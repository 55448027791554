const SidebarMenuItem = ({
  text,
  Icon,
  active,
  onClick,
  mainimg,
  mainsubcat,
  item,
}) => {
  return (
    <div
    className={`hoverEffect flex sm:flex-col xs:flex-col md:flex-row items-center text-gray-700 justify-center xl:justify-start text-lg xl:space-x-3 border-b border-gray-300 p-2 transition-colors duration-200 ease-in-out ${
      mainsubcat === item ? "bg-purple-100 text-purple-800" : "hover:bg-gray-100"
    }`}
    onClick={onClick}
  >
    {Icon && <Icon className="h-6 w-6 sm:h-7 sm:w-7" />}
    {mainimg && (
      <img
        src={`https://api.petzoy.in/assets/${mainimg}`}
        className="w-10 h-10 rounded-full object-cover mb-2 sm:mb-0"
        alt={text}
      />
    )}
    <span className={`${mainsubcat === item ? 'font-bold':''} text-sm text-center sm:text-base xl:inline`}>
      {text}
    </span>
  </div>
  
  );
};

export default SidebarMenuItem;
