import { useEffect, useState } from "react";

import { useRandomCategoriesQuery } from "../../store/services/categoryService";
import { useGetAllServicesQuery, useRandomServicesQuery } from "../../store/services/serviceService";
import { useGetNearbyUserPetsQuery, useGetNearbyUsersQuery } from "../../store/services/authService";
import Loader from "../../components/Loader";
import Banner from "../../components/Banner/Banner";
import Services from "../../components/Services/Services";
import NearbyPets from "../../components/NearByPets/NearbyPets";
import NearbyUsers from "../../components/NearbyUsers/NearbyUsers";
import RandomServicesUser from "../../components/RandomServices/RandomServices";
import WhiteHeader from "../../components/WhiteHeader";
import ClickableServices from "../../components/ClickableServices/ClickableServices";
import { useUsersbyServiceQuery } from "../../store/services/homeProducts";
import { Link } from "react-router-dom";
import AdvertWidget from "../widgets/AdvertWidget";
import { Box, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";



function ServicesUsers() {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
    
  const latlong = useSelector((state) => state.authReducer.latlong);
    const [selected_service, setselected_service] = useState('')
    const { data, isFetching } = useUsersbyServiceQuery({
        service: selected_service,
        page: "",
        latitude:latlong?.latitude,
        longitude:latlong?.longitude
      });
    


    
    
  
  
  
    


  return (
    
<div>


{isFetching && <Loader/>}




<main className="fix">



    <section className="services__area">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-xl-6 col-lg-7">
                    <div className="section__title">
                        <span className="sub-title">Get Nearby Service Providers
                            <strong className="shake">
                                <img src="assets/img/icon/pet_icon02.svg" alt="" className="injectable"/>
                            </strong>
                        </span>
                        {/* <h2 className="title">Providing Our Best Pet Care & Veterinary Services</h2> */}
                    </div>
                </div>
                
            </div>
            <div className="row justify-content-center">
                
                
                <ClickableServices setselectedservice={setselected_service}/>
            </div>
        </div>
        <div className="services__shape-wrap">
            <img src="assets/img/images/services_shape01.png" alt="img" className="ribbonRotate"/>
            <img src="assets/img/images/services_shape02.png" alt="img" data-aos="fade-up-right" data-aos-delay="800"/>
            <img src="assets/img/images/services_shape03.png" alt="img" data-aos="fade-down-left" data-aos-delay="400"/>
        </div>
    </section>





    <section className="team__area">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-6">
                    <div className="section__title text-center">
                        {/* <span className="sub-title">Friends Near You Pet Owners
                            <strong className="shake">
                                <img src="assets/img/icon/pet_icon02.svg" alt="" className="injectable"/>
                            </strong>
                        </span> */}
                        
                    </div>
                </div>
            </div>
            <div className="row justify-content-start">


{!isFetching && data?.length == 0   &&
<>
<main class="fix">


<section class="breadcrumb__area fix">
  
    <div class="breadcrumb__shape-wrap">
        <img src="assets/img/images/breadcrumb_shape01.png" alt="img" data-aos="fade-down-right" data-aos-delay="400"/>
        <img src="assets/img/images/breadcrumb_shape02.png" alt="img" data-aos="fade-up-left" data-aos-delay="400"/>
    </div>
</section>

<section class="error__area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <div class="error__img">
                    <img src="assets/img/images/error_img.png" alt=""/>
                </div>
                <div class="error__content">
                    <h2 class="title">OOPS! Nothing Was Found</h2>
                    <p>Oops! it could be you or us, there is no data here. It might have been <br/> moved or deleted.Back To Home</p>
                    <a href="/" class="btn">Back To Home Page <img src="assets/img/icon/right_arrow.svg" alt="" class="injectable"/></a>
                </div>
            </div>
        </div>
    </div>
</section>


</main>
</>
}


  {data?.map((category, index) => (
    <div className="col-xl-6" key={category._id} style={{width:"50%"}}>
      <Link to={`/shop/${category.name}`} className="w-full">
        <div className="animal__item animal__item-two shine-animate-item">
          <div className="animal__thumb animal__thumb-two shine-animate">
            <a href="animal-details.html">
              <img
                src={`https://api.petzoy.in/assets/${category?.picturePath}`}
                alt="img"
              />
            </a>
            <a href="animal-details.html" className="wishlist">
              <i className="flaticon-love"></i>
            </a>
          </div>
          <div className="animal__content animal__content-two">
            <h4 className="title">
              <a href="animal-details.html">
                {category?.firstName}
              </a>
            </h4>
            <div className="pet-info">
              <ul className="list-wrap">
                <li>
                  <span>{category?.occupation}</span>
                </li>
              </ul>
            </div>
            <div className="location">
              <i className="flaticon-placeholder"></i>
              <span>{category?.location}</span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  ))}
  
          <div className="col-xl-4 service_ad">
    <AdvertWidget />
    </div>


</div>

          
        </div>
        <div className="team__shape">
            <img src="assets/img/team/team_shape.png" alt="img" className="ribbonRotate"/>
        </div>
    </section>

    
    

</main>



</div>

  );
}

export default ServicesUsers;
