import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const reportService = createApi({
  reducerPath: 'reports',
  tagTypes: 'reports',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.petzoy.in/reports/', // Change this URL to your API endpoint
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      headers.set('authorization', token ? `Bearer ${token}` : '');
      return headers;
    }
  }),
  endpoints: (builder) => ({
    // Define endpoints for report-related operations here
    getReportByName: builder.query({
      query: (name) => `${name}`,
      providesTags: ['reports']
    }),
  }),
});

export const {
  useGetReportByNameQuery,
} = reportService;

export default reportService;
