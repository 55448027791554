import React, { useEffect, useRef, useState } from "react";
import { useZIM } from "../../hooks/zim";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import Loader from "../../components/Loader";
import "./chat.scss";
import Footer from "./Conversation/Footer";
import moment from "moment";
import { useGetUserByIdQuery } from "../../store/services/authService";
import { useLocation } from "react-router-dom";
import MobileFooter from "./Conversation/MobileFooter";

export default function ChatScreen() {
  const theme = useTheme();
  const location = useLocation();
  const locationstate = location.state || {}; // Provide a default empty object
  const { mainchatid } = locationstate;

  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [showmobilechat, setshowmobilechat] = useState(false)
  const [state, zimAction] = useZIM();
  const [chatdata, setchatdata] = useState({
    chattype: 0,
    id: "",
    name: "",
    image: "",
  });
  const [{ avatarMap, chatMap }] = useZIM();
  const { data, error, isFetching } = useGetUserByIdQuery(chatdata.id, {
    skip: !chatdata.id,
  });
  const msgs = chatMap[chatdata.id] || [];
  const [searchQuery, setSearchQuery] = useState("");

  // Function to handle input change
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const messageListRef = useRef(null);

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTo({
        top: messageListRef.current.scrollHeight, // Scroll to the bottom
        behavior: "smooth", // Animate scrolling smoothly
      });
    }
  }, [msgs]);

  useEffect(() => {
    if (state.isLogin && chatdata.id && chatdata.name) {
      zimAction.queryHistoryMessage(chatdata.id, chatdata.chattype, {
        count: 1000,
        reverse: true,
      });
    }
  }, [chatdata, state.isLogin]);

  useEffect(() => {
    if (state.isLogin) {
      setTimeout(() => {
        zimAction.queryConversationList();
      }, 1000);
    }
  }, [state.isLogin]);


  const filteredConvs = state.convs.filter((el) =>
    el.conversationName.toLowerCase().includes(searchQuery.toLowerCase())
  );


  useEffect(() => {
    if (mainchatid) {
      console.warn("ok ok yeah")
      const matchingConv = filteredConvs.find((el) => el.conversationID === mainchatid);
      if (matchingConv) {
        if(!isNonMobileScreens){
          setshowmobilechat(true)
        }
        setchatdata({
          chattype: matchingConv.type,
          id: matchingConv.conversationID,
          name: matchingConv.conversationName,
          image: matchingConv.conversationAvatarUrl,
        });
      }
    }
  }, [mainchatid]);


  if (!state.isLogin) {
    return <Loader />;
  }

  const formatDate = (dateString) => {
    const date = moment(dateString).format("YYYY-MM-DD");
    const today = moment().format("YYYY-MM-DD");
    return date === today ? "Today" : date;
  };


  return (
    <div className="home-page__content messages-page">
      <div className="container-fluid h-100">
        <div className="row px-0 h-100">
    {!showmobilechat &&

<>      <div className="col-12 col-md-4 col-lg-5 col-xl-3 px-0 messages-page__list-scroll">
            <div className="messages-page__header mb-0 px-4 pt-3 pb-3">
              <span className="messages-page__title">Chats</span>
            </div>
            <div className="messages-page__search mb-0 px-3 pb-3">
              <div className="custom-form__search-wrapper">
                <input
                  type="text"
                  className="form-control custom-form"
                  id="search"
                  placeholder="Search User..."
                  autoComplete="off"
                  value={searchQuery}
                  onChange={handleInputChange}
                />

                <button type="submit" class="custom-form__search-submit">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="svg-icon svg-icon--search"
                    viewBox="0 0 46.6 46.6"
                  >
                    <path
                      d="M46.1,43.2l-9-8.9a20.9,20.9,0,1,0-2.8,2.8l8.9,9a1.9,1.9,0,0,0,1.4.5,2,2,0,0,0,1.5-.5A2.3,2.3,0,0,0,46.1,43.2ZM4,21a17,17,0,1,1,33.9,0A17.1,17.1,0,0,1,33,32.9h-.1A17,17,0,0,1,4,21Z"
                      fill="#f68b3c"
                    />
                  </svg>
                </button>
              </div>
            </div>

            {filteredConvs.length == 0 && (
              <ul class="messages-page__list pb-5 px-1 px-md-3"></ul>
            )}
            {filteredConvs.map((el) => {
              const mylastMessage = () => {
                return el.lastMessage && el.lastMessage.message
                  ? el.lastMessage.message.length > 20
                    ? el.lastMessage.message.slice(0, 20) + "..."
                    : el.lastMessage.message
                  : "";
              };

              return (
                <ul
                  class="messages-page__list  px-1 px-md-3"
                  onClick={() => {
                    console.log(el);
                    if(!isNonMobileScreens){
                      setshowmobilechat(true)
                    }
                    setchatdata({
                      chattype: el.type,
                      id: el.conversationID,
                      name: el.conversationName,
                      image: el.conversationAvatarUrl,
                    });
                    zimAction.clearConversationUnreadMessageCount(
                      el.conversationID,
                      el.type
                    );
                  }}
                >
                  <li
                    class={`messaging-member ${
                      el.unreadMessageCount > 0 && el.notificationStatus == 1
                        ? "messaging-member--new"
                        : ""
                    } messaging-member--online`}
                  >
                    <div class="messaging-member__wrapper">
                      <div class="messaging-member__avatar">
                        <img
                          src={el.conversationAvatarUrl}
                          alt="Bessie Cooper"
                          loading="lazy"
                        />
                        <div class="user-status"></div>
                      </div>

                      <span class="messaging-member__name">
                        {el.conversationName}
                      </span>
                      <span class="messaging-member__message">
                        {mylastMessage()}
                      </span>
                    </div>
                  </li>
                </ul>
              );
            })}
          </div>


</>}
{chatdata.id && (!isNonMobileScreens ? showmobilechat : true) && (


            <div class="chat col-12 col-md-8 col-lg-7 col-xl-6 px-0 pl-md-1">
              <div class="chat__container">
                <div class="chat__wrapper py-2 pt-mb-2 pb-md-3">
                  <div class="chat__messaging messaging-member--online pb-2 pb-md-2 pl-2 pl-md-4 pr-2">
                    <div class="chat__previous d-flex d-md-none" onClick={() => setshowmobilechat(false)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="svg-icon svg-icon--previous"
                        viewBox="0 0 45.5 30.4"
                      >
                        <path
                          d="M43.5,13.1H7l9.7-9.6A2.1,2.1,0,1,0,13.8.6L.9,13.5h0L.3,14v.6c0,.1-.1.1-.1.2v.4a2,2,0,0,0,.6,1.5l.3.3L13.8,29.8a2.1,2.1,0,1,0,2.9-2.9L7,17.2H43.5a2,2,0,0,0,2-2A2.1,2.1,0,0,0,43.5,13.1Z"
                          fill="#f68b3c"
                        />
                      </svg>
                    </div>
                    <div class="chat__notification d-flex d-md-none chat__notification--new">
                      <span>1</span>
                    </div>
                    <div class="chat__infos pl-2 pl-md-0">
                      <div class="chat-member__wrapper" data-online="true">
                        <div class="chat-member__avatar">
                          <img
                            src={chatdata.image}
                            alt="Jenny Smith"
                            loading="lazy"
                          />
                          <div class="user-status user-status--large"></div>
                        </div>
                        <div class="chat-member__details">
                          <span class="chat-member__name">{chatdata.name}</span>
                          <span class="chat-member__status">
                            {data?.pets.length} Pet Owner
                          </span>
                        </div>
                        {/* <div class="chat__actions mr-2 ">
                <ul class="m-0">
                  <li>
                    <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 48 48">
                      <path d="M38.4,48c-2.1,0-5.1-.8-9.5-3.2a62.9,62.9,0,0,1-14.6-11A61.7,61.7,0,0,1,3.2,19C-.9,11.8-.3,8.5.7,6.4A9.7,9.7,0,0,1,4.8,2,21.1,21.1,0,0,1,7.8.4h.3c1.8-.7,3-.3,4.9,1.5h.1a40.1,40.1,0,0,1,5.4,8.2c1.3,2.6,1.6,4.3-.2,6.9l-.5.6c-.8,1-.8,1.2-.8,1.6s1.9,3.4,5.2,6.7S28,30.8,28.8,31s.6,0,1.6-.8l.7-.4c2.5-1.9,4.2-1.6,6.8-.3A40.6,40.6,0,0,1,46.1,35h.1c1.8,1.9,2.2,3.1,1.5,4.9v.2h0a21.1,21.1,0,0,1-1.6,3,10,10,0,0,1-4.3,4.1A7.7,7.7,0,0,1,38.4,48ZM9.5,4.1H9.2L6.9,5.4H6.8A6.3,6.3,0,0,0,4.3,8.1c-.3.7-1.2,2.6,2.4,9A58.9,58.9,0,0,0,17.1,30.9,58.2,58.2,0,0,0,30.9,41.3c6.4,3.6,8.4,2.7,9.1,2.4a6.7,6.7,0,0,0,2.5-2.5.1.1,0,0,0,.1-.1c.5-.8.9-1.6,1.3-2.4v-.2l-.5-.6a35.4,35.4,0,0,0-7.3-4.8c-1.7-.8-1.8-.8-2.7-.1l-.6.4A5.3,5.3,0,0,1,28,34.9c-2.9-.6-7.4-4.9-8.7-6.2s-5.6-5.8-6.2-8.7.6-3.6,1.5-4.8l.4-.6c.7-.9.8-1-.1-2.7a35.4,35.4,0,0,0-4.8-7.3Z" fill="#f68b3c" />
                    </svg>
                  </li>
                  <li>
                    <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 46.8 47.4">
                      <path d="M35.8,47.4H11a11,11,0,0,1-11-11V11.6A11,11,0,0,1,11,.6h8.8a2,2,0,1,1,0,4H11a7,7,0,0,0-7,7V36.4a7,7,0,0,0,7,7H35.8a7,7,0,0,0,7-7v-9a2,2,0,1,1,4,0v9A11,11,0,0,1,35.8,47.4Z" fill="#f68b3c" />
                      <path d="M36.6,20.4A10.2,10.2,0,1,1,46.8,10.2,10.2,10.2,0,0,1,36.6,20.4ZM36.6,4a6.2,6.2,0,1,0,6.2,6.2A6.2,6.2,0,0,0,36.6,4Z" fill="#f68b3c" />
                    </svg>
                  </li>
                  <li class="chat__details d-flex d-xl-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 42.2 11.1">
                      <g>
                        <circle cx="5.6" cy="5.6" r="5.6" fill="#d87232" />
                        <circle cx="21.1" cy="5.6" r="5.6" fill="#d87232" />
                        <circle cx="36.6" cy="5.6" r="5.6" fill="#d87232" />
                      </g>
                    </svg>
                  </li>
                </ul>

              </div> */}
                      </div>
                    </div>
                  </div>
                  <div class="chat__content pt-4 px-3" ref={messageListRef}>
                    <ul class="chat__list-messages">
                      {msgs.map((item, index) => {
                        // const item = e.item;

                        const msg = item.message || item.fileName;
                        const date = new Date(item.timestamp);

                        const previousItem = index > 0 ? msgs[index - 1] : null;

                        // Compare the timestamps to determine if a new date should be shown
                        const showDateHeading =
                          !previousItem ||
                          new Date(previousItem.timestamp).toDateString() !==
                            date.toDateString();
                        return (
                          <>
                            {item.direction ? (
                              <li>
                                {showDateHeading && (
                                  <div class="chat__time">
                                    {formatDate(item.created)}
                                  </div>
                                )}
{item.fileDownloadUrl?
 <div class="chat__bubble chat__bubble--you">
   <a href={item.fileDownloadUrl} target="_blank">
   <img src={item.fileDownloadUrl} alt="" style={{width:"60px",height:'60px'}} />
   </a></div>:
                                <div class="chat__bubble chat__bubble--you">
                                  {item.message}
                                </div>
                                              }                        </li>
                            ) : (
                              <li>
                            {item.fileDownloadUrl?
                             <div class="chat__bubble chat__bubble--me"> <a href={item.fileDownloadUrl} target="_blank"><img src={item.fileDownloadUrl} alt="" style={{width:"60px",height:'60px'}} /></a></div>:    <div class="chat__bubble chat__bubble--me">
                                  {item.message}
                                </div>}
                              </li>
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </div>
                  <div class="chat__send-container px-2 px-md-3 pt-1 pt-md-3">
                    <div class="custom-form__send-wrapper">
                     {isNonMobileScreens ?  <Footer
                        maindata={chatdata}
                        onpress={() => {
                          console.warn("hit jit");
                          messageListRef.current.scrollTo({
                            top: messageListRef.current.scrollHeight, // Scroll to the bottom
                            behavior: "smooth", // Animate scrolling smoothly
                          });
                        }}
                      />:<MobileFooter
                      maindata={chatdata}
                      onpress={() => {
                        console.warn("hit jit");
                        messageListRef.current.scrollTo({
                          top: messageListRef.current.scrollHeight, // Scroll to the bottom
                          behavior: "smooth", // Animate scrolling smoothly
                        });
                      }}
                    />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {chatdata.id &&  isNonMobileScreens && (
            <div class="col-12 col-md-5 col-lg-4 col-xl-3 px-4 px-sm-5 px-lg-4 user-profile">
              {/* <div class="user-profile__close d-flex d-xl-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="svg-icon"
                  viewBox="0 0 38.8 38.9"
                >
                  <g>
                    <path
                      d="M2,38.9a1.9,1.9,0,0,1-1.4-.5,2.1,2.1,0,0,1,0-2.9L35.4.6a1.9,1.9,0,0,1,2.8,0,1.9,1.9,0,0,1,0,2.8L3.4,38.4A1.9,1.9,0,0,1,2,38.9Z"
                      fill="#d87232"
                    />
                    <path
                      d="M36.8,38.9a1.9,1.9,0,0,1-1.4-.5L.6,3.4A1.9,1.9,0,0,1,.6.6,1.9,1.9,0,0,1,3.4.6L38.2,35.5a2.1,2.1,0,0,1,0,2.9A1.9,1.9,0,0,1,36.8,38.9Z"
                      fill="#d87232"
                    />
                  </g>
                </svg>
              </div> */}
              <div class="user-profile__wrapper">
                <div class="user-profile__avatar">
                  <img src={chatdata.image} alt="Jenny Smith" loading="lazy" />
                </div>
                <div class="user-profile__details mt-1">
                  <span class="user-profile__name">{chatdata.name}</span>
                  <span class="user-profile__phone">{data?.email}</span>
                  <span class="user-profile__location">{data?.location}</span>
                </div>
                {/* <div class="user-profile__description">
            <p>Fly me to the moon 🌙 If you feel like your life is a routine, step back and take a deep breath.</p>
          </div> */}
                <div class="user-profile__learning mt-4">
                  <span class="user-profile__label">User Medias</span>
                  <ul class="user-profile__tags user-profile__tags--primary mt-2">
                    <li>
                      <a target="_blank">{data?.followers.length} Followers</a>
                    </li>
                    <li>
                      <a target="_blank">{data?.following.length} Followings</a>
                    </li>
                    <li>
                      <a target="_blank">{data?.pets.length} Pets</a>
                    </li>
                  </ul>
                </div>
                <div class="user-profile__hobbies">
                  <span class="user-profile__label">Services</span>
                  <ul class="user-profile__tags user-profile__tags--secondary mt-2">
                    {data?.providedServices?.map((item, index) => (
                      <React.Fragment key={index}>
                        <li>{item.name}</li>
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
