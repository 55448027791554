import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const reviewService = createApi({
  reducerPath: 'reviews',
  tagTypes: 'reviews',
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.petzoy.in/api/",
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      headers.set('authorization', token ? `Bearer ${token}` : '');
      return headers;
    }
  }),
  endpoints: (builder) => ({
    createUserReview: builder.mutation({
      query: (data) => ({
        url: '/createreview',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['reviews']
    }),
    getAllUserReviews: builder.query({
      query: () => '/allreview',
      providesTags: ['reviews']
    }),
    getUserReviewsByUserId: builder.query({
      query: (userId) => `/userreview/${userId}`,
      providesTags: ['reviews']
    }),
    getUserReviewsByPetId: builder.query({
      query: (petId) => `/petreview/${petId}`,
      providesTags: ['reviews']
    }),
    deleteUserReview: builder.mutation({
        query: (reviewId) => ({
          url: `/deletereview/${reviewId}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['reviews']
      }),
  }),
});

export const {
  useCreateUserReviewMutation,
  useGetAllUserReviewsQuery,
  useGetUserReviewsByUserIdQuery,
  useGetUserReviewsByPetIdQuery,
  useDeleteUserReviewMutation,
} = reviewService;

export default reviewService;
