import { Virtual } from "swiper/modules";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { useGetAllServicesQuery } from "../../store/services/serviceService";
import { Box, Typography, useTheme } from "@mui/material";
import UserImage from "../UserImage";
const ClickableServices = ({ setselectedservice }) => {

  const { palette } = useTheme();
  const main = palette.neutral.main;
  const { data, isFetching } = useGetAllServicesQuery();
  let i = 1;
  return (
    data?.length > 0 && (
      <Swiper
        modules={[Virtual]}
        spaceBetween={10}
        slidesPerView={10}
        virtual
        className="w-full h-[200px]"
        breakpoints={{
          0: {
            slidesPerView: 3,
          },
          640: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 4,
          },
          1080: {
            slidesPerView: 5,
          },
          1280: {
            slidesPerView: 6,
          },
        }}
      >
        {data.map((category, index) => {
          if (i >= 5) {
            i = 1;
          } else {
            i++;
          }
          return (
            <SwiperSlide
              className="w-full overflow-hidden  relative soccer"
              key={index}
              virtualIndex={index}
            >
              {/* <Link
                
                onClick={() => setselectedservice(category._id)}
                className="text-white text-base font-medium capitalize w-full h-[200px] "
              >
         
         
<div className="col-xl-12 col-lg-4 col-md-6 col-sm-8">
                    <div className="services__item">
                        
                        <div className="service__thumb service__thumb-two shine-animate">
                        <img
                    src={`https://api.petzoy.in/assets/${category.image}`}
                    
                    alt=""
                  />
                  </div>
                  
                        <div className="services__content">
                            <h4 className="title"><a href="animal-details.html">{category.name}</a></h4>
                            
                        </div>
                    </div>
                </div>
              </Link> */}


              <Box onClick={() => setselectedservice(category._id)} display="flex" flexDirection={"column"} alignItems="center" justifyContent="center">
                <UserImage image={category.image} size="120px" />
                <Box

                >
                  <Typography
                    color={main}
                    variant="h5"
                    fontWeight="500"
                    textAlign={'center'}
                    sx={{
                      "&:hover": {
                        color: palette.primary.light,
                        cursor: "pointer",
                      },
                    }}
                  >
                    {category.name}
                  </Typography>
                  {/* <Typography color={medium} fontSize="0.75rem">
            {subtitle}
          </Typography> */}
                </Box>
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper>
    )
  )

};

export default ClickableServices;
