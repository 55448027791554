import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useCatProductsQuery, useGetSubcategoryProductsQuery, useUsersbyServiceQuery } from "../../store/services/homeProducts";
import { discount } from "../../utils/discount";
import currency from "currency-formatter";
import { useAllCategoriesQuery } from "../../store/services/categoryService";
import HomeProducts from "../../components/HomeProducts/HomeProducts";


import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useGetSubcategoriesByCategoryQuery } from "../../store/services/subcategoryService";
import { useSelector } from "react-redux";
import { useGetNearbyUserPetsQuery } from "../../store/services/authService";
import Moment from "react-moment";


const AllServiceUsers = () => {
    
  const navigate = useNavigate();
  const { state } = useLocation();
  const { categoryy } = state;
    const {_id} = useParams()
    const latlong = useSelector((state) => state.authReducer.latlong);
  const { data, isFetching } = useUsersbyServiceQuery({
    service: _id,
    
    latitude:latlong?.latitude,
    longitude:latlong?.longitude
  });


  let i = 1;
  return (
    <>
      <main class="fix" style={{height:"80vh"}}>
        <section class="category__area">
          <div class="container">
            <div class="row align-tiems-center">
              <div class="col-md-8">
                <div class="section__title-two mb-30">
                  <h2 class="title">
                  <i class="fa-solid fa-circle-chevron-left mr-10" onClick={() =>{navigate("/")}}></i>
                    {categoryy.name}
                    <img
                      src="assets/img/images/title_shape.svg"
                      alt=""
                      class="injectable"
                    />
                  </h2>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
            {data?.map((category, index) => {
          if (i >= 5) {
            i = 1;
          } else {
            i++;
          }
          return (
          
            categoryy.type == "owner" ? (
                <Link
                  to={{
                    pathname: `/userconnect/${category._id}`,
                  }}
                  state={{
                    userId: category._id,
                  }}
                  className="w-full "
                >
                  <div class="main-box-container" id="usermaincard">
                    <div class="box-container">
                      <img
                        src={`https://api.petzoy.in/assets/${category?.picturePath}`}
                      />
                      <h3>
                        {category?.private && (
                          <i class="fa-solid fa-lock"></i>
                        )}{" "}
                        {category?.firstName}{" "}
                      </h3>

                      <p>
                        <p>
                          {category?.providedServices?.map(
                            (item, index) => (
                              <React.Fragment key={index}>
                                <small>{item.name}</small>({" "}
                                <span>{item.title}</span>)
                                <br />
                              </React.Fragment>
                            )
                          )}
                        </p>
                      </p>
                      {category?.pets?.length != 0 && (
                        <p>{category?.pets?.length} Pet's Owner</p>
                      )}

                      <h6>
                        <i class="fa-solid fa-circle-arrow-right"></i>{" "}
                      </h6>
                    </div>
                  </div>
                </Link>
              ) : (
                <Link
                  to={{
                    pathname: `/contactprofile/${category._id}`,
                  }}
                  state={{
                    userId: category._id,
                  }}
                  // to={`/shop/${category.name}`}
                  className="w-full  "
                >
                  {/* <div class="wrapper" id="petcard">
                    <div class="card">
                      <p className="pt-1">

                        <i class={category?.gender == "male" ? "fa-solid fa-mars " : "fa-solid fa-venus"}></i>


                        {" "}  {category?.age} Yrs.
                        {" "}
                        {category?.type == "Dog" ? <i class="fa-solid fa-shield-dog" /> : <i class="fa-solid fa-cat" />}  {category?.breed}

                      </p>
                      <div class="image">
                        <img src={`https://api.petzoy.in/assets/${category?.picturePath}`} alt="" />
                      </div>
                      <div class="button">{category?.name}  </div>
                    </div>

                  </div> */}

                  <div class="petcard">
                    <div className="petImageContainer">
                      <img
                        class="card-image"
                        src={`https://api.petzoy.in/assets/${category?.picturePath}`}
                        alt="Dog"
                      />
                    </div>
                    <div class="card-content">
                      <div className="userMainHeading">
                        <h3 class="button">{category?.name}</h3>
                        <img
                          class="user-Image"
                          src={`https://api.petzoy.in/assets/${category?.owner?.picturePath}`}
                          alt="Dog"
                        />
                      </div>
                      <div class="card-info">
                        {!category?.private ? (
                          <span class="card-icon">
                            {category?.private && (
                              <i class="fa-solid fa-lock" />
                            )}{" "}
                            {"  "}
                            {category?.type == "Dog" ? (
                              <i class="fa-solid fa-shield-dog" />
                            ) : (
                              <i class="fa-solid fa-cat" />
                            )}{" "}
                            {category?.breed}
                          </span>
                        ) : (
                          <i class="fa-solid fa-lock" />
                        )}
                        {!category?.private && (
                          <span class="card-date">
                            <i
                              class={
                                category?.gender == "male"
                                  ? "fa-solid fa-mars "
                                  : "fa-solid fa-venus"
                              }
                            ></i>{" "}
                              <Moment
        fromNow
        style={{
          color: "#505050",
          fontSize: "10px",
        }}
      >
        {category?.age}
      </Moment> 
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              )
            
          );
        })}

          </div>
          </div>
        </section>

      </main>
    </>
  );
};

export default AllServiceUsers;
