import { useEffect, useState } from "react";
import Header from "../../components/Header";
import {
  useGetBrandsByTypeQuery,
  useGetBrandsQuery,
} from "../../store/services/brandService";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  usePlaceCashOnDeliveryOrderMutation,
  useSendPaymentMutation,
} from "../../store/services/paymentService";
import { useGetUserByIdQuery } from "../../store/services/authService";
import toast, { Toaster } from "react-hot-toast";
import currency from "currency-formatter";
import { discount } from "../../utils/discount";
import { useUsersbyServiceQuery } from "../../store/services/homeProducts";

function Commercial() {
  const { state } = useLocation();
  const { cart, multiple } = state;

  const { donateuserid } = useSelector((state) => state.authReducer);
  const latlong = useSelector((state) => state.authReducer.latlong);

  const {
    data: brandsdata,
    isLoading,
    error,
  } = useUsersbyServiceQuery({
    service: donateuserid,
    page: "",
    latitude:latlong?.latitude,
    longitude:latlong?.longitude
  });

  
  const brands = brandsdata;

  const [selected_member, setselected_member] = useState("");

  const navigate = useNavigate();
  const { total, totalshipping } = useSelector((state) => state.cart);
  const [doPayment, response] = useSendPaymentMutation();
  const { userToken, user } = useSelector((state) => state.authReducer);
  const [paymentMethod, setPaymentMethod] = useState("online");

  const [userData, setUserData] = useState({
    name: "",
    email: "",
  });
  const [formAddressData, setFormAddressData] = useState({
    addressLine1: "",

    postalCode: "",
    state: "",
    country: "India",
  });
  console.log(user);
  const { data: userProfile, isSuccess } = useGetUserByIdQuery(user?._id);
  const [checkoutLoading, setcheckoutLoading] = useState(false);

  const [shippingCharge, setShippingCharge] = useState("");

  useEffect(() => {
    if (total <= 200) {
      setShippingCharge(30);
    } else if (total <= 400) {
      setShippingCharge(25);
    } else if (total > 401) {
      setShippingCharge(20);
    }
  }, [total]);

  useEffect(() => {
    if (isSuccess) {
      setUserData({
        name: userProfile.firstName,
        email: userProfile.email,
      });
    }
  }, [isSuccess, userProfile]);

  useEffect(() => {
    setFormAddressData({
      ...formAddressData,
      addressLine1: selected_member.location,

      postalCode: selected_member.location,
      state: "",
      country: "India",
    });
  }, [selected_member]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name" || name === "email") {
      setUserData({
        ...userData,
        [name]: value,
      });
    } else {
      setFormAddressData({
        ...formAddressData,
        [name]: value,
      });
    }
  };
  const dispatch = useDispatch();

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleCheckout = async () => {
    setcheckoutLoading(true);
    if (multiple) {
      doPayment({ cart, id: user._id });
    } else {
      doPayment({ cart: [{ ...cart }], id: user?._id });
    }
    console.log("Processing online payment...");
  };

  useEffect(() => {
    if (response?.isSuccess) {
      window.location.href = response?.data?.url;
    }
  }, [response]);

  return (
    <div>
      <main class="fix">
        <section class="breadcrumb__area fix">
          <div class="container">
            <div class="row align-items-end">
              <div class="col-lg-8">
                <div class="breadcrumb__content">
                  <h3 class="title">Pet Helpers</h3>
                  <nav class="breadcrumb">
                    <span property="itemListElement" typeof="ListItem">
                      <a href="index.html">Home</a>
                    </span>
                    <span class="breadcrumb-separator">
                      <i class="flaticon-right-arrow-angle"></i>
                    </span>
                    <span property="itemListElement" typeof="ListItem">
                      Team
                    </span>
                  </nav>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="breadcrumb__img">
                  <img
                    src="assets/img/images/breadcrumb_img.png"
                    alt="img"
                    data-aos="fade-left"
                    data-aos-delay="800"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="team__area-three">
          <div class="container">
            {!selected_member && (
              <div class="row justify-content-center">
                {!isLoading &&
                  brands.map((brand) => {
                    return (
                      <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                        <div class="team__item">
                          <div class="team__item-img">
                            <div class="mask-img-wrap">
                              <img
                                src={`https://api.petzoy.in/assets/${brand?.picturePath}`}
                                alt="img"
                              />
                            </div>
                            <div class="team__item-img-shape">
                              <div class="shape-one">
                                <img
                                  src="assets/img/team/team_img_shape01.svg"
                                  alt=""
                                  class="injectable"
                                  style={{ height: "100%" }}
                                />
                              </div>
                              <div class="shape-two">
                                <img
                                  src="assets/img/team/team_img_shape02.svg"
                                  alt=""
                                  class="injectable"
                                />
                              </div>
                            </div>
                            <div class="team__social">
                              <ul class="list-wrap">
                                <li>
                                  <button
                                    style={{ color: "white" }}
                                    onClick={() => setselected_member(brand)}
                                  >
                                    <i class="fa-solid fa-kit-medical">
                                      {" "}
                                      Donate
                                    </i>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="team__item-content">
                            <h4 class="title">
                              <a href="team-details.html">{brand.firstName}</a>
                            </h4>
                            <span>
                              <i class="fa-solid fa-location-dot" />{" "}
                              {brand?.location}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}

            {selected_member && (
              <section className="shop-checkout container">
                <div className="row pb-10">
                  <div className="col-md-9">
                    <div className="billing-info__wrapper">
                      <h4>BILLING DETAILS</h4>
                      <div className="row">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-floating my-3">
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                value={userData.name}
                                disabled
                                placeholder="First Name"
                              />
                              <label for="checkout_first_name">
                                First Name
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-floating my-3">
                              <input
                                type="text"
                                className="form-control"
                                name="email"
                                value={userData.email}
                                disabled
                                placeholder="E-Mail"
                              />
                              <label for="checkout_last_name">E-Mail</label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="search-field my-3">
                              <div className="form-label-fixed hover-container">
                                <label
                                  for="search-dropdown"
                                  className="form-label"
                                >
                                  Country / Region*
                                </label>
                                <div className="js-hover__open">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg search-field_actor search-field_arrow-down"
                                    id="search-dropdown"
                                    name="search-keyword"
                                    disabled
                                    readonly
                                    placeholder="India"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-floating mt-3 mb-3">
                              <input
                                disabled
                                readonly
                                type="text"
                                className="form-control"
                                name="addressLine1"
                                value={formAddressData.addressLine1}
                                onChange={handleChange}
                                placeholder="Street Address *"
                              />
                              <label for="checkout_company_name">
                                Street Address *
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-floating my-3">
                              <input
                                disabled
                                readonly
                                type="text"
                                className="form-control"
                                name="postalCode"
                                value={formAddressData.postalCode}
                                onChange={handleChange}
                                placeholder="Postcode / ZIP *"
                              />
                              <label for="checkout_zipcode">Postal Code</label>
                            </div>
                          </div>

                          {/* <div className="col-md-12">
                     <div className="form-floating my-3">
                     <input disabled readonly type="text" className="form-control" name="state" value={formAddressData.state} onChange={handleChange} placeholder="State *"/>
                       <label for="checkout_email">State *</label>
                     </div>
                   </div> */}
                        </div>
                      </div>
                      {/* <div className="col-md-12">
                   <div className="mt-3">
                     <textarea className="form-control form-control_gray" placeholder="Order Notes (optional)" cols="30"
                       rows="8"></textarea>
                   </div>
                 </div> */}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <form name="checkout-form" action>
                      <div className="shopping-cart">
                        <div className="shopping-cart__totals-wrapper">
                          <div className="sticky-content">
                            <div className="shopping-cart__totals">
                              <h3>My Donation</h3>
                              <table className="cart-totals">
                                <tbody>
                                  <tr>
                                    <th>Subtotal</th>
                                    <td style={{ textAlign: "end" }}>
                                      {" "}
                                      {currency.format(
                                        multiple
                                          ? total
                                          : discount(
                                              cart?.price,
                                              cart?.discount
                                            ),
                                        {
                                          code: "INR",
                                        }
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Shipping Charges</th>
                                    <td style={{ textAlign: "end" }}>
                                      {" "}
                                      {currency.format(
                                        multiple
                                          ? shippingCharge
                                          : cart?.shipping,
                                        {
                                          code: "INR",
                                        }
                                      )}
                                    </td>
                                  </tr>

                                  {/* <tr>
                         <th>GST</th>
                         <td style={{textAlign:"end"}}>₹ 19</td>
                       </tr> */}
                                  <tr>
                                    <th>Total</th>
                                    <td style={{ textAlign: "end" }}>
                                      {" "}
                                      {currency.format(
                                        multiple
                                          ? total + shippingCharge
                                          : cart?.shipping ||
                                              0 +
                                                discount(
                                                  cart?.price,
                                                  cart?.discount
                                                ),
                                        {
                                          code: "INR",
                                        }
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>
                                      {" "}
                                      <input
                                        type="radio"
                                        className="form-check-input form-check-input_fill"
                                        name="checkout_payment_method"
                                        id="checkout_payment_method_online"
                                        value="online"
                                        checked={paymentMethod === "online"}
                                        onChange={handlePaymentMethodChange}
                                      />
                                    </th>
                                    <td style={{ textAlign: "end" }}>
                                      Online Payment
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                              <div className="policy-text">
                                Your personal data will be used to process your
                                order, support your experience throughout this
                                website, and for other purposes described in our{" "}
                                <a href="terms.html" target="_blank">
                                  privacy policy
                                </a>
                                .
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="checkout-form">
                        <div className="checkout__totals-wrapper">
                          <div className="mobile_fixed-btn_wrapper">
                            <button
                              onClick={handleCheckout}
                              disabled={checkoutLoading}
                              className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300"
                            >
                              {checkoutLoading ? "Processing" : "Donate Order"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </section>
            )}
          </div>
        </section>

        {/* <div class="brand__area-five">
        <div class="container">
            <div class="swiper brand-active">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="brand__item brand__item-two">
                            <img src="assets/img/brand/h2_brand_img01.png" alt="img"/>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="brand__item brand__item-two">
                            <img src="assets/img/brand/h2_brand_img02.png" alt="img"/>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="brand__item brand__item-two">
                            <img src="assets/img/brand/h2_brand_img03.png" alt="img"/>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="brand__item brand__item-two">
                            <img src="assets/img/brand/h2_brand_img04.png" alt="img"/>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="brand__item brand__item-two">
                            <img src="assets/img/brand/h2_brand_img05.png" alt="img"/>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="brand__item brand__item-two">
                            <img src="assets/img/brand/h2_brand_img06.png" alt="img"/>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="brand__item brand__item-two">
                            <img src="assets/img/brand/h2_brand_img07.png" alt="img"/>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="brand__item brand__item-two">
                            <img src="assets/img/brand/h2_brand_img03.png" alt="img"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
      </main>
    </div>
  );
}

export default Commercial;
