import { Box, Button, Typography, useTheme } from "@mui/material";
import FlexBetween from "../../components/FlexBetween";
import Friend from "../../components/Friend";
import Pet from "../../components/Pet";
import WidgetWrapper from "../../components/WidgetWrapper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CreatePetModal from "./CreatePetModal";
import { setPets } from "../../store/reducers/authReducer";
import EditPetProfileModal from "./EditPetModal";
import PetTabProfile from "../../components/PetTabProfile";
import { useGetPetaccoUserQuery } from "../../store/services/allpetService";

import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useNavigate } from "react-router-dom";
import { EditOutlined } from "@mui/icons-material";

const ProfilePetsWidget = ({ userId, isProfile = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const [modal_open, setmodal_open] = useState(false);
  const token = useSelector((state) => state.authReducer.token);
  const pets = useSelector((state) => state.authReducer.user.pets);
  const [alldata, setalldata] = useState(null);
  const [editmodalopen, seteditmodalopen] = useState(false);
  const {
    data: petsData = [],
    error,
    isLoading,
  } = useGetPetaccoUserQuery(userId, {
    skip: !userId, // Skip the query if userId is not defined
  });

  useEffect(() => {
    // Dispatch pets data to Redux store on successful query
    if (petsData) {
      dispatch(setPets({ pets: petsData }));
    }
  }, [dispatch, petsData]);

  let i = 1;
  return (
    <WidgetWrapper>
      <EditPetProfileModal
        open={editmodalopen}
        setOpen={seteditmodalopen}
        userdata={alldata}
      />
      <CreatePetModal open={modal_open} setOpen={setmodal_open} />
      <FlexBetween gap="0.5rem">
        <h2>{!isProfile ? "My Pets" : "Owned Pets"}</h2>
        {!isProfile && (
          <Button
            size="small"
            onClick={() => setmodal_open(true)}
            sx={{
              color: palette.background.alt,
              backgroundColor: palette.primary.main,
              borderRadius: "3rem",
            }}
          >
            Add
          </Button>
        )}
      </FlexBetween>

      <div className="container ">
        <div className="row  ">
          {/* <Box display="flex" flexDirection="row" gap="1.5rem" > */}
          <div className="profiles-display">
            {/* <PetTabProfile
              onpress={(data) => {
                setalldata(data);
                seteditmodalopen(true);
              }}
              alldata={friend}
              key={friend._id}
              friendId={friend._id}
              name={`${friend.name}`}
              subtitle={friend.breed}
              userPicturePath={friend.picturePath}
              isProfile={isProfile}
            /> */}
            {pets?.length > 0 && (
              <Swiper
                navigation={true}
                modules={[Navigation]}
                spaceBetween={10}
                slidesPerView={10}
                Navigation
                className="w-full h-full"
                breakpoints={{
                  0: {
                    slidesPerView: 1.5,
                  },
                  768: {
                    slidesPerView: 1.5,
                  },
                  1080: {
                    slidesPerView: 4.5,
                  },
                  1280: {
                    slidesPerView: 4.5,
                  },
                }}
              >
                {pets?.map((friend, index) => {
                  if (i >= 5) {
                    i = 1;
                  } else {
                    i++;
                  }
                  return (
                    <SwiperSlide
                      className="w-full h-full overflow-hidden  relative"
                      key={index}
                      virtualIndex={index}
                    >
                      <div className="w-full h-full">
                        <div className="one-profile online">
                          <div
                            className="img"
                            onClick={() =>
                              navigate(`/contactprofile/${friend._id}`, {
                                state: { userId: friend._id },
                              })
                            }
                          >
                            <img
                              src={`https://api.petzoy.in/assets/${friend.picturePath}`}
                            />
                          </div>
                          <h4
                            onClick={() => {
                              setalldata(friend);
                              seteditmodalopen(true);
                            }}
                          >
                            {friend.name} <EditOutlined />
                          </h4>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}
            {/* pets?.map((friend) => (
                <div
                  className="one-profile online"
                  onClick={() => {
                    setalldata(friend);
                    seteditmodalopen(true);
                  }}
                >
                  <div className="img">
                    <img
                      src={`https://api.petzoy.in/assets/${friend.picturePath}`}
                    />
                  </div>
                  <h4>{friend.name}</h4>
                </div>
              )) */}
          </div>
        </div>
      </div>
    </WidgetWrapper>
  );
};

export default ProfilePetsWidget;
