import {
    Avatar,
    Box,
    Button,
    Chip,
    Divider,
    Modal,
    TextField,
    Typography,
    useTheme,
    Checkbox,
    Card,
    CardActions,
    CardContent,
    Switch,
    useMediaQuery,
    styled,
    FormControlLabel,
    MenuItem,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { useNavigate } from "react-router";
import WidgetWrapper from "../../components/WidgetWrapper";
import Friend from "../../components/Friend";
  
  const CommentPostModal = ({ open, setOpen,  postId,
    postUserId,
    name,
    description,
    location,
    picturePath,
    mediaType,
    userPicturePath,
    likes,
    comments,
    tag,
    postType,
    brandId, }) => {
        
    const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
    const theme = useTheme();
    const navigate = useNavigate();


    const [isComments, setIsComments] = useState(false);
    const dispatch = useDispatch();
    const token = useSelector((state) => state.authReducer.token);
    const loggedInUserId = useSelector((state) => state.authReducer.user._id);
    const isLiked = Boolean(likes[loggedInUserId]);
    const likeCount = Object.keys(likes).length;
  
  
    const [show_Commentbox, setshow_Commentbox] = useState(false)
  
    const { palette } = useTheme();
    const main = palette.neutral.main;
    const primary = palette.primary.main;
   
  
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
  
      setOpen(false);
    };
    const [commentInput, setCommentInput] = useState("");
    const handleSubmitComment = () => {
        // Handle submitting the comment, e.g., dispatching to Redux or calling an API
        console.log("Submitted comment:", commentInput);
        // Clear the comment input field
        setCommentInput("");
      };
  
  
    
  
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "scroll",
          bgcolor: "rgba(0,0,0,0)",
          border: "2px solid #000",
          flexDirection: "column",
          boxShadow: 24,
          height: '100%',
  
        }}
      >
        <>     
          <Box
            sx={{
              width: isNonMobileScreens ? "30%" : "100%",
              maxWidth: "800px",
              bgcolor: "white",
  
              borderRadius: isNonMobileScreens ? 5 : 0,
              boxShadow: 24,
              height: isNonMobileScreens ? "90%" : "90%", paddingBottom: 1,  overflowY: "scroll", maxHeight: isNonMobileScreens ? "80%" : "100%",
              // marginBottom:isNonMobileScreens? "0%":"20%"
            }}
          >
  
   
  <WidgetWrapper m="0.5rem 0">
      <Friend
        friendId={postUserId}
        name={postType == "Brand" ? brandId.name: name}
        subtitle={postType == "Brand" ? brandId.address:location}
        userPicturePath={postType == "Brand" ? brandId.picturePath:userPicturePath}
        tag={tag}
        brand={brandId}
      />
      <Typography color={main} sx={{ mt: "2rem" }}>
        {description}
      </Typography>
      {picturePath && (
       mediaType == "image"? <img
       width="100%"
       height="auto"
       alt="post"
       style={{ borderRadius: "0.75rem", marginTop: "0.75rem" }}
       src={`https://api.petzoy.in/assets/${picturePath}`}
     />:mediaType == "video" &&
     <video
     width="100%"
     height="auto"
     controls
     style={{ borderRadius: "0.75rem", marginTop: "0.75rem" }}
   >
     <source
       src={`https://api.petzoy.in/assets/${picturePath}`}
       type="video/mp4"
     />
     Your browser does not support the video tag.
   </video>
      )}
     
    </WidgetWrapper>



    <Box mt="1rem" width="95%" display="flex" alignItems="center" ml={'10px'}>
          <TextField
            fullWidth
            
            variant="outlined"
            label="Add a comment"
            value={commentInput}
            onChange={(e) => setCommentInput(e.target.value)}
            sx={{ mr: "1rem", }} // Add margin-right for spacing between input and button
          />
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: "1rem" }}
            onClick={handleSubmitComment}
          >
            Comment
          </Button>
        </Box>


        {/* Display Comments */}
        <Box mt="2rem"  ml={'10px'}>
          <Typography variant="h5" sx={{ mb: "1rem" }}>
            Comments
          </Typography>
          <Box sx={{ maxHeight: "300px", overflowY: "auto" }}>
            {comments.map((comment, index) => (
              <Box key={index} sx={{ mb: "1rem" }}>
                <Typography variant="body1">{comment.text}</Typography>
                <Typography variant="subtitle2">{comment.user}</Typography>
                <Divider sx={{ mt: "0.5rem" }} />
              </Box>
            ))}
          </Box>
        </Box>
      </Box></>
  
      </Modal>
    );
  };
  
  export default CommentPostModal;
  