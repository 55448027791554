import React from "react";

import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";
import { useUsersbyServiceQuery } from "../../store/services/homeProducts";
import { Link } from "react-router-dom";
import { Virtual, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Moment from "react-moment";
import { useSelector } from "react-redux";

const RandomServicesUser = ({ categoryy }) => {
  let i = 1;
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  const latlong = useSelector((state) => state.authReducer.latlong);
  const { data, isFetching } = useUsersbyServiceQuery({
    service: categoryy._id,
    limit: 15,
    latitude:latlong?.latitude,
    longitude:latlong?.longitude
  });

  console.log(categoryy.type, "main type");
  return isFetching ? (
    <></>
  ) : (
    data?.length > 0 && (
      <>
        <section className="team__area">
          <div className="container">
            <div className="col-lg-12">
              <div className="section__title text-center">
                <span className="sub-title">
                  {categoryy.name}
                  <strong className="shake">
                    <img
                      src="assets/img/icon/pet_icon02.svg"
                      alt=""
                      className="injectable"
                    />
                  </strong>
                </span>
              </div>
            </div>

            <div className="row justify-content-center">
              <Swiper
                navigation={true}
                modules={[Navigation]}
                spaceBetween={15}
                slidesPerView={10}
                // virtual
                Navigation
                className="w-full "
                breakpoints={{
                  0: {
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1080: {
                    slidesPerView: 5,
                  },
                  1280: {
                    slidesPerView: 5,
                  },
                }}
              >
                {data.map((category, index) => {
                  if (i >= 5) {
                    i = 1;
                  } else {
                    i++;
                  }
                  return (
                    <SwiperSlide
                      className="w-full overflow-hidden  relative soccer"
                      key={index}
                      virtualIndex={index}
                    >
                      {categoryy.type == "owner" ? (
                        <Link
                          to={{
                            pathname: `/userconnect/${category._id}`,
                          }}
                          state={{
                            userId: category._id,
                          }}
                          className="w-full "
                        >
                          <div class="main-box-container" id="usermaincard">
                            <div class="box-container">
                              <img
                                src={`https://api.petzoy.in/assets/${category?.picturePath}`}
                              />
                              <h3>
                                {category?.private && (
                                  <i class="fa-solid fa-lock"></i>
                                )}{" "}
                                {category?.firstName}{" "}
                              </h3>

                              <p>
                                <p>
                                  {category?.providedServices?.map(
                                    (item, index) => (
                                      <React.Fragment key={index}>
                                        <small>{item.name}</small>({" "}
                                        <span>{item.title}</span>)
                                        <br />
                                      </React.Fragment>
                                    )
                                  )}
                                </p>
                              </p>
                              {category?.pets?.length != 0 && (
                                <p>{category?.pets?.length} Pet's Owner</p>
                              )}

                              <h6>
                                <i class="fa-solid fa-circle-arrow-right"></i>{" "}
                              </h6>
                            </div>
                          </div>
                        </Link>
                      ) : (
                        <Link
                          to={{
                            pathname: `/contactprofile/${category._id}`,
                          }}
                          state={{
                            userId: category._id,
                          }}
                          // to={`/shop/${category.name}`}
                          className="w-full  "
                        >
                          {/* <div class="wrapper" id="petcard">
                            <div class="card">
                              <p className="pt-1">

                                <i class={category?.gender == "male" ? "fa-solid fa-mars " : "fa-solid fa-venus"}></i>


                                {" "}  {category?.age} Yrs.
                                {" "}
                                {category?.type == "Dog" ? <i class="fa-solid fa-shield-dog" /> : <i class="fa-solid fa-cat" />}  {category?.breed}

                              </p>
                              <div class="image">
                                <img src={`https://api.petzoy.in/assets/${category?.picturePath}`} alt="" />
                              </div>
                              <div class="button">{category?.name}  </div>
                            </div>

                          </div> */}

                          <div class="petcard">
                            <div className="petImageContainer">
                              <img
                                class="card-image"
                                src={`https://api.petzoy.in/assets/${category?.picturePath}`}
                                alt="Dog"
                              />
                            </div>
                            <div class="card-content">
                              <div className="userMainHeading">
                                <h3 class="button">{category?.name}</h3>
                                <img
                                  class="user-Image"
                                  src={`https://api.petzoy.in/assets/${category?.owner?.picturePath}`}
                                  alt="Dog"
                                />
                              </div>
                              <div class="card-info">
                                {!category?.private ? (
                                  <span class="card-icon">
                                    {category?.private && (
                                      <i class="fa-solid fa-lock" />
                                    )}{" "}
                                    {"  "}
                                    {category?.type == "Dog" ? (
                                      <i class="fa-solid fa-shield-dog" />
                                    ) : (
                                      <i class="fa-solid fa-cat" />
                                    )}{" "}
                                    {category?.breed}
                                  </span>
                                ) : (
                                  <i class="fa-solid fa-lock" />
                                )}
                                {!category?.private && (
                                  <span class="card-date">
                                    <i
                                      class={
                                        category?.gender == "male"
                                          ? "fa-solid fa-mars "
                                          : "fa-solid fa-venus"
                                      }
                                    ></i>{" "}
                                                <Moment
        fromNow
        style={{
          color: "#505050",
          fontSize: "10px",
        }}
      >
        {category?.age}
      </Moment>{" "}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </Link>
                      )}
                    </SwiperSlide>
                  );
                })}
            {data.length > 10 &&     <SwiperSlide className="w-full h-full rounded-lg overflow-hidden relative">
        <Link to={`/allserviceuser/${categoryy._id}`} state={{categoryy:categoryy}} className="w-full h-full flex items-center justify-center bg-gray-200">
          <h3 className="text-xl font-bold text-gray-800">View All</h3>
        </Link>
      </SwiperSlide>}
              </Swiper>

              {/* {data?.map((item) => {
       return( <Link
        to={`/shop/${item.name}`}
        className="w-full h-[200px] "
      >
        <div className="col-xl-12" key={item._id}>
                <div className="animal__item animal__item-two shine-animate-item">
                    <div className="animal__thumb animal__thumb-two shine-animate">
                        <a href="animal-details.html"><img src={`https://api.petzoy.in/assets/${item?.picturePath}`} alt="img"/></a>
                        <a href="animal-details.html" className="wishlist"><i className="flaticon-love"></i></a>
                    </div>
                    <div className="animal__content animal__content-two">
                        <h4 className="title"><a href="animal-details.html">{item?.firstName}</a></h4>
                        <div className="pet-info">
                            <ul className="list-wrap">
                            
                                <li><span>{item?.occupation}</span></li>
                            </ul>
                        </div>
                        <div className="location">
                            <i className="flaticon-placeholder"></i>
                            <span>{item?.location}</span>
                        </div>
                    </div>
                </div>
            </div>
      </Link>
      )})} */}
            </div>
            {/* <div className="team__bottom-content">
                <p>Connect With your nearby at one Spot</p>
                <a href="team.html" className="btn">See All Nearby Friends <img src="assets/img/icon/right_arrow.svg" alt="" className="injectable"/></a>
            </div> */}
          </div>
          <div className="team__shape">
            <img
              src="assets/img/team/team_shape.png"
              alt="img"
              className="ribbonRotate"
            />
          </div>
        </section>
      </>
    )
  );
};

export default RandomServicesUser;
