import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../common/pagination.jsx";
import ListGroup from "../listgroup.jsx";
import Posts from "../posts.jsx";
import { paginate } from "../../utils/paginate.js";
import { api } from "../../config.js";
import http from "../../services/httpService.js";
import Jumbotron from "../common/jumbotron.jsx";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import WhiteHeader from "../WhiteHeader/index.js";
import Loader from "../Loader/index.js";
import { useGetPetTypesQuery } from "../../store/services/pettypeService.js";
import "./dashboard.css";
import { useSelector } from "react-redux";

const Dashboard = ({  }) => {
  const { userToken, user } = useSelector((state) => state.authReducer);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { palette } = useTheme();
  const navigate = useNavigate();
  const [loading, setloading] = useState(true);
  const [allPosts, setAllPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(4);
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState({
    _id: "1",
    name: "All Posts",
  });
  const { data: userdata, error: usererror } = useGetPetTypesQuery();
  const [selectedPetType, setSelectedPetType] = useState(null);
  const [selectedBreed, setSelectedBreed] = useState(null);

  useEffect(() => {
    const fetchPostsAndTags = async () => {
      try {
        const { data: fetchedAllPosts } = await http.get(api.postsEndPoint);
        const { data: fetchedTags } = await http.get(api.tagsEndPoint);
        setAllPosts([...fetchedAllPosts]);
        setTags([
          {
            _id: "1",
            name: "All Posts",
          },
          ...fetchedTags,
        ]);
        setloading(false);
      } catch (ex) {
        console.error(ex);
      }
    };

    fetchPostsAndTags();
  }, []);

  const handleTagSelect = (tag) => {
    setSelectedTag(JSON.parse(tag));
    setSelectedPetType(null); // Reset pet type and breed selections
    setSelectedBreed(null);
    setCurrentPage(1);
  };

  const handlePetTypeSelect = (petType) => {
    setSelectedPetType(petType);
    setSelectedTag({ _id: "1", name: "All Posts" }); // Reset tag selection
    setSelectedBreed(null);
    setCurrentPage(1);
  };

  const handleBreedSelect = (breed) => {
    setSelectedBreed(breed);
    setSelectedTag({ _id: "1", name: "All Posts" }); // Reset tag selection
    setCurrentPage(1);
  };

  const getFilteredPosts = () => {
    let filtered =
      selectedTag._id === "1"
        ? allPosts
        : allPosts.filter((post) =>
            post.tags.some((tag) => tag.name === selectedTag.name)
          );

    if (selectedPetType != null) {
      console.log(
        "filte running",
        selectedPetType,
        filtered.filter(
          (post) => post.pettag && post.pettag.type == selectedPetType.petType
        )
      );
      filtered = filtered.filter(
        (post) => post.pettag && post.pettag.type == selectedPetType.petType
      );
    }

    if (selectedBreed) {
      filtered = filtered.filter(
        (post) => post.pettag && post.pettag.breed == selectedBreed.breedName
      );
    }
    console.log(filtered);
    return filtered;
  };

  const filteredPosts = getFilteredPosts();
  const posts = paginate(filteredPosts, currentPage, pageSize);

  const groupPostsByTags = (posts) => {
    const groupedPosts = {};

    posts.forEach((post) => {
      if (post.tags.length > 0) {
        post.tags.forEach((tag) => {
          if (!groupedPosts[tag.name]) {
            groupedPosts[tag.name] = [];
          }
          groupedPosts[tag.name].push(post);
        });
      } else {
        if (!groupedPosts["General"]) {
          groupedPosts["General"] = [];
        }
        groupedPosts["General"].push(post);
      }
    });

    return groupedPosts;
  };

  const groupedPosts = groupPostsByTags(filteredPosts);

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="main_Container">
        <div className="section_One">
          <div className="pet-png">
            <img
              src="assets/img/images/breadcrumb_shape01.png"
              alt="img"
              data-aos="fade-down-right"
              data-aos-delay="400"
            />
            <img
              src="assets/img/images/breadcrumb_shape02.png"
              alt="img"
              data-aos="fade-up-left"
              data-aos-delay="400"
            />
          </div>
          <div className="section_One_Main">
            <div className="main_Heading">
              <h1 className="main_title">PetoPedia</h1>
            </div>
            <div className="total_Post_Container">
              <p>Showing {filteredPosts.length} posts.</p>
            </div>
            <div className="section_One_Container_Two">
              <div className="blog-widget">
                <h4 className="widget-title">Topics</h4>
                <div className="sidebar-tag-list">
                  <ul className="list-wrap">
                    <FormControl fullWidth>
                      <Select onChange={(e) => handleTagSelect(e.target.value)}>
                        {tags.map((item) => (
                          <MenuItem
                            key={item.name}
                            value={JSON.stringify(item)}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </ul>
                </div>
              </div>

              <div className="blog-widget">
                <h4 className="widget-title">Pet Type</h4>
                <div className="sidebar-tag-list">
                  <ul className="list-wrap">
                    {[
                      {
                        _id: "1",
                        petType: "All",
                      },
                      ...(userdata || []),
                    ]?.map((item) => (
                      <li key={item._id}>
                        <a
                          href="#"
                          onClick={() =>
                            item._id == "1"
                              ? handleTagSelect(
                                  JSON.stringify({
                                    _id: "1",
                                    name: "All Posts",
                                  })
                                )
                              : handlePetTypeSelect(item)
                          }
                        >
                          {item.petType}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              {selectedPetType && (
                <div className="blog-widget">
                  <h4 className="widget-title">Pet Breeds</h4>
                  <div className="sidebar-tag-list">
                    <ul className="list-wrap">
                      {[
                        {
                          _id: "1",
                          breedName: "All",
                        },
                        ...selectedPetType.petBreeds,
                      ].map((item) => (
                        <li key={item._id}>
                          <a
                            href="#"
                            onClick={() =>
                              item._id == "1"
                                ? handleBreedSelect(null)
                                : handleBreedSelect(item)
                            }
                          >
                            {item.breedName}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/*  */}

        <div className="section_Two">
          <div className="pet-png">
            <img
              src="assets/img/images/breadcrumb_shape01.png"
              alt="img"
              data-aos="fade-down-right"
              data-aos-delay="400"
            />
            <img
              src="assets/img/images/breadcrumb_shape02.png"
              alt="img"
              data-aos="fade-up-left"
              data-aos-delay="400"
            />
          </div>
         {user && <div className="post_Create_Section">
            <Button
              onClick={() =>
                navigate("/new-post", {
                  state: { tagdata: null },
                })
              }
              sx={{
                color: palette.background.alt,
                backgroundColor: "#894B8D",
                borderRadius: "3rem",
              }}
            >
              + Create Post
            </Button>
          </div>}
          <div className="post_list">
            {Object.keys(groupedPosts).map((tag) => (
              <div key={tag}>
                <div className="post_tag">
                  <h4 className="widget-title">{tag}</h4>
                </div>
                <Swiper
            spaceBetween={20}
            slidesPerView={3.5}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            }}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 1,
              },
              1080: {
                slidesPerView: 3.5,
              },
              1280: {
                slidesPerView: 3.5,
              },
            }}
            className="post_Rows"
          >
                <div className="post_Rows">
                  {groupedPosts[tag].map((item) => (
                    <SwiperSlide key={item._id}>
                    <a
                      onClick={() =>
                        navigate(`/post/${item._id}`, {
                          state: { postId: item._id },
                        })
                      }
                    >
                      <div className="main_Post-Container" key={item._id}>
                        <div className="main_Post">
                          <h2 className="main_title-Text">{item.title}</h2>
                        </div>

                        <div className="tag_Main-Container">
                          <div className="tag_main">
                            {item.tags.map((tag) => (
                              <div key={tag._id}>
                                <p>{tag.name}</p>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="blog__post-meta">
                          <ul className="list-wrap">
                            <li>
                              <i className="flaticon-calendar"></i>
                              {item.time.split("T")[0]}
                            </li>
                            <li>
                              <i className="flaticon-user"></i>by{" "}
                              <strong
                                onClick={() =>
                                  navigate(`/userconnect/${item.author._id}`, {
                                    state: {
                                      userId: item.author._id,
                                    },
                                  })
                                }
                              >
                                {item.author?.firstName}
                              </strong>{" "}
                              {item.pettag && "for"}{" "}
                              {item.pettag && (
                                <strong
                                  onClick={() =>
                                    navigate(`/contactprofile/${item.pettag._id}`, {
                                      state: {
                                        userId: item.pettag._id,
                                      },
                                    })
                                  }
                                >
                                  {item.pettag?.name}
                                </strong>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </a>
                    </SwiperSlide>
                  ))}
                </div>
                </Swiper>
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;


{/* <>
  <div>
    <main>
      <section className="breadcrumb__area fix">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-8">
              <div className="breadcrumb__content">
                <h3 className="title">PetoPedia</h3>
                <nav className="breadcrumb">
                  <span property="itemListElement" typeof="ListItem">
                    <a href="">Home</a>
                  </span>
                  <span className="breadcrumb-separator">
                    <i className="flaticon-right-arrow-angle"></i>
                  </span>
                  <span property="itemListElement" typeof="ListItem">
                    All Discussion
                  </span>
                </nav>
                {!isNonMobileScreens && (
                  <>
                    <div className="blog-widget">
                      <FormControl fullWidth>
                        <Select
                          label={"Tags"}
                          onChange={(e) => handleTagSelect(e.target.value)}
                        >
                          {tags.map((item) => (
                            <MenuItem
                              key={item.name}
                              value={JSON.stringify(item)}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <div className="sidebar-tag-list mt-1">
                        <ul className="list-wrap">
                          {[
                            {
                              _id: "1",
                              petType: "All",
                            },
                            ...(userdata || []),
                          ]?.map((item) => (
                            <li key={item._id}>
                              <a
                                href="#"
                                onClick={() =>
                                  item._id == "1"
                                    ? handleTagSelect(
                                        JSON.stringify({
                                          _id: "1",
                                          name: "All Posts",
                                        })
                                      )
                                    : handlePetTypeSelect(item)
                                }
                              >
                                {item.petType}
                              </a>
                            </li>
                          ))}
                        </ul>
                        {selectedPetType && (
                          <ul className="list-wrap mt-1">
                            {[
                              {
                                _id: "1",
                                breedName: "All",
                              },
                              ...selectedPetType.petBreeds,
                            ].map((item) => (
                              <li key={item._id}>
                                <a
                                  href="#"
                                  onClick={() =>
                                    item._id == "1"
                                      ? handleBreedSelect(null)
                                      : handleBreedSelect(item)
                                  }
                                >
                                  {item.breedName}
                                </a>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>

                    <div className="container">
                      <div className="row">
                        <div className="col">
                          <div className="d-flex w-100 justify-content-between">
                            Showing {filteredPosts.length} posts.
                            {user && (
                              <Button
                                onClick={() =>
                                  navigate("/new-post", {
                                    state: { tagdata: null },
                                  })
                                }
                                sx={{
                                  color: palette.background.alt,
                                  backgroundColor: "#894B8D",
                                  borderRadius: "3rem",
                                }}
                              >
                                NEW POST
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* <div className="blog-widget">
                  <FormControl fullWidth>
                    <Select
                      label={"Pet Type"}
                      onChange={(e) => handlePetTypeSelect(e.target.value)}
                    >
                      {userdata?.map((item) => (
                        <MenuItem key={item._id} value={JSON.stringify(item)}>
                          {item.petType}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div> */}
//             <div className="col-lg-4">
//               <div className="breadcrumb__img">
//                 <img
//                   src="assets/img/images/breadcrumb_img.png"
//                   alt="img"
//                   data-aos="fade-left"
//                   data-aos-delay="800"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="breadcrumb__shape-wrap">
//           <img
//             src="assets/img/images/breadcrumb_shape01.png"
//             alt="img"
//             data-aos="fade-down-right"
//             data-aos-delay="400"
//           />
//           <img
//             src="assets/img/images/breadcrumb_shape02.png"
//             alt="img"
//             data-aos="fade-up-left"
//             data-aos-delay="400"
//           />
//         </div>
//       </section>
//       {/* */}
//       <section className="blog__area pb-100">
//         <div className="container">
//           <div className="row">
//             <div className="col-xl-9 col-lg-8 order-0 order-lg-2">
//               <div className="row">
//                 {/* {filteredPosts?.map((item) => (
//                       <div className="col-md-4">
//                         <div className="blog__post-item-three blog__post-item-five shine-animate-item">
//                           <div className="blog__post-content-three">
//                             <div className="blog__post-meta">
//                               <ul className="list-wrap blog__post-tag blog__post-tag-two">
//                                 {item.tags.map((tag) => (
//                                   <li>
//                                     <a href="blog.html">{tag?.name}</a>
//                                   </li>
//                                 ))}
//                               </ul>
//                             </div>
//                             <div className="blog__post-meta">
//                               <ul className="list-wrap">
//                                 <li>
//                                   <i className="flaticon-calendar"></i>
//                                   {item.time.split("T")[0]}
//                                 </li>
//                                 <li>
//                                   <i className="flaticon-user"></i>by{" "}
//                                   <strong
//                                     onClick={() =>
//                                       navigate("/userconnect", {
//                                         state: { userId: item.author._id },
//                                       })
//                                     }
//                                   >
//                                     {item.author?.firstName}
//                                   </strong>{" "}
//                                   {item.pettag && "for"}{" "}
//                                   {item.pettag && (
//                                     <strong
//                                       onClick={() =>
//                                         navigate("/contactprofile", {
//                                           state: { userId: item.pettag._id },
//                                         })
//                                       }
//                                     >
//                                       {item.pettag?.name}
//                                     </strong>
//                                   )}
//                                 </li>
//                               </ul>
//                             </div>

//                             <h2 className="title">
//                               <a
//                                 onClick={() =>
//                                   navigate("/post", {
//                                     state: { mainid: item._id },
//                                   })
//                                 }
//                               >
//                                 {item.title}
//                               </a>
//                             </h2>
//                           </div>
//                         </div>
//                       </div>
//                     ))} */}

//                 {Object.keys(groupedPosts).map((tag) => (
//                   <div key={tag}>
//                     <div className="">
//                       <h4 className="widget-title">{tag}</h4>
//                     </div>
//                     <div className="posts-row">
//                       {groupedPosts[tag].map((item) => (
//                         <div className="post-item" key={item._id}>
//                           <div className="blog__post-item-three blog__post-item-five shine-animate-item">
//                             <div className="blog__post-content-three">
//                               <div className="blog__post-meta">
//                                 <ul className="list-wrap blog__post-tag blog__post-tag-two">
//                                   {item.tags.map((tag) => (
//                                     <li key={tag._id}>
//                                       <a href="blog.html">{tag.name}</a>
//                                     </li>
//                                   ))}
//                                 </ul>
//                               </div>
//                               <div className="blog__post-meta">
//                                 <ul className="list-wrap">
//                                   <li>
//                                     <i className="flaticon-calendar"></i>
//                                     {item.time.split("T")[0]}
//                                   </li>
//                                   <li>
//                                     <i className="flaticon-user"></i>by{" "}
//                                     <strong
//                                       onClick={() =>
//                                         navigate("/userconnect", {
//                                           state: {
//                                             userId: item.author._id,
//                                           },
//                                         })
//                                       }
//                                     >
//                                       {item.author?.firstName}
//                                     </strong>{" "}
//                                     {item.pettag && "for"}{" "}
//                                     {item.pettag && (
//                                       <strong
//                                         onClick={() =>
//                                           navigate("/contactprofile", {
//                                             state: {
//                                               userId: item.pettag._id,
//                                             },
//                                           })
//                                         }
//                                       >
//                                         {item.pettag?.name}
//                                       </strong>
//                                     )}
//                                   </li>
//                                 </ul>
//                               </div>

//                               <h2 className="title">
//                                 <a
//                                   onClick={() =>
//                                     navigate("/post", {
//                                       state: { postId: item._id },
//                                     })
//                                   }
//                                 >
//                                   {item.title}
//                                 </a>
//                               </h2>
//                             </div>
//                           </div>
//                         </div>
//                       ))}
//                     </div>
//                   </div>
//                 ))}
//               </div>
//               {/* <nav className="pagination__wrap mt-50">
//                     <ul className="list-wrap">
//                       <li className="link-arrow">
//                         <a href="#">
//                           <img
//                             src="assets/img/icon/pagination_icon01.svg"
//                             alt=""
//                             className="injectable"
//                           />
//                         </a>
//                       </li>
//                       <li className="active">
//                         <a href="#">1</a>
//                       </li>
//                       <li>
//                         <a href="courses.html">2</a>
//                       </li>
//                       <li>
//                         <a href="courses.html">3</a>
//                       </li>
//                       <li>
//                         <a href="courses.html">4</a>
//                       </li>
//                       <li className="link-arrow">
//                         <a href="#">
//                           <img
//                             src="assets/img/icon/pagination_icon02.svg"
//                             alt=""
//                             className="injectable"
//                           />
//                         </a>
//                       </li>
//                     </ul>
//                   </nav> */}
//             </div>
//             <div className="col-xl-3 col-lg-4">
//               {isNonMobileScreens && (
//                 <aside className="blog-sidebar">
//                   <div className="container">
//                     <div className="row">
//                       <div className="col">
//                         <div className="d-flex w-100 justify-content-between m-3">
//                           Showing {filteredPosts.length} posts.
//                           {user && (
//                             <Button
//                               onClick={() =>
//                                 navigate("/new-post", {
//                                   state: { tagdata: null },
//                                 })
//                               }
//                               sx={{
//                                 color: palette.background.alt,
//                                 backgroundColor: "#894B8D",
//                                 borderRadius: "3rem",
//                               }}
//                             >
//                               NEW POST
//                             </Button>
//                           )}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   {/* <div className="blog-widget">
//                         <h4 className="widget-title">Search</h4>
//                         <div className="sidebar-search-form">
//                           <form action="#">
//                             <input
//                               type="text"
//                               placeholder="Type Keywords. . ."
//                             />
//                             <button type="submit">
//                               <i className="flaticon-loupe"></i>
//                             </button>
//                           </form>
//                         </div>
//                       </div> */}

//                   <div className="blog-widget">
//                     <h4 className="widget-title">Topics</h4>
//                     <div className="sidebar-tag-list">
//                       <ul className="list-wrap">
//                         {/* {tags.map((item) => (
//                               <li>
//                                 <a
//                                   href="#"
//                                   onClick={() => handleTagSelect(item)}
//                                 >
//                                   {item.name}
//                                 </a>
//                               </li>
//                             ))} */}
//                         <FormControl fullWidth>
//                           <Select
//                             onChange={(e) => handleTagSelect(e.target.value)}
//                           >
//                             {tags.map((item) => (
//                               <MenuItem
//                                 key={item.name}
//                                 value={JSON.stringify(item)}
//                               >
//                                 {item.name}
//                               </MenuItem>
//                             ))}
//                           </Select>
//                         </FormControl>
//                       </ul>
//                     </div>
//                   </div>
//                   <div className="blog-widget">
//                     <h4 className="widget-title">Pet Type</h4>
//                     <div className="sidebar-tag-list">
//                       <ul className="list-wrap">
//                         {[
//                           {
//                             _id: "1",
//                             petType: "All",
//                           },
//                           ...(userdata || []),
//                         ]?.map((item) => (
//                           <li key={item._id}>
//                             <a
//                               href="#"
//                               onClick={() =>
//                                 item._id == "1"
//                                   ? handleTagSelect(
//                                       JSON.stringify({
//                                         _id: "1",
//                                         name: "All Posts",
//                                       })
//                                     )
//                                   : handlePetTypeSelect(item)
//                               }
//                             >
//                               {item.petType}
//                             </a>
//                           </li>
//                         ))}
//                       </ul>
//                     </div>
//                   </div>
//                   {selectedPetType && (
//                     <div className="blog-widget">
//                       <h4 className="widget-title">Pet Breeds</h4>
//                       <div className="sidebar-tag-list">
//                         <ul className="list-wrap">
//                           {[
//                             {
//                               _id: "1",
//                               breedName: "All",
//                             },
//                             ...selectedPetType.petBreeds,
//                           ].map((item) => (
//                             <li key={item._id}>
//                               <a
//                                 href="#"
//                                 onClick={() =>
//                                   item._id == "1"
//                                     ? handleBreedSelect(null)
//                                     : handleBreedSelect(item)
//                                 }
//                               >
//                                 {item.breedName}
//                               </a>
//                             </li>
//                           ))}
//                         </ul>
//                       </div>
//                     </div>
//                   )}
//                 </aside>
//               )}
//             </div>
//           </div>
//         </div>
//       </section>
//     </main>
//   </div>
// </>; */}