import {
  ManageAccountsOutlined,
  EditOutlined,
  LocationOnOutlined,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import { Box, Typography, Divider, useTheme } from "@mui/material";
import UserImage from "../../components/UserImage";
import FlexBetween from "../../components/FlexBetween";
import WidgetWrapper from "../../components/WidgetWrapper";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const UserWidget = ({ userId, picturePath,setloading,onpress }) => {
  const [user, setUser] = useState(null);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.authReducer.token);
  const dark = palette.neutral.dark;
  const medium = palette.neutral.medium;
  const main = palette.neutral.main;

  const getUser = async () => {
    const response = await fetch(`https://api.petzoy.in/users/${userId}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setUser(data);
    setloading(false)
  };

  useEffect(() => {
    getUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!user) {
    return null;
  }

  const {
    firstName, 
    location,
    occupation,
    viewedProfile,
    impressions,
    friends,
  } = user;

  return (
    // <WidgetWrapper>
      
    //   <FlexBetween
    //     gap="0.5rem"
    //     pb="1.1rem"
    //     onClick={() => navigate("/profile",{ state: {userId:userId}} )}
    //   >
    //     <FlexBetween gap="1rem">
    //       <UserImage image={picturePath} />
    //       <Box>
    //         <Typography
    //           variant="h4"
    //           color={dark}
    //           fontWeight="500"
    //           sx={{
    //             "&:hover": {
    //               color: palette.primary.light,
    //               cursor: "pointer",
    //             },
    //           }}
    //         >
    //           {firstName} 
    //         </Typography>
    //         <Typography color={medium}>{friends?.length} friends</Typography>
    //       </Box>
    //     </FlexBetween>
    //     <ManageAccountsOutlined />
    //   </FlexBetween>

    //   <Divider />


    //   <Box p="1rem 0">
    //     <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
    //       <LocationOnOutlined fontSize="large" sx={{ color: main }} />
    //       <Typography color={medium}>{location}</Typography>
    //     </Box>
    //     <Box display="flex" alignItems="center" gap="1rem">
    //       <WorkOutlineOutlined fontSize="large" sx={{ color: main }} />
    //       <Typography color={medium}>{occupation}</Typography>
    //     </Box>
    //   </Box>

    //   <Divider />


    //   <Box p="1rem 0">
    //     <FlexBetween mb="0.5rem">
    //       <Typography color={medium}>Who's viewed your profile</Typography>
    //       <Typography color={main} fontWeight="500">
    //         {viewedProfile}
    //       </Typography>
    //     </FlexBetween>
    //     <FlexBetween>
    //       <Typography color={medium}>Impressions of your post</Typography>
    //       <Typography color={main} fontWeight="500">
    //         {impressions}
    //       </Typography>
    //     </FlexBetween>
    //   </Box>

    //   <Divider />


    //   <Box p="1rem 0">
    //     <Typography fontSize="1rem" color={main} fontWeight="500" mb="1rem">
    //       Social Profiles
    //     </Typography>

    //     <FlexBetween gap="1rem" mb="0.5rem">
    //       <FlexBetween gap="1rem">
    //         <img src="../assets/twitter.png" alt="twitter" />
    //         <Box>
    //           <Typography color={main} fontWeight="500">
    //             Twitter
    //           </Typography>
    //           <Typography color={medium}>Social Network</Typography>
    //         </Box>
    //       </FlexBetween>
    //       <EditOutlined sx={{ color: main }} />
    //     </FlexBetween>

    //     <FlexBetween gap="1rem">
    //       <FlexBetween gap="1rem">
    //         <img src="../assets/linkedin.png" alt="linkedin" />
    //         <Box>
    //           <Typography color={main} fontWeight="500">
    //             Linkedin
    //           </Typography>
    //           <Typography color={medium}>Network Platform</Typography>
    //         </Box>
    //       </FlexBetween>
    //       <EditOutlined sx={{ color: main }} />
    //     </FlexBetween>
    //   </Box>
    // </WidgetWrapper>


    <Box onClick={onpress} display="flex" flexDirection={"column"} alignItems="center" justifyContent="center">
      <UserImage image={picturePath} size={"55px" }  />
        {/* <Box
         onClick={onpress}
        > */}
          <Typography
            color={main}
            variant="h6"
            fontWeight="500"
            textAlign={"center"}
            sx={{
              "&:hover": {
                color: palette.primary.light,
                cursor: "pointer",
              },
            }}
          >
            {firstName}
          </Typography>
          {/* <Typography color={medium} fontSize="0.75rem">
            {subtitle}
          </Typography> */}
        {/* </Box> */}
    </Box>
  );
};

export default UserWidget;
