import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import FlexBetween from "./FlexBetween";
import UserImage from "./UserImage";
import EditPetProfileModal from '../pages/widgets/EditPetModal';
import { useState } from 'react';
const Pet = ({ onpress,alldata,friendId, name, subtitle, userPicturePath,isProfile = false  }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { palette } = useTheme();
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;
  const main = palette.neutral.main;
  const medium = palette.neutral.medium;


  return (
    // <FlexBetween key={friendId}>
    //   <FlexBetween gap="1rem">
    //     <UserImage image={userPicturePath} size="55px" />
    //     <Box
    //       onClick={() => {
    //       {isProfile ? 
    //         navigate("/petprofile",{ state: {userId:friendId}} )
            
    //         :
    //         navigate("/mypetprofile",{ state: {userId:friendId}} )
    //       }
    //         navigate(0);
    //       }}
    //     >
    //       <Typography
    //         color={main}
    //         variant="h5"
    //         fontWeight="500"
    //         sx={{
    //           "&:hover": {
    //             color: palette.primary.light,
    //             cursor: "pointer",
    //           },
    //         }}
    //       >
    //         {name}
    //       </Typography>
    //       <Typography color={medium} fontSize="0.75rem">
    //         {subtitle}
    //       </Typography>
    //     </Box>
    //   </FlexBetween>
    //   <IconButton
    //     onClick={() => {
    //       {isProfile ? 
    //         navigate("/petprofile",{ state: {userId:friendId}} )
            
    //         :
    //         navigate("/mypetprofile",{ state: {userId:friendId}} )
            
    //       }
    //         navigate(0);
    //       }}
    //     sx={{ backgroundColor: primaryLight, p: "0.6rem" }}
    //   >
    //       <RemoveRedEyeIcon sx={{ color: primaryDark }} />
       
    //   </IconButton>
    // </FlexBetween>

    // <Box  onClick={() => {
    //   // {isProfile ? 
    //   //   navigate("/petprofile",{ state: {userId:friendId}} )
        
    //   //   :
    //   //   navigate("/mypetprofile",{ state: {userId:friendId}} )
    //   // }
    //   //   navigate(0);
    //   onpress(alldata)
    //   }} display="flex" flexDirection={"column"} alignItems="center" justifyContent="center">
        
    //   <UserImage image={userPicturePath} size="55px"  />
    //     <Box
    //       onClick={() => {
    //       // {isProfile ? 
    //       //   navigate("/petprofile",{ state: {userId:friendId}} )
            
    //       //   :
    //       //   navigate("/mypetprofile",{ state: {userId:friendId}} )
    //       // }
    //       //   navigate(0);
    //       onpress(alldata)
    //       }}
    //     >
    //       <Typography
    //         color={main}
    //         variant="h5"
    //         fontWeight="500"
    //         sx={{
    //           "&:hover": {
    //             color: palette.primary.light,
    //             cursor: "pointer",
    //           },
    //         }}
    //       >
    //         {name}
    //       </Typography>
    //       {/* <Typography color={medium} fontSize="0.75rem">
    //         {subtitle}
    //       </Typography> */}
    //     </Box>
    // </Box>



    <li>
    <span>{name}</span>
    <a onClick={() => {
      // {isProfile ? 
      //   navigate("/petprofile",{ state: {userId:friendId}} )
        
      //   :
      //   navigate("/mypetprofile",{ state: {userId:friendId}} )
      // }
      //   navigate(0);
      onpress(alldata)
      }}>
    <img src={'https://api.petzoy.in/assets/'+userPicturePath}/>
    </a>
  </li>
  );
};

export default Pet;
