import {
    Avatar,
    Box,
    Button,
    Chip,
    Divider,
    Modal,
    TextField,
    Typography,
    useTheme,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    useMediaQuery,
    MenuItem,
    FormControlLabel,
    Checkbox,
  } from "@mui/material";
  import { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
import { useCreateBrandMutation } from "../../../store/services/brandService";
import { useAddAddressMutation, useGetUserAddressesQuery, useUpdateAddressMutation } from "../../../store/services/addressService";
  
  const EditAddressModal = ({ open, setOpen }) => {
    const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
    const theme = useTheme();
    const [formdata, setformdata] = useState({
      businesstype: "",
      name: "",
      location: "",
      description:"",
      email:"",
      phone:"",
      address:""
    });
    const [selected_type, setselected_type] = useState(null);
    const [preview, setPreview] = useState(null);
    const [imgfile, setimgfile] = useState(null);
  
    const [current_addstep, setcurrent_addstep] = useState(0);
    const { palette } = useTheme();
  
    const token = useSelector((state) => state.authReducer.token);
    const { user } = useSelector((state) => state.authReducer);
    const loggedInUserId = useSelector((state) => state.authReducer.user._id);
    const dispatch = useDispatch();
    const { data: userAddresses , isLoading: isAddressloading } = useGetUserAddressesQuery(user._id);
    const [addAddress, { isLoading: isAddingAddress }] = useAddAddressMutation();
    const [updateAddress, { isLoading: isUpdatingAddress }] = useUpdateAddressMutation();
    
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
      
      setPreview(null);
      setformdata({
        businesstype: "",
      name: "",
      location: "",
      description:"",
      email:"",
      phone:"",
      address:""
      })
      setOpen(false);
  };
  
  


useEffect(() => {
    if (userAddresses && userAddresses.length > 0) {
      const firstAddress = userAddresses[0];
      setFormAddressData({
        ...formaddressData,
        addressLine1: firstAddress.addressLine1,
        state: firstAddress.state,
        city: firstAddress.city,
        postalCode: firstAddress.postalCode,
      });
    }
  }, [userAddresses]);
  
const handleSubmit = async (e) => {
    e.preventDefault();
  
    // if (!validateAddressInputs()) {
    //     return;
    // }
  
    try {
      if (userAddresses && userAddresses.length > 0) {
        // Update the first address found for the user
        await updateAddress({ id: userAddresses[0]._id, addressData: formaddressData }).unwrap();
        setSuccessMessage('Address updated successfully');
    } else {
        // If no address is found, create a new one
        await addAddress(formaddressData).unwrap();
        setSuccessMessage('Address added successfully');
    }
        setSuccessMessage('Address updated successfully');
        // You can add logic to update the UI after successfully updating the address
    } catch (error) {
        setErrorMessages(error.data.errors.map(err => err.msg));
        console.error(error);
    }
  };
    
  
  
  
  
    const handleImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        setimgfile(file);
        setPreview(URL.createObjectURL(file));
      }
    };
  
    const handleFormInput = (event) => {
      console.log(event);
      const { name, value } = event.target;
      setformdata({
        ...formdata,
        [name]: value,
      });
    };
  
    console.log("select type ",selected_type)
    const [createBrand, { isLoading, error }] = useCreateBrandMutation(); // Use the mutation hook

    const handleSave = async () => {
      
  
        const data = {
            name: formdata.name,
            location: formdata.location,
            type: formdata.businesstype,
            description: formdata.description,
            phone: formdata.phone,
            email: formdata.email,
            address: formdata.address,
            userId: loggedInUserId,
          };
      
          // If image is selected, append it to the data
          if (imgfile) {
            data.picture = imgfile;
            data.picturePath = imgfile.name;
          }
  
      const result = await createBrand(data);
      console.log(result)
      setimgfile(null);
      setimgfile("");
      // Here you can dispatch an action to save the pet details
      // For example: dispatch(createPet({ name: name, breed: location, age: petAge, weight: petWeight, image: preview }));
      handleClose();
    };
  
  

    const [errorMessages, setErrorMessages] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');


const [formaddressData, setFormAddressData] = useState({
  addressLine1: '',
  country: 'India',
  state: '',
  city: '',
  postalCode: '',
  userId: user._id
});
const handleaddressInputChange = (e) => {
    setFormAddressData({
        ...formaddressData,
        [e.target.name]: e.target.value
    });
  };
  
  
  
  
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "scroll",
          bgcolor: "rgba(0,0,0,0)",
          border: "2px solid #000",
          flexDirection:"column",
          boxShadow: 24,
        }}
      >
        <>      {!isNonMobileScreens &&
   <Box
          sx={{
            width: "100%",
            maxWidth: "500px",
            bgcolor: palette.primary.main,
        display:"flex",
            boxShadow: 24,
            height:  "70px",alignItems:"center",justifyContent:"space-between",paddingLeft:'10px',paddingRight:'10px'
          }}
        >
          <i onClick={handleClose} class="fa-solid fa-chevron-left"></i>
           <Typography variant="h4" color={"black"}>
                      Products Shipping Address
                    </Typography>
                    <i class="fa-solid fa-paw"></i>
        </Box> }
        <Box
          sx={{
            width: isNonMobileScreens ? "30%" : "100%",
            maxWidth: "800px",
            bgcolor: "white",
            padding: 3,
            borderRadius: isNonMobileScreens ? 5:0,
            boxShadow: 24,
            height: isNonMobileScreens ? "auto" : "100%",
          }}
        >
          
          <Typography id="modal-modal-title" variant="h3" component="h3">
           Shipping Address
          </Typography>
  
         
          <form onSubmit={handleSubmit} className="col-xl-12 col-lg-10 col-md-12 content flex flex-col p-3">
    {errorMessages.length > 0 && (
        <div className="alert alert-danger">
            <ul>
                {errorMessages.map((error, index) => (
                    <li key={index}>{error}</li>
                ))}
            </ul>
        </div>
    )}
    {successMessage && <div className="alert alert-success">{successMessage}</div>}
    <div className="form-group mb-3">
        <textarea className="form-control" name="addressLine1" value={formaddressData.addressLine1} onChange={handleaddressInputChange} rows="5" placeholder="Complete Address *" required></textarea>
    </div>
    <div className="row">
        <div className="col-lg-6 mb-3">
            <div className="form-group">
                <select className="form-control" name="country" value={formaddressData.country} onChange={handleaddressInputChange} required>
                    <option value="India">India</option>
                </select>
            </div>
        </div>
        <div className="col-lg-6 mb-3">
            <div className="form-group">
                <input type="text" className="form-control" name="state" value={formaddressData.state} onChange={handleaddressInputChange} placeholder="State *" required />
            </div>
        </div>
    </div>
    <div className="row">
        <div className="col-lg-6 mb-3">
            <div className="form-group">
                <input type="text" className="form-control" name="city" value={formaddressData.city} onChange={handleaddressInputChange} placeholder="City *" required />
            </div>
        </div>
        <div className="col-lg-6 mb-3">
            <div className="form-group">
                <input type="text" className="form-control" name="postalCode" value={formaddressData.postalCode} onChange={handleaddressInputChange} placeholder="Pincode *" required />
            </div>
        </div>
    </div>
    {/* <div className="form-group">
        <button type="submit" className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300" disabled={isAddingAddress || isUpdatingAddress}>
            {isAddingAddress || isUpdatingAddress ? 'Processing...' : 'Submit'}
        </button>
    </div> */}
</form>

  
          <Box sx={[{ display: "flex", justifyContent: "space-between",
          
           },!isNonMobileScreens && {position:"absolute",bottom:'10px',width:"80%"}]}>
           
            <Button
              variant="outlined"
              size="large"
              onClick={() => {
             
                  handleClose();
                  
              }}
            >
              {"Cancel"}
            </Button>
            <Button variant="contained" size="large" onClick={handleSubmit}>
              {"Save"}
            </Button>
          </Box>
        </Box></>
  
      </Modal>
    );
  };
  
  export default EditAddressModal;
  