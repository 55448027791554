import { SearchCircleIcon, SparklesIcon } from "@heroicons/react/outline";
import TweetInput from "./TweetInput";
import Post from "./Post";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { SearchIcon } from "@heroicons/react/outline";
import { useState } from "react";
const Feed = ({ maindata, tags, onpress,onSearchInputChange,setSearchQuery,tagdata }) => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { user } = useSelector((state) => state.authReducer);
const [showsearch, setshowsearch] = useState(false)
  const ScrollerItem = ({ item }) => (
    <div
      onClick={() => onpress(JSON.stringify(item))}
      className="flex-shrink-0 bg-white border border-gray-300 rounded-lg shadow-md p-2 m-2"
    >
      <div className="text-black font-medium">#{item.name}</div>
    </div>
  );

  const HorizontalScroller = ({ items }) => (
    <div className="flex overflow-x-auto space-x-2 p-2 scrollbar-hidden bg-gray-200">
      {items.map((item, index) => (
        <ScrollerItem key={index} item={item} />
      ))}
    </div>
  );

  return (
    <div className="xl:ml-[260px] border-l border-r border-gray-200 xl:min-w-[576px] sm:ml-[73px] flex-grow max-w-xl">
      <div className="flex py-2 px-3 sticky top-0 z-50 bg-white justify-between items-center border-b border-gray-200">
        <h2 className="text-lg sm:text-xl font-bold cursor-pointer">Petopedia</h2>
        {!isNonMobileScreens && (  <div className="hoverEffect flex items-center justify-center px-0 ml-auto w-9 h-9">
          <SearchCircleIcon className="h-5" onClick={() =>{setshowsearch(!showsearch);
            if(showsearch){
              setSearchQuery("")
            }
           }}/>
        </div>)}
      </div>
      {!isNonMobileScreens && showsearch && (
        <div className="w-[90%] xl:w-[75%] sticky top-0 bg-white py-2 z-50">
        <div className="flex items-center p-3 rounded-full relative ">
          <SearchIcon className="h-5 z-50 text-gray-500" />
          <input
            className="absolute inset-0 rounded-full pl-11 border-gray-500 text-gray-700 focus:shadow-lg focus:bg-white bg-gray-100"
            type="text"
            placeholder="Search Petopedia"
            onChange={onSearchInputChange}
          />
        </div>
      </div>
      )}
      {!isNonMobileScreens && (
        <div className="relative">
          <div className="overflow-x-auto overflow-hidden scrollbar-hidden">
            <HorizontalScroller items={tags} />
          </div>
        </div>
      )}
      {user && <TweetInput tagdata={tagdata}/>}
      {maindata.map((post) => (
        <Post key={post.id} post={post} />
      ))}
    </div>
  );
};

export default Feed;
