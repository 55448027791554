import React, { useState } from "react";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import {
  Box,
  Fab,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Camera, PaperPlaneTilt, Smiley } from "phosphor-react";
import { useZIM } from "../../../hooks/zim";
import SendImageMessage from "../../widgets/SendImageMessage";

const StyledInput = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    paddingTop: "12px !important",
    paddingBottom: "12px !important",
  },
}));

const MobileFooter = ({ maindata, onpress }) => {
  const theme = useTheme();
  const [state, zimAction] = useZIM();
  const [message, setMessage] = useState("");
  const [openPicker, setOpenPicker] = useState(false);
  const [showimagemodal, setshowimagemodal] = useState(false)

  const sendMessage = () => {
    if (message.trim() === "") return;

    zimAction
      .sendChatMessage(maindata.chattype, message, maindata.id, false)
      .then(() => {
        setMessage("");
        setTimeout(() => {
          onpress();
        }, 500);
      });
  };


  const sendMediaMessage = (mainimg) => {
    if (!mainimg) {
      return;
    }
  console.log(mainimg)
    const reader = new FileReader();
    // reader.onload = (e) => {
      // const fileData = e.target.result;
  
      const msgs = { type: 11, fileLocalPath: mainimg };
      zimAction.sendMediaMessage(msgs, maindata.id, maindata.chattype).then(() => {
        
        setTimeout(() => {
          onpress();
        }, 200);
      });
    // };
  
    // reader.readAsDataURL(mainimg);
  };
  

  return (
    <Box
      p={2}
      sx={{
        width: "100%",
        backgroundColor:
          theme.palette.mode === "light"
            ? "#F8FAFF"
            : theme.palette.background.paper,
      }}
      display={{ xs: "block", md: "none" }} // Show only on mobile, hide on desktop
      position="fixed"
      bottom={0}
      left={0}
      zIndex={1000}
    >
        <SendImageMessage open={showimagemodal} setOpen={setshowimagemodal} sendmessage={(e) => {console.log(e); sendMediaMessage(e)}}/>
      <Stack direction="row" alignItems="center" spacing={2} width="100%">
        <IconButton onClick={() => setOpenPicker(!openPicker)}>
          <Smiley />
        </IconButton>
        <StyledInput
          fullWidth
          placeholder="Write a message..."
          variant="filled"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment>
                <IconButton onClick={sendMessage}>
                  <PaperPlaneTilt />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <IconButton>
          <Camera onClick={() =>  setshowimagemodal(true) }/>
        </IconButton>
      </Stack>
      {openPicker && (
        <Box position="absolute" bottom="60px" left="20px" zIndex={2000}>
          <Picker
            data={data}
            onEmojiSelect={(emoji) => {
              setMessage((prevMessage) => prevMessage + emoji.native);
              setOpenPicker(false);
            }}
            theme={theme.palette.mode}
          />
        </Box>
      )}
    </Box>
  );
};

export default MobileFooter;
