import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const discussionPostService = createApi({
  reducerPath: 'discussionPosts',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.petzoy.in/discussionposts/' ,  prepareHeaders: (headers, { getState }) => {
    const reducers = getState();
    const token = reducers?.authReducer?.token;
    headers.set('Authorization', token ? `Bearer ${token}` : '');
    return headers;
  }}),
  endpoints: (builder) => ({
    getAllPosts: builder.query({
      query: () => "/",
      providesTags: ["discussionPosts"],
    }),
    getPostById: builder.query({
      query: (id) => `/${id}`,
      providesTags: (result, error, id) => [{ type: "discussionPosts", id }],
    }),
    createPost: builder.mutation({
      query: (postData) => ({
        url: "/create",
        method: "POST",
        body: postData,
      }),
      invalidatesTags: ["discussionPosts"],
    }),
    likePost: builder.mutation({
      query: (postId) => ({
        url: `/like/${postId}`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, postId) => [
        { type: "discussionPosts", id: postId },
      ],
    }),
    updatePostStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `/status/${id}`,
        method: "PUT",
        body: { status },
      }),
      invalidatesTags: ["discussionPosts"],
    }),
    deletePost: builder.mutation({
      query: (postId) => ({
        url: `/${postId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["discussionPosts"],
    }),
    searchPosts: builder.query({
      query: (query) => ({url:`/search?q=${query}`,method:"POST"}),
      providesTags: ["discussionPosts"],
    }),
    closeDiscussion: builder.mutation({
      query: (postId) => ({
        url: `/close/${postId}`,
        method: "PUT",
      }),
      invalidatesTags: ["discussionPosts"],
    }),
  }),
});

export const {
  useGetAllPostsQuery,
  useGetPostByIdQuery,
  useCreatePostMutation,
  useLikePostMutation,
  useUpdatePostStatusMutation,
  useDeletePostMutation,
  useSearchPostsQuery,
  useCloseDiscussionMutation,
} = discussionPostService;

export default discussionPostService;
