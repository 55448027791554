import React from "react";
import { HiOutlineChevronRight } from "react-icons/hi";
import { Link, useNavigate, useParams } from "react-router-dom";

import { NavLink } from "react-router-dom";
import { BsPersonCircle } from "react-icons/bs";
import { AiOutlineShoppingCart, AiOutlineLogout } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/reducers/authReducer";
import { useDetailsQuery } from "../../store/services/userOrdersService";
import moment from "moment";
import currency from "currency-formatter";
import { discount } from "../../utils/discount";
import { useGetContactUsByIdQuery } from "../../store/services/contactusService";
import { Toaster } from "react-hot-toast";
const UserOrderDetails = ({id}) => {
  const { contactusid } = useSelector((state) => state.authReducer);
  const { data: contactusdata, isFetching: contactusfetch } =
    useGetContactUsByIdQuery(contactusid);
  
  const { data, isFetching } = useDetailsQuery(id);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const total = currency.format(
    discount(
      data?.details?.productId?.price,
      data?.details?.productId?.discount
    ) * data?.details?.quantities,
    {
      code: "INR",
    }
  );
  return (
    <>
      
      <div className="max-w-container mx-auto px-4 w-full flex flex-col ">
        <div className="full-row pt-30" id="prod">
          <div className="container">
            <div className="row mb-4 mb-lg-5 pb-lg-5">
              {/* {anything} */}

              <div className="col-xl-9 col-lg-10 col-md-12 content">
                <div className="tab-content dashboard-content">
                  <div id="dashboard" className="tab-pane fade active show">
                    <div className="col-lg-12">
                      <div className="pull-left">
                        Order ID: #{data?.details?._id}
                      </div>
                      <div className="pull-right"></div>
                      <div className="cart style2" id="mobiles">
                        <table className="table table-striped table-bordered">
                          <thead>
                            <tr>
                              <th>Contact Details</th>
                              <th>Order Details</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td data-th="Contact Details">
                                <p style={{ margin: 0 }}>
                                  <b>REMEDIATE GLOBAL PRIVATE LIMITED</b>
                                </p>
                                <p style={{ margin: 0 }}>Corporate Office:-</p>
                                <p style={{ margin: 0 }}>
                                  {contactusdata?.address}
                                </p>
                                <br />
                                <p style={{ margin: 0 }}>
                                  <b>Telephone:</b> {contactusdata?.phoneNumber}
                                  ,{contactusdata?.anotherPhoneNumber}{" "}
                                </p>
                                <p style={{ margin: 0 }}>
                                  <b>E-Mail:</b> {contactusdata?.emailAddress},
                                  {contactusdata?.anotherEmailAddress}
                                </p>
                                <p style={{ margin: 0 }}>
                                  {contactusdata?.gstNumber}
                                </p>
                                <p style={{ margin: 0 }}>
                                  <b>Web Site:</b> www.uniform.com
                                </p>
                              </td>
                              <td data-th="Order Details">
                                <p style={{ margin: 0 }}>
                                  <b>Date Added:</b>{" "}
                                  {moment(data?.details?.createdAt).format(
                                    "MMMM Do YYYY"
                                  )}
                                </p>
                                <p style={{ margin: 0 }}>
                                  <b>Order ID:</b> {data?.details?._id}
                                </p>

                                <p style={{ margin: 0 }}>
                                  <b>Payment Method:</b>{" "}
                                  {data?.details?.paymenttype}
                                </p>
                                <p style={{ margin: 0 }}>
                                  <b>Order Recieved: </b>
                                  <strong
                                    style={{
                                      color: data?.details?.received
                                        ? "green"
                                        : "red",
                                    }}
                                  >
                                    {data?.details?.received ? "Yes" : "No"}
                                  </strong>{" "}
                                </p>
                                <p style={{ margin: 0 }}>
                                  <b>Order Status: </b>
                                  <strong style={{ color: "green" }}>
                                    Confirmed
                                  </strong>{" "}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                          <thead>
                            <tr>
                              <th colspan="2">Delivery Address</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td data-th="Delivery Address" colspan="2">
                                <p style={{ margin: 0 }}>
                                  <b>{data?.details?.userId?.name} </b>
                                </p>
                                <p style={{ margin: 0 }}>
                                  {data?.details?.address?.city}
                                </p>
                                <p style={{ margin: 0 }}>
                                  {data?.details?.address?.state} -{" "}
                                  {data?.details?.address?.postal_code}
                                </p>
                                <p style={{ margin: 0 }}>
                                  {data?.details?.address?.country}
                                </p>
                                <p style={{ margin: 0 }}>
                                  <b>E-Mail:</b> {data?.details?.userId?.name}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="cart style2" id="mobiles">
                        <table className="table table-striped table-bordered">
                          <thead className="cart__row cart__header small--hide">
                            <tr>
                              <th colspan="2" className="text-start">
                                Product
                              </th>
                              <th className="text-center">MRP&nbsp;Price</th>
                              <th className="text-center">Quantity</th>
                              <th className="text-center">Price</th>
                              <th className="text-center">Delivered</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td data-th="Product">
                                <p>
                                <Link
                                    to={{pathname:`../product_details/${data?.details?.productId?._id}`}}
                                  >
                                    <img
                                      className="cart__image blur-up lazyload"
                                      src={`https://api.petzoy.in/assets/${data?.details?.productId?.images[0]}`}
                                      width="150"
                                    />
                                  </Link>
                                </p>
                              </td>
                              <td data-th="Product Details">
                                <div className="list-view-item__title">
                                  <Link
                                    to={{pathname:`../product_details/${data?.details?.productId?._id}`}}
                                  >
                                    {data?.details?.productId?.title}
                                  </Link>
                                </div>
                                <div className="cart__meta-text">
                                  <b>SKU : </b>
                                  {data?.details?.productId?._id} <br />
                                  <b>Size : </b>
                                  {data?.details?.size}{" "}
                                </div>
                              </td>
                              <td data-th="MRP Price">
                                <span className="money">
                                  <i
                                    className="fa fa-inr"
                                    style={{ color: "#000", width: "10px" }}
                                  ></i>
                                  {currency.format(
                                    discount(
                                      data?.details?.productId?.price,
                                      data?.details?.productId?.discount
                                    ),
                                    {
                                      code: "INR",
                                    }
                                  )}
                                </span>
                              </td>
                              <td data-th="Quantity">
                                {data?.details?.quantities}
                              </td>
                              <td data-th="Price">
                                <span className="money">
                                  <i
                                    className="fa fa-inr"
                                    style={{ color: "#000", width: "10px" }}
                                  ></i>
                                  {total}
                                </span>
                              </td>
                              <td data-th="Delivered">
                                {data?.details?.status ? "Yes" : "No"}
                              </td>
                            </tr>{" "}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 cart__footer">
                      <div className="cart-order_detail cart-col sticky">
                        <div className="cart-order_detail cart-col sticky">
                          <div className="row">
                            <span className="col-6 col-sm-6 cart__subtotal-title">
                              <strong>Subtotal</strong>
                            </span>
                            <span className="col-6 col-sm-6 cart__subtotal-title cart__subtotal text-end">
                              <span className="money">
                                <i
                                  className="fa fa-inr"
                                  style={{ color: "#000", width: "10px" }}
                                ></i>
                                {currency.format(
                                  data?.details?.productId?.price,

                                  {
                                    code: "INR",
                                  }
                                )}
                              </span>
                            </span>
                          </div>
                          <div className="row">
                            <span className="col-6 col-sm-6 cart__subtotal-title">
                              <strong>Discount </strong>
                            </span>
                            <span className="col-6 col-sm-6 cart__subtotal-title cart__subtotal text-end">
                              <span className="money">
                                <i
                                  className="fa fa-inr"
                                  style={{ color: "#000", width: "10px" }}
                                ></i>
                                {data?.details?.productId?.discount}{" "}
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <span className="col-7 col-sm-7 cart__subtotal-title">
                            <strong>
                              Shipping{" "}
                              <small
                                style={{
                                  textTransform: "capitalize",
                                  fontSize: "10px",
                                }}
                              >
                                (tentative)
                              </small>
                            </strong>
                          </span>
                          <span className="col-5 col-sm-5 cart__subtotal-title cart__subtotal text-end">
                            <span className="money">
                              <i
                                className="fa fa-inr"
                                style={{ color: "#000", width: "10px" }}
                              ></i>
                              {data?.details?.productId?.shipping || 0}
                            </span>
                          </span>
                        </div>
                        <div className="row">
                          <div
                            className="col-12 col-sm-12 cart__subtotal-title"
                            style={{ lineHeight: 1 }}
                          >
                            <strong>
                              <small
                                style={{
                                  textTransform: "capitalize",
                                  fontSize: "9px",
                                }}
                              >
                                Shipping charges will be finalized as per actual
                                & transport Builty will be given.
                              </small>
                            </strong>
                          </div>
                        </div>{" "}
                        <div className="row">
                          <span
                            className="col-6 col-sm-6 cart__subtotal-title"
                            style={{ fontSize: "16px", color: "#ff0000" }}
                          >
                            <strong>Grand Total</strong>
                          </span>
                          <span className="col-6 col-sm-6 cart__subtotal-title cart__subtotal text-end">
                            <span
                              className="money"
                              style={{ fontSize: "16px", color: "#ff0000" }}
                            >
                              <i
                                className="fa fa-inr"
                                style={{ color: "#ff0000", width: "10px" }}
                              ></i>
                              {currency.format(
                               data?.details?.productId?.price -
							   data?.details?.productId?.discount  
                                 ,
                                {
                                  code: "INR",
                                }
                              )}{" "}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserOrderDetails;
