import { useEffect, useState } from "react";
import Feed from "./Feed";
import Sidebar from "./Sidebar";
import Widgets from "./Widgets";
import http from "../../../services/httpService.js";
import { api } from "../../../config";
import { useSelector } from "react-redux";
import { useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetPetTypesQuery } from "../../../store/services/pettypeService.js";
import { useGetAllPostsQuery, useSearchPostsQuery } from "../../../store/services/discussionPosts.js";


export default function Petopedia() {
  const { state } = useLocation();
  const  tagdata  = state?.tagdata;
    const { userToken, user } = useSelector((state) => state.authReducer);
    const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
    const { palette } = useTheme();
    const navigate = useNavigate();
    const [loading, setloading] = useState(true);
    const [allPosts, setAllPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(4);
    const [tags, setTags] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedTag, setSelectedTag] = useState({
      _id: "1",
      name: "All Posts",
    });
    const { data: userdata, error: usererror } = useGetPetTypesQuery();
    const { data: fetchedAllPosts, error: posterror } = useGetAllPostsQuery(undefined, {
      skip: !!searchQuery, // Skip this query if searchQuery is not empty
    });
    const { data: searchedPosts, error: searchError } = useSearchPostsQuery(searchQuery, {
      skip: !searchQuery, // Skip this query if searchQuery is empty
    });
    const [selectedPetType, setSelectedPetType] = useState(null);
    const [selectedBreed, setSelectedBreed] = useState(null);

  useEffect(() => {
    const fetchPostsAndTags = async () => {
      try {
        // const { data: fetchedAllPosts } = await http.get(api.postsEndPoint);
        const { data: fetchedTags } = await http.get(api.tagsEndPoint);
        // setAllPosts([...fetchedAllPosts]);
        setTags([
          {
            _id: "1",
            name: "All Posts",
          },
          ...fetchedTags,
        ]);
        setloading(false);
      } catch (ex) {
        console.error(ex);
      }
    };

    fetchPostsAndTags();
  }, [fetchedAllPosts]);

  useEffect(() => {
    if (!searchQuery) {
      setAllPosts(fetchedAllPosts || []);
    } else {
      setAllPosts(searchedPosts || []);
    }
  }, [fetchedAllPosts, searchedPosts, searchQuery]);


  const handleTagSelect = (tag) => {
    setSelectedTag(JSON.parse(tag));
    setSelectedPetType(null); // Reset pet type and breed selections
    setSelectedBreed(null);
    setCurrentPage(1);
  };

  const handlePetTypeSelect = (petType) => {
    setSelectedPetType(petType);
    setSelectedTag({ _id: "1", name: "All Posts" }); // Reset tag selection
    setSelectedBreed(null);
    setCurrentPage(1);
  };

  const handleBreedSelect = (breed) => {
    setSelectedBreed(breed);
    setSelectedTag({ _id: "1", name: "All Posts" }); // Reset tag selection
    setCurrentPage(1);
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };


  const getFilteredPosts = () => {
    let filtered =
      selectedTag._id === "1"
        ? allPosts
        : allPosts.filter((post) =>
            post.tags.some((tag) => tag.name === selectedTag.name)
          );

    if (selectedPetType != null) {
      console.log(
        "filte running",
        selectedPetType,
        filtered.filter(
          (post) => post.pettype == selectedPetType.petType || post.pettag && post.pettag.type == selectedPetType.petType
        )
      );
      filtered = filtered.filter(
        (post) =>
          post.pettype === selectedPetType.petType ||
          (post.pettag && post.pettag.type === selectedPetType.petType)
      );
    }

    if (selectedBreed) {
      filtered = filtered.filter(
        (post) => post.petbreed == selectedBreed.breedName || post.pettag && post.pettag.breed == selectedBreed.breedName
      );
    }
    console.log(filtered);
    return filtered;
  };

  const filteredPosts = getFilteredPosts();

  return (
    <div className="flex flex-col min-h-screen w-full mx-auto bg-white">
      <main className="flex flex-col xl:flex-row max-w-7xl mx-auto w-full">
        {/* Sidebar */}
        <Sidebar
          maindata={userdata}
          handlePetTypeSelect={handlePetTypeSelect}
          handleTagSelect={handleTagSelect}
          className="xl:w-1/4"
        />
        {/* Feed */}
        <Feed
        tagdata={tagdata}
          maindata={filteredPosts}
          tags={tags}
          onpress={(e) => handleTagSelect(e)}
          onSearchInputChange={handleSearchInputChange}
          setSearchQuery={setSearchQuery}
          className="xl:w-1/2"
        />
        {/* Widgets */}
        <Widgets
          maindata={tags}
          onpress={(e) => handleTagSelect(e)}
          onSearchInputChange={handleSearchInputChange}
          className="xl:w-1/4"
        />
      </main>
    </div>
  );
}
