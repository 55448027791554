import { Box } from "@mui/material";

const UserImage = ({ image, size = "60px",onpress }) => {
  return (
    <Box width={size} height={size} >
      <img
      onClick={onpress}
        style={{ objectFit: "cover", borderRadius: "50%",width:size,height:size }}
       
        alt="user"
        src={`https://api.petzoy.in/assets/${image}`}
      />
    </Box>
  );
};

export default UserImage;
