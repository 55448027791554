import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const petService = createApi({
  reducerPath: 'pets',
  tagTypes: 'pets',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.petzoy.in/pets/',
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      headers.set('authorization', token ? `Bearer ${token}` : '');
      return headers;
    }
  }),
  endpoints: (builder) => ({
    createPetType: builder.mutation({
      query: (data) => ({
        url: '/pet_type/newtype',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['pets']
    }),
    updatePetType: builder.mutation({
      query: (data) => ({
        url: `/pet_type/${data.id}`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['pets']
    }),
    deletePetType: builder.mutation({
      query: (id) => ({
        url: `/pet_type/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['pets']
    }),
    updatePetTypeStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `/pet_type/${id}/status`,
        method: 'PUT',
        body: { status }
      }),
      invalidatesTags: ['pets']
    }),
    createBreed: builder.mutation({
      query: (data) => ({
        url: '/breed/new',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['pets']
    }),
    updateBreed: builder.mutation({
      query: (data) => ({
        url: `/breed/${data.id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['pets']
    }),
    deleteBreed: builder.mutation({
      query: (id) => ({
        url: `/breed/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['pets']
    }),
    updateBreedStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `/breed/${id}/status`,
        method: 'PUT',
        body: { status }
      }),
      invalidatesTags: ['pets']
    }),
    getPetTypes: builder.query({
      query: () => '/pet_type/all',
      providesTags: ['pets']
    }),
    getBreedById: builder.query({
      query: (id) => `/breed/${id}`,
      providesTags: ['pets']
    }),
    getAllPetBreeds: builder.query({
        query: () => '/petbreeds/all',
        providesTags: ['pets']
      }),
  
  }),
});

export const {
  useCreatePetTypeMutation,
  useUpdatePetTypeMutation,
  useDeletePetTypeMutation,
  useUpdatePetTypeStatusMutation,
  useCreateBreedMutation,
  useUpdateBreedMutation,
  useDeleteBreedMutation,
  useUpdateBreedStatusMutation,
  useGetPetTypesQuery,
  useGetBreedByIdQuery,
  useGetAllPetBreedsQuery,
} = petService;

export default petService;
