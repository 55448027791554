import React, { useState } from "react";
import Joi from "joi-browser";
import { ToastContainer } from "react-toastify";

import { Rating } from "react-simple-star-rating";
import { useSelector } from "react-redux";
import { useCreateUserReviewMutation } from "../store/services/userReviewService";

const UserPostReview = ({ id,userpet }) => {
  const [data, setData] = useState({ rating: 0, comment: "" });
  const [errors, setErrors] = useState({ comment: "" });
  const [createUserReviewMutation] = useCreateUserReviewMutation();
  const { _id } = useSelector((state) => state.authReducer.user);
  const schema = {
    rating: Joi.number().min(1).max(5).required().label("Rating"),
    comment: Joi.string().required().min(5).label("Comment"),
  };

  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const newErrors = {};
    for (let item of error.details) {
      newErrors[item.path[0]] = item.message;
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    try {
        console.log({ ...data, user: id, createdBy: _id })
        await createUserReviewMutation({ ...data, user: id,userpet:userpet, createdBy: _id });
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 500);
    } catch (ex) {
      console.error("Error creating user review:", ex);
    }
  };

  const handleRatingChange = (rating) => {
    setData({ ...data, rating });
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="comment-respond">
        <h4 className="comment-reply-title">Post a Review</h4>
        <Rating SVGstyle={{"display":"inline"}} onClick={handleRatingChange} ratingValue={data.rating} />
        <form onSubmit={handleSubmit} className="comment-form mt-10">
          <div className="form-grp">
            <textarea
              value={data.comment}
              onChange={handleChange}
              name="comment"
              type="comment"
              id="comment"
              placeholder="Comment"
            ></textarea>
            {errors.description && <div className="alert-info">{errors.description}</div>}
          </div>
          <button type="submit" disabled={validate()} className="btn">
            Post <img src="assets/img/icon/right_arrow.svg" alt="" className="injectable" />
          </button>
        </form>
      </div>
    </React.Fragment>
  );
};

export default UserPostReview;
