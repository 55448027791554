import React, { useState } from "react";
import Joi from "joi-browser";
import { ToastContainer, toast } from "react-toastify";
import { createreply } from "../services/replyCreateService";
import { useSelector } from "react-redux";

const PostReply = ({ id }) => {
  const [data, setData] = useState({ comment: "" });
  const [errors, setErrors] = useState({});
  const token = useSelector((state) => state.authReducer.token);
  

  const schema = {
    comment: Joi.string().required().min(5).label("Comment"),
  };

  const validate = () => {
    const result = Joi.validate(data, schema, { abortEarly: false });
    if (!result.error) return null;

    const errors = {};
    for (let item of result.error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    try {
      const { data: reply } = await createreply(data, id, token);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (ex) {
      toast.error("An error occurred while submitting the comment.");
    }
  };

  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="bg-white rounded-lg shadow-md p-6 mt-8">
        <h3 className="text-2xl font-semibold mb-4 text-gray-800">
          Post a comment
        </h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="relative">
            <textarea
              value={data.comment}
              onChange={handleChange}
              name="comment"
              id="comment"
              placeholder="Share your thoughts..."
              className="w-full px-4 py-2 text-gray-700 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none h-32"
            ></textarea>
            {errors.comment && (
              <p className="text-red-500 text-sm mt-1">{errors.comment}</p>
            )}
          </div>
          <button
            type="submit"
            disabled={validate()}
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out flex items-center justify-center space-x-2 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <span>Post Comment</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </form>
      </div>
    </React.Fragment>
  );
};

export default PostReply;
