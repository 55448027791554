import { PersonAddOutlined, PersonRemoveOutlined } from "@mui/icons-material";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import FlexBetween from "./FlexBetween";
import UserImage from "./UserImage";
import { setFriends } from "../store/reducers/authReducer";
import { useZIM } from "../hooks/zim";

const Friend = ({ friendId, name, subtitle, userPicturePath,tag ,brand}) => {
  const dispatch = useDispatch();
  const [{ avatarMap, chatMap }, zimAction] = useZIM();
  const navigate = useNavigate();
  // const { _id } = useSelector((state) => state.authReducer.user);
  // const token = useSelector((state) => state.authReducer.token);
  // const friends = useSelector((state) => state.authReducer.user.friends);

  const { palette } = useTheme();
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;
  const main = palette.neutral.main;
  const medium = palette.neutral.medium;

  // const isFriend = friends.length > 0 ? friends.find((friend) => friend._id === friendId) : false;

  const patchFriend = async () => {
    zimAction.sendChatMessage(0, 'hey', friendId, false).then(() => {
      console.log("ok")
      // onClose()
        // navigation.push(routes.DiscussionScreen, {"id": friendId, "name": 'test', "type": 0})
      
  });
    // const response = await fetch(
    //   `https://api.petzoy.in/users/${_id}/${friendId}`,
    //   {
    //     method: "PATCH",
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       "Content-Type": "application/json",
    //     },
    //   }
    // );
    // const data = await response.json();
    // dispatch(setFriends({ friends: data }));




  };

  return (
    <FlexBetween>
      <FlexBetween gap="1rem">
        {tag ?
         <Box position="relative" width={'50px'} height={'50px'}>
         <Box position="absolute" top="0" left="0">
           <UserImage image={userPicturePath} size={'30px'} />
         </Box>
         <Box position="absolute" bottom="0" right="0">
           <UserImage image={tag.picturePath} size={'30px'} />
         </Box>
       </Box>:<UserImage image={userPicturePath} size="55px" /> 
        }
        {/* */}
        <Box
          onClick={() => {
            brand ? 

            navigate("/commericaldetails",{ state: {maindata:brand}} ):
            navigate(`/userconnect/${friendId}`,{ state: {userId:friendId}} )
            
            navigate(0);
          }}
        >
          <Typography
  color={main}
  variant="h5"
  fontWeight="500"
  sx={{
    "&:hover": {
      color: palette.primary.light,
      cursor: "pointer",
    },
  }}
>
  <strong>{name}</strong> {tag && 'with'} {tag && <strong> {tag.name}</strong>}
</Typography>
          <Typography color={medium} fontSize="0.75rem">
            {subtitle}
          </Typography>
        </Box>
      </FlexBetween>
     {/* {friendId != _id && <IconButton
        onClick={() => patchFriend()}
        sx={{ backgroundColor: primaryLight, p: "0.6rem" }}
      >
        {isFriend ? (
          <PersonRemoveOutlined sx={{ color: primaryDark }} />
        ) : (
          <PersonAddOutlined sx={{ color: primaryDark }} />
        )}
      </IconButton>} */}
    </FlexBetween>
  );
};

export default Friend;
