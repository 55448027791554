import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import { ToastContainer, toast } from "react-toastify";
import Input from "./common/input";
import http from "../services/httpService";
import { api } from "../config.js";

import Header from "./Header/index.js";
import { useLocation } from "react-router-dom";
import UserImage from "./UserImage.jsx";
import { useCreatePostMutation } from "../store/services/discussionPosts.js";
const NewPost = () => {
  const { state } = useLocation();
  const [createpost] = useCreatePostMutation();
  const { tagdata } = state;
  const [data, setData] = useState({ title: "", description: "", tags: [] });
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    tags: [],
  });
  const [tags, setTags] = useState([]);

  const schema = {
    title: Joi.string().required().min(10).label("Title"),
    description: Joi.string().required().min(5).label("Description"),
    tags: Joi.array(),
  };

  const handleTagChange = (tagID) => {
    let newData = { ...data };
    const newTags = [...newData.tags];
    const index = newTags.indexOf(tagID);
    if (index === -1) newTags.push(tagID);
    else newTags.splice(index, 1);
    newData.tags = newTags;
    setData(newData);
  };

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const { data: fetchedTags } = await http.get(api.tagsEndPoint);
        setTags(fetchedTags);
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          toast.error("Post Validation Failed!");
        }
      }
    };

    fetchTags();
  }, []);

  const validate = () => {
    const { error } = Joi.validate(data, schema, { abortEarly: false });
    if (!error) return null;

    const newErrors = {};
    for (let item of error.details) {
      newErrors[item.path[0]] = item.message;
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!data.title || !data.description) {
      toast.error("Title and Description is Required ");
      return;
    }

    if (data.title.length < 10) {
      toast.error("Title Must be atleast 10 Character Long");
      return;
    }

    // const validationErrors = validate();
    // setErrors(validationErrors || {});

    // if (validationErrors) return;

    try {
      const postData = tagdata
        ? { ...data, pettag: tagdata._id }
        : { ...data, pettag: null };
      const { response } = await createpost(postData);
      console.log(response);
      window.location = "/discussiondashboard";
    } catch (ex) {
      console.error(ex);
    }
  };

  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  return (
    <React.Fragment>
      <div>
        <ToastContainer />
        {/* <Header/> */}
        <main class="fix">
          <section class="registration__area-two">
            <div class="container">
              <div class="registration__inner-wrap-two">
                <div class="row">
                  <div class="col-lg-8">
                    <div class="registration__form-wrap">
                      <form onSubmit={handleSubmit} class="registration__form">
                        <h3 class="title">Ask a Question</h3>
                        <span>
                          Your email address will not be published. Required
                          fields are marked *
                        </span>
                        <div class="row gutter-20">
                          <div class="col-md-12">
                            <div class="form-grp">
                              <input
                                value={data.title}
                                onChange={handleChange}
                                placeholder="Title"
                                name="title"
                                type="text"
                                error={errors.title}
                              />
                            </div>
                          </div>

                          <div class="col-md-12">
                            <div class="form-grp">
                              <textarea
                                value={data.description}
                                onChange={handleChange}
                                name="description"
                                type="description"
                                id="description"
                                placeholder="Special Note . . ."
                              ></textarea>
                            </div>
                          </div>
                          {errors.description && (
                            <div className="alert-info">
                              {errors.description}
                            </div>
                          )}
                          {tagdata && (
                            <div className="d-flex">
                              <label htmlFor="tags">
                                Discussion About{" "}
                                <strong>{tagdata?.name} </strong>
                              </label>
                              <UserImage
                                size="20px"
                                image={tagdata?.picturePath}
                              />
                            </div>
                          )}
                          <div className="form-group mb-20">
                            <label htmlFor="tags">Related Topics</label>
                            <br />
                            {tags.map((tag) => (
                              <div className="ml-10" key={tag._id}>
                                <label className="ml-10">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={() => handleTagChange(tag._id)}
                                  />
                                  {tag.name}
                                </label>
                              </div>
                            ))}
                            {errors.tags && (
                              <div className="alert-info">{errors.tags}</div>
                            )}
                          </div>
                        </div>
                        <button onClick={handleSubmit} className="btn">
                          Submit{" "}
                          <img
                            src="assets/img/icon/right_arrow.svg"
                            alt=""
                            className="injectable"
                          />
                        </button>
                      </form>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="registration__img">
                      <img
                        src="assets/img/images/registration_img.png"
                        alt=""
                        data-aos="fade-right"
                        data-aos-delay="400"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </React.Fragment>
  );
};

export default NewPost;
