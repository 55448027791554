import { Virtual } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { useGetAllServicesQuery } from "../../store/services/serviceService";
import { useMediaQuery } from "@mui/material";

// Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper/modules';

const Services = ({ setselectedservice, setselectedname, setselectedservicetype }) => {
  const { data, isFetching } = useGetAllServicesQuery();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  let i = 1;
  const navigate = useNavigate();
  return (
    data?.length > 0 && (
      <Swiper
        navigation={true}
        modules={[Navigation]}
        spaceBetween={10}
        slidesPerView={6} 
        Navigation 
        breakpoints={{
          0: {
            slidesPerView: 2.5,
          },
          640: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 4,
          },
          1080: {
            slidesPerView: 5,
          },
          1280: {
            slidesPerView: 6,
          },
        }}
      >
        {data.map((category, index) => {
          if (i >= 5) {
            i = 1;
          } else {
            i++;
          }
          return (
            <SwiperSlide
              className="w-full overflow-hidden  relative soccer"
              key={index}
              virtualIndex={index}
            >
              <button
              onClick={() =>{
                navigate(`/allserviceuser/${category._id}`, {
                    state: { categoryy:category},
                  })
            }}
                // onClick={() => { setselectedservice(category._id); setselectedname(category.name); setselectedservicetype(category) }}
                className="text-black text-base font-medium capitalize w-full rounded-full"
              >
                <div className="service-card rounded-full">
                  <img
                    src={`https://api.petzoy.in/assets/${category.image}`}
                    className="w-full h-full object-cover"
                    alt=""
                  />
                  <div className="hover-text" style={{ fontSize: isNonMobileScreens ? '14px' : '8px' }}>
                    {category.description}
                  </div>
                </div>

                <div className="inset-0 bg-black/20   items-center justify-center rounded-full" style={{ color: "white", fontSize: isNonMobileScreens ? '14px' : '9px', fontWeight: "bold", background: "#894B8D" }}>
                  {category.name}
                </div>
              </button>
            </SwiperSlide>
          );
        })}
      </Swiper>
    )
  )
};

export default Services;
