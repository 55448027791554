import React from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { useGetAllBlogsQuery } from "../../store/services/blogService";

const OurBlogs = () => {
  const { data: allBlogs, isLoading: isblogsloading } = useGetAllBlogsQuery();

  let i = 1;
  return (
    allBlogs?.length > 0 && (
      <Swiper
        navigation={true}
        modules={[Navigation]}
        spaceBetween={10}
        slidesPerView={10}
        Navigation
        className="w-full"
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
          },
          1080: {
            slidesPerView: 3,
          },
          1280: {
            slidesPerView: 3,
          },
        }}
      >
        {allBlogs.map((category, index) => {
          if (i >= 5) {
            i = 1;
          } else {
            i++;
          }
          return (
            <SwiperSlide
              className="w-full overflow-hidden  relative"
              key={index}
              virtualIndex={index}
            >
              <Link
                to={{
                  pathname: `/blog-details/${category._id}`,
                }}
                state={{
                  maindata: category,
                }}
                className="w-full"
              >
                {/* <!-- Single item --> */}
                <div class="blog__post-item-four shine-animate-item">
                  <div class="blog__post-thumb-four shine-animate">
                    <a>
                      <Link to={`/blog-details/${category._id}`}>
                        <img
                          src={`https://api.petzoy.in/assets/${category?.picturePath}`}
                          alt="img"
                        />
                      </Link>
                    </a>
                  </div>
                  <div class="blog__post-content-four">
                    <div class="blog__post-meta">
                      <ul class="list-wrap">
                        <li>
                          <i class="flaticon-calendar"></i>
                          {category?.createdAt?.split("T")[0]}
                        </li>
                        <li>
                          <i class="flaticon-user"></i>by {category?.author}
                        </li>
                      </ul>
                    </div>
                    <h2 class="title">
                      <Link to={`/blog-details/${category._id}`}>
                        {category?.title}
                      </Link>
                    </h2>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    )
  );
};

export default OurBlogs;
