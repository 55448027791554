import { Box, Button, Typography, useTheme } from "@mui/material";
import FlexBetween from "../../components/FlexBetween";
import Friend from "../../components/Friend";
import Pet from "../../components/Pet";
import WidgetWrapper from "../../components/WidgetWrapper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CreatePetModal from "./CreatePetModal";
import { setPets } from "../../store/reducers/authReducer";
import { Dock } from "react-osx-dock";

const MyPetListWidget = ({ userId,isProfile = false,onPress }) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const [modal_open, setmodal_open] = useState(false)
  const token = useSelector((state) => state.authReducer.token);
  const [pets, setpets] = useState([])

  const getFriends = async () => {
    try {
      const response = await fetch(
        `https://api.petzoy.in/pets/${userId}/pets`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      if (!response.ok) {
        // Handle non-2xx status codes
        throw new Error(`API request failed with status ${response.status}`);
      }
  
      const data = await response.json();
  
      if (data) {
        setpets(data);
      } else {
        // Handle empty response
        setpets([]); // Set pets state to an empty array
      }
    } catch (error) {
      console.error('Error fetching friends:', error);
      // Handle errors appropriately, e.g., display an error message to the user
    }
  };

  useEffect(() => {
    getFriends();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    // <WidgetWrapper>
    //      <CreatePetModal open={modal_open} setOpen={setmodal_open}/>
    //     <FlexBetween
    //     gap="0.5rem"
    //     pb="1.1rem">
    //   <Typography
    //     color={palette.neutral.dark}
    //     variant="h5"
    //     fontWeight="500"
    //     // sx={{ mb: "1.5rem" }}
    //   >
    //    {!isProfile ? "My Pets":"Owned Pets"}
    //   </Typography>
    //   {!isProfile &&<Button
    //   size="small"
    //   onClick={() => setmodal_open(true)}
    //       sx={{
    //         color: palette.background.alt,
    //         backgroundColor: palette.primary.main,
    //         borderRadius: "3rem",
    //       }}
    //     >
    //       Add
    //     </Button>}
    //   </FlexBetween>
    //   <Box display="flex" flexDirection="row" gap="1.5rem">
    //      {pets?.length > 0 && pets?.map((friend) => (
    //       <Pet
    //         key={friend._id}
    //         friendId={friend._id}
    //         name={`${friend.name}`}
    //         subtitle={friend.breed}
    //         userPicturePath={friend.picturePath}
    //         isProfile={isProfile}
    //       />
    //     ))}
    //   </Box>
    // </WidgetWrapper>


                   
<div className="dock-container z-50">
      <div className="dock">
        <ul>
      {/* <Dock itemWidth={50} magnification={2} magnifyDirection="up"> */}
         {pets?.length > 0 && pets?.map((friend) => (
          <Pet
          onpress={onPress}
          alldata={friend}
            key={friend._id}
            friendId={friend._id}
            name={`${friend.name}`}
            subtitle={friend.breed}
            userPicturePath={friend.picturePath}
            isProfile={isProfile}
          />
        ))}
        {/* </Dock> */}
      </ul>
      </div></div>
  );
};

export default MyPetListWidget;
