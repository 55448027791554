import { Link, useLocation, useParams } from "react-router-dom";
import { useGetBrandQuery } from "../../store/services/brandService";
import Friend from "../../components/Friend";
import { useGetBlogDetailsQuery } from "../../store/services/blogService";



function BlogDetails() {

    const {id } = useParams();
    const { data, isFetching } = useGetBlogDetailsQuery(id);
  return (
    
    
<body>
  
  
<main class="fix">


    
    <section class="team__details-area">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-5 col-md-8">
                    <div class="team__details-img">
                        <img src={`https://api.petzoy.in/assets/${data?.picturePath}`} alt="img"/>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="team__details-content">
                        <h2 class="name">{data?.title}</h2>
                        <span>By {data?.author}</span>
                        <p>{data?.content}</p>
                       
                    </div>
                </div>
            </div>
        </div>
    </section>
    

</main>

</body>
  );
}

export default BlogDetails;
