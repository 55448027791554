import { useEffect, useState } from "react";
import Header from "../../components/Header";
import WhiteHeader from "../../components/WhiteHeader";
import { useZIM } from "../../hooks/zim";
import { useUserLoginMutation } from "../../store/services/authService";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { setLogin, setUserToken } from "../../store/reducers/authReducer";
import OTPInput from "react-otp-input";

function Login() {

    const [{callID}, zimAction] = useZIM();
  // ============= Initial State Start here =============
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // ============= Initial State End here ===============
  // ============= Error Msg Start here =================
  const [errEmail, setErrEmail] = useState("");
  const [errPassword, setErrPassword] = useState("");

  // ============= Error Msg End here ===================
  const [successMsg, setSuccessMsg] = useState("");
  // ============= Event Handler Start here =============
  const handleEmail = (e) => {
    if(showotpinput){
      setshowotpinput(false)
      
    }
    setEmail(e.target.value);
    setErrEmail("");
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
    setErrPassword("");
  };

  const [errors, setErrors] = useState([]);
  const [loginUser, response] = useUserLoginMutation()
  
  const [identifier, setIdentifier] = useState(''); 

  useEffect(() => {
    const configuration = {
      widgetId: "34677a75674a313031303933",
      tokenAuth: "427089TkjGodLuUC66a40b39P1",
      identifier: "", // Set initially to empty
      exposeMethods: true,
      // captchaRenderId: captchaRenderId.current?.id || '',
      success: (data) => {
        console.log('success response', data);
      },
      failure: (error) => {
        console.log('failure reason', error);
      },
    };

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://control.msg91.com/app/assets/otp-provider/otp-provider.js';
    script.onload = () => {
      if (window.initSendOTP) {
        window.initSendOTP(configuration);
      } else {
        console.error('initSendOTP function is not available.');
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const [otp, setotp] = useState(0)
  // ============= Event Handler End here ===============
  const handleSignUp = (e) => {
    e.preventDefault();
    if (email.length < 9 ) {
      setErrEmail("Enter valid mobile number");
    }
    if (!email) {
      setErrEmail("Enter your mobile number");
    }

    // ============== Getting the value ==============
    if (email ) {

      if (window.verifyOtp) {
        console.log(JSON.stringify(window.verifyOtp))
        window.verifyOtp(
          otp,
          (data) => {console.log('OTP verified: ', data);
            loginUser({
              phone: email,
              password: password
          });
            setEmail("");
            setPassword("");
          },
          (error) =>{ console.log(error);toast.error("OTP Mismatch")}
        );
      } else {
        console.error('verifyOtp function is not available.');
        toast.error("OTP Error")
      }

    
    }
  };

  useEffect(() => {
    if(response.isError) {
      
       toast.error(response?.error?.data?.errors[0].msg);
    }
   }, [response?.error?.data])
   const dispatch = useDispatch();
   const navigate = useNavigate();
   useEffect(() => {
    if(response.isSuccess) {
      console.log(response)
      zimAction
      .login({userID: response.data.user._id, userName: response.data.user.name})
      .then(() => {
        zimAction.initEvent();
        zimAction.updateUserInfo(response.data.user.name, `https://api.petzoy.in/assets/${response.data.user?.picturePath}`);
        // alert("user login done")
      });
      dispatch(
        setLogin({
          user: response.data.user,
          token: response.data.token,
        })
      );
        console.log("dispatch hit with data",response.data.user)
      localStorage.setItem('userToken', response?.data?.token);
     
      dispatch(setUserToken(response?.data?.token))
      
      toast.success('Welcome User !');
      navigate('/');
    }
  }, [response.isSuccess])




  const [showotpinput, setshowotpinput] = useState(false)
  
  const [randomotp, setrandomotp] = useState(0)

  const [timer, setTimer] = useState(30); // Timer for resend button
  const [resendDisabled, setResendDisabled] = useState(false); // Disable resend button

  useEffect(() => {
    let interval;
    if(timer == 1){
      setResendDisabled(false)
    }
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);



    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!email) return;
      if (email.length < 9 ) {
        setErrEmail("Enter valid mobile number");
        return
      }
      setResendDisabled(true); // Disable resend button
      setTimer(30);
  
      if (window.sendOtp) {
        
        window.sendOtp(
          `91${email}`,
          (data) => console.log('OTP sent successfully.', data),
          (error) => console.log('Error occurred', error)
        );
      } else {
        console.error('sendOtp function is not available.');
      }
  
      // Make a request to your backend to send OTP
      // const response = await fetch('https://api.petzoy.in/send-otp', {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify({ phone: email })
      // });
      // const data = await response.json();
  
      // Handle the response, e.g., show OTP input field
      // console.log(data);
      // setrandomotp(data.otp);
      setshowotpinput(true);
  };





    
    return(
<div>
<Toaster position="top-right" />
<main class="fix">


    
    <section class="registration__area-two">
        <div class="container">
            <div class="registration__inner-wrap-two">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="registration__form-wrap">
                            <form onSubmit={(e) => {showotpinput ? handleSignUp(e):handleSubmit(e)}} class="registration__form">
                                <h3 class="title">Login Now</h3>
                                <span>Your email address will not be published. Required fields are marked *</span>
                                <div class="row gutter-20">
                                   
                                    <div class="col-md-8">
                                        <div class="form-grp">
                                            <input  onChange={handleEmail}
                    value={email} type="number" placeholder="Phone Number"/>
                                            {errEmail && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errEmail}
                    </p>
                  )}
                                        </div>
                                    </div>
                                    {showotpinput && 
                         
                         <>
                         
                         <OTPInput
      value={otp}
      onChange={setotp}
      numInputs={4}
      // renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props}  style={{
        width: '50px', // Adjust the width as needed
        height: '50px', // Adjust the height as needed
        fontSize: '20px', // Adjust the font size as needed
        textAlign: 'center', // Center the text horizontally
        marginLeft:'10px',marginRight:'10px'
      }} />}
      />
       {timer > 0 && (
            <p>Resend OTP in {timer} seconds</p>
          )}
          {timer === 0 && (
            <button className="btn" onClick={() => handleSubmit()} disabled={resendDisabled}>
              Resend OTP
            </button>
          )}
      </>
      }
                                   
                                   
                                </div>
                                <button type="submit" class="btn">Login <img src="assets/img/icon/right_arrow.svg" alt=""  class="injectable"/></button>
                                <p className="text-sm mt-10 font-titleFont font-medium">
                  Don't have an Account?{" "}
                  <Link to="/register">
                    <span className="hover:text-blue-600 duration-300">
                      Sign up
                    </span>
                  </Link>
                </p>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="registration__img">
                            <img src="assets/img/images/registration_img.png" alt="" data-aos="fade-right" data-aos-delay="400"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    

</main>

</div>

  );
}

export default Login;
