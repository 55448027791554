import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useGetPethoroscopeaccoUserQuery } from "../../../store/services/allpetService";
import { setPets } from "../../../store/reducers/authReducer";
import { Modal, Box, Typography, IconButton, useTheme, useMediaQuery } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const ThoughtBubble = ({ width, height, children }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 200" width={width} height={height}>
    <defs>
      <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
        <feGaussianBlur in="SourceAlpha" stdDeviation="3" result="blur" />
        <feOffset in="blur" dx="2" dy="2" result="offsetBlur" />
        <feMerge>
          <feMergeNode in="offsetBlur" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <radialGradient id="bubbleGradient" cx="50%" cy="50%" r="50%" fx="25%" fy="25%">
        <stop offset="0%" style={{ stopColor: "#ffffff", stopOpacity: 1 }}>
          <animate attributeName="stopColor" values="#ffffff;#f0f0f0;#ffffff" dur="5s" repeatCount="indefinite" />
        </stop>
        <stop offset="70%" style={{ stopColor: "#f0f0f0", stopOpacity: 1 }}>
          <animate attributeName="stopColor" values="#f0f0f0;#e0e0e0;#f0f0f0" dur="5s" repeatCount="indefinite" />
        </stop>
        <stop offset="100%" style={{ stopColor: "#e0e0e0", stopOpacity: 1 }}>
          <animate attributeName="stopColor" values="#e0e0e0;#d0d0d0;#e0e0e0" dur="5s" repeatCount="indefinite" />
        </stop>
      </radialGradient>
    </defs>

    <g filter="url(#shadow)">
      <path d="M150,5 Q210,5 240,40 Q270,75 270,110 Q270,145 240,180 Q210,215 150,215 Q90,215 60,180 Q30,145 30,110 Q30,75 60,40 Q90,5 150,5 Z"
        fill="url(#bubbleGradient)" stroke="#d0d0d0" strokeWidth="2">
        <animateTransform attributeName="transform"
          attributeType="XML"
          type="scale"
          values="1;1.02;1"
          dur="6s"
          repeatCount="indefinite" />
        <animate attributeName="d"
          values="M150,5 Q210,5 240,40 Q270,75 270,110 Q270,145 240,180 Q210,215 150,215 Q90,215 60,180 Q30,145 30,110 Q30,75 60,40 Q90,5 150,5 Z;
                 M150,10 Q205,10 235,45 Q265,80 265,110 Q265,140 235,175 Q205,210 150,210 Q95,210 65,175 Q35,140 35,110 Q35,80 65,45 Q95,10 150,10 Z;
                 M150,5 Q210,5 240,40 Q270,75 270,110 Q270,145 240,180 Q210,215 150,215 Q90,215 60,180 Q30,145 30,110 Q30,75 60,40 Q90,5 150,5 Z"
          dur="10s"
          repeatCount="indefinite" />
      </path>

      <circle cx="25" cy="130" r="12" fill="url(#bubbleGradient)" stroke="#d0d0d0" strokeWidth="1.5">
        <animateTransform attributeName="transform"
          attributeType="XML"
          type="translate"
          values="0,0; 3,-3; 0,0"
          dur="4s"
          repeatCount="indefinite" />
        <animate attributeName="r" values="12;13;12" dur="4s" repeatCount="indefinite" />
        <animate attributeName="opacity" values="0.7;1;0.7" dur="4s" repeatCount="indefinite" />
      </circle>
      <circle cx="15" cy="155" r="8" fill="url(#bubbleGradient)" stroke="#d0d0d0" strokeWidth="1.5">
        <animateTransform attributeName="transform"
          attributeType="XML"
          type="translate"
          values="0,0; -2,-2; 0,0"
          dur="5s"
          repeatCount="indefinite" />
        <animate attributeName="r" values="8;9;8" dur="5s" repeatCount="indefinite" />
        <animate attributeName="opacity" values="0.8;1;0.8" dur="5s" repeatCount="indefinite" />
      </circle>
      <circle cx="10" cy="175" r="5" fill="url(#bubbleGradient)" stroke="#d0d0d0" strokeWidth="1.5">
        <animateTransform attributeName="transform"
          attributeType="XML"
          type="translate"
          values="0,0; 1,-1; 0,0"
          dur="6s"
          repeatCount="indefinite" />
        <animate attributeName="r" values="5;6;5" dur="6s" repeatCount="indefinite" />
        <animate attributeName="opacity" values="0.9;1;0.9" dur="6s" repeatCount="indefinite" />
      </circle>
    </g>

    <foreignObject x="40" y="20" width="220" height="180">
      <div xmlns="http://www.w3.org/1999/xhtml" style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Arial, sans-serif',
        fontSize: '14px',
        color: '#894B8D',
        textAlign: 'center',
        padding: '20px',
        boxSizing: 'border-box',
        overflow: 'hidden',
        textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
        animation: 'fadeInOut 5s infinite'
      }}>
        <style>
          {`
            @keyframes fadeInOut {
              0%, 100% { opacity: 0.8; }
              50% { opacity: 1; }
            }
          `}
        </style>
        {children}
      </div>
    </foreignObject>
  </svg>
);

const HoroscopeModel = ({ open, setOpen }) => {
  const navigate = useNavigate();

  const picturePath = useSelector((state) => state.authReducer.user?.picturePath);
  
  const _id = useSelector((state) => state.authReducer.user?._id);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const dispatch = useDispatch();
  const [hoveredPet, setHoveredPet] = useState(null);

  const {
    data: petsData = [],
    error,
    isLoading,
  } = useGetPethoroscopeaccoUserQuery(_id, {
    skip: !_id,
  });

  useEffect(() => {
    if (petsData) {
      setHoveredPet(petsData[0])
      dispatch(setPets({ pets: petsData }));
    }
  }, [dispatch, petsData]);

  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: { xs: "95%", sm: "80%" },
          height: { xs: "95%", sm: "80%" },
          maxWidth: "1200px",
          maxHeight: "800px",
          backgroundImage: `url("https://images.unsplash.com/photo-1534796636912-3b95b3ab5986?q=80&w=1471&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflow: "auto",
          borderRadius: "15px",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: '#ffffff',
            fontSize: '2rem',
          }}
        >
          <CloseIcon fontSize="large" />
        </IconButton>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            padding: { xs: "15px", sm: "30px" },
            marginTop: { xs: "150px", sm: "80px" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "50%" },
              height: { xs: "200px", sm: "250px" },
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: { xs: "20px", sm: 0 },
            }}
          >
            {hoveredPet ? (
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.5 }}
                transition={{ duration: 0.3 }}
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    width: { xs: '200px', sm: '300px' },
                    height: { xs: '200px', sm: '300px' },
                    borderRadius: '50%',
                    overflow: 'hidden',
                    position: 'relative',
                    boxShadow: '0 0 20px rgba(255, 255, 255, 0.5)',
                  }}
                >
                  <img
                    src={`https://api.petzoy.in/assets/${hoveredPet?.picturePath}`}
                    alt={hoveredPet.name}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  style={{
                    position: 'absolute',
                    top: '-120px',
                    left: '40%',
                    transform: 'translateX(-50%)',
                    width: '250px',
                    height: '200px',
                  }}
                >
                  <ThoughtBubble width="100%" height="100%">
                    <motion.div
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ delay: 0.2, duration: 0.5 }}
                      style={{
                        maxHeight: '150px',
                        overflow: 'hidden',
                        padding: '10px',
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: '14px',
                          lineHeight: 1.4,
                          color: '#894B8D',
                          fontWeight: 500,
                          textShadow: '0px 1px 2px rgba(0,0,0,0.3)',
                          letterSpacing: '0.5px'
                        }}
                      >
                        {hoveredPet.horoscope}
                      </Typography>
                    </motion.div>
                  </ThoughtBubble>
                </motion.div>
              </motion.div>
            ) : (
              <Box
                sx={{
                  width: { xs: '200px', sm: '300px' },
                  height: { xs: '200px', sm: '300px' },
                  borderRadius: '50%',
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '20px',
                  textAlign: 'center',
                  boxShadow: '0 0 20px rgba(255, 255, 255, 0.3)',
                }}
              >
                <Typography variant="h6" sx={{ color: '#ffffff', fontSize: { xs: '0.8rem', sm: '1rem' } }}>
                  {window.innerWidth <= 600 ? 'Click on a pet to see their horoscope' : 'Hover over a pet to see their horoscope'}
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              width: { xs: "100%", sm: "45%" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Box
                sx={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  marginBottom: '10px',
                  cursor: 'pointer',
                  boxShadow: '0 0 10px rgba(255, 255, 255, 0.5)',
                }}
                // onClick={() => navigate('/editprofile')}
              >
                <img
                  style={{ objectFit: "cover", borderRadius: "50%", width: '100%', height: '100%' }}
                  alt="user"
                  src={`https://api.petzoy.in/assets/${picturePath}`}
                />
              </Box>
            </Box>
            <Typography
              variant="body2"
              sx={{
                textAlign: 'center',
                color: '#ffffff',
                fontStyle: 'italic',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                padding: '10px',
                borderRadius: '5px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                maxWidth: '80%',
                fontSize: { xs: '0.7rem', sm: '0.8rem' },
              }}
            >
              This is just for fun and not a real horoscope. Enjoy the playful predictions for your pets!
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "flex-end",
            padding: { xs: "10px", sm: "15px" },
            marginTop: "auto", 
            marginBottom: { xs: "10px", sm: "15px" }, 
          }}
        >
          {petsData.map((pet, index) => (
            <motion.div
              key={index}
              style={{
                width: '60px',
                height: '60px',
                margin: '8px',
                cursor: 'pointer',
              }}
              whileHover={{ scale: 1.1 }}
              onClick={() => setHoveredPet(pet)}
              // onMouseLeave={() => setHoveredPet(null)}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  boxShadow: '0 0 10px rgba(255, 255, 255, 0.5)',
                }}
              >
                <img
                  src={`https://api.petzoy.in/assets/${pet?.picturePath}`}
                  alt={pet.name}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </Box>
              <Typography
                variant="caption"
                sx={{
                  textAlign: 'center',
                  width: '100%',
                  display: 'block',
                  marginTop: '3px',
                  color: '#ffffff',
                  textShadow: '0px 1px 2px rgba(0,0,0,0.3)',
                  fontSize: '0.7rem',
                }}
              >
                {pet.name}
              </Typography>
            </motion.div>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default HoroscopeModel;