import { Virtual, Navigation } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import UserImage from "../UserImage";
import Moment from "react-moment";
// import './nearby.css'

const NearbyPets = ({ data }) => {
  const navigate = useNavigate();
  const latlong = useSelector((state) => state.authReducer.latlong);
  const [distanceaway, setdistanceaway] = useState(0);

  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const earthRadiusKm = 6371; // Radius of the earth in kilometers
    const deg2rad = (deg) => deg * (Math.PI / 180); // Function to convert degrees to radians

    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadiusKm * c; // Distance in km

    return distance;
  }

  const setlocation = () => {
    console.log("location nearby pet", latlong, data);
    if (latlong) {
      const petlat = data?.owner?.latitude || 0.0;
      const petlong = data?.owner?.longitude || 0.0;

      const distance = getDistanceFromLatLonInKm(
        latlong.latitude,
        latlong.longitude,
        petlat,
        petlong
      );
      setdistanceaway(distance);
    }
  };

  useEffect(() => {
    setlocation();
  }, [latlong]);

  let i = 1;
  return (
    data?.length > 0 && (
      <Swiper
        navigation={true}
        modules={[Navigation]}
        spaceBetween={15}
        slidesPerView={10}
        Navigation
        className="w-full"
        breakpoints={{
          0: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2,
          },
          1080: {
            slidesPerView: 5,
          },
          1280: {
            slidesPerView: 5,
          },
        }}
      >
        {data.map((category, index) => {
          if (i >= 5) {
            i = 1;
          } else {
            i++;
          }
          return (
            <SwiperSlide
              className="w-full h-full rounded-lg overflow-hidden overflow-hidden relative"
              key={index}
              virtualIndex={index}
            >
              <Link
                to={{
                  pathname: `/contactprofile/${category._id}`,
                }}
                state={{
                  userId: category._id,
                }}
                // to={`/shop/${category.name}`}
                className="w-full h-full"
              >
                {/* <div className="col-xl-12" key={category._id}>
                  <div className="animal__item animal__item-two shine-animate-item">
                    <div className="animal__thumb animal__thumb-two shine-animate">
                      <a href="animal-details.html"><img src={`https://api.petzoy.in/assets/${category?.picturePath}`} alt="img" /></a>
                    </div>
                    <div className="animal__content animal__content-two">
                      <h4 className="title"><a href="animal-details.html">{category?.name} </a></h4>
                      <li> {distanceaway.toFixed(2)} Km away</li>
                      <div className="pet-info">
                        <ul className="list-wrap">
                          <li>Gender: <span>Male</span></li>
                          <li>Breed: <span>{category?.breed}</span></li>
                        </ul>
                      </div>

                    </div>
                  </div>
                </div> */}

                {/* <div class="card">
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt quibusdam omnis repellendus, atque similique magnam alias.</p>
                  <div class="image">
                    <img src="https://assets.codepen.io/4787486/puppies-2.jpg" alt="" />
                  </div>
                  <div class="button">Puppies</div>
                </div> */}

                {/* <div class="wrapper" id="petcard">
                  <div class="card">
                    <div class="image">
                      <img
                        src={`https://api.petzoy.in/assets/${category?.picturePath}`}
                        alt=""
                      />
                    </div>
                    <div class="button">{category?.name}</div>
                    <div>
                      <p className="p-1">
                        <i
                          class={
                            category?.gender == "male"
                              ? "fa-solid fa-mars "
                              : "fa-solid fa-venus"
                          }
                        ></i>{" "}
                        {category?.age} Yrs.{" "}
                        {category?.type == "Dog" ? (
                          <i class="fa-solid fa-shield-dog" />
                        ) : (
                          <i class="fa-solid fa-cat" />
                        )}{" "}
                        {category?.breed}
                      </p>
                    </div>
                  </div>
                </div> */}
                <div class="petcard">
                <div className="petImageContainer">
                  <img
                    class="card-image"
                    src={`https://api.petzoy.in/assets/${category?.picturePath}`}
                    alt="Dog"
                  />
                </div>
                  <div class="card-content">
                    <div className="userMainHeading">
                      <h3 class="button">{category?.name}</h3>
                      <img
                        class="user-Image"
                        src={`https://api.petzoy.in/assets/${category?.owner?.picturePath}`}
                        alt="Dog"
                      />
                    </div>
                    <div class="card-info">
                    {!category?.private ? <span class="card-icon">
                        {category?.private &&  <i class="fa-solid fa-lock"/>} {"  "}
                        {category?.type == "Dog" ? (
                          <i class="fa-solid fa-shield-dog" />
                        ) : (
                          <img
                          
                          src={`https://api.petzoy.in/assets/${category?.image}`}
                          style={{width:"20px",height:"20px"}}
                        />
                        )}{" "}
                        {category?.breed}
                      </span>:
 <i class="fa-solid fa-lock"/>
        }
                      {!category?.private &&   <span class="card-date">
                        <i
                          class={
                            category?.gender == "male"
                              ? "fa-solid fa-mars "
                              : "fa-solid fa-venus"
                          }
                        ></i>{" "}
                                      <Moment
        fromNow
        style={{
          color: "#505050",
          fontSize: "10px",
        }}
      >
        {category?.age}
      </Moment>{" "}
                      </span>}
                    </div>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          );
        })}
         <SwiperSlide className="w-full h-full rounded-lg overflow-hidden relative">
        <Link to="/allnearbypets" className="w-full h-full flex items-center justify-center bg-gray-200">
          <h3 className="text-xl font-bold text-gray-800">View All</h3>
        </Link>
      </SwiperSlide>
      </Swiper>
    )
  );
};

export default NearbyPets;
