import { Box, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";

import UserWidget from "../widgets/UserWidget";
import MyPostWidget from "../widgets/MyPostWidget";
import PostsWidget from "../widgets/PostsWidget";
import AdvertWidget from "../widgets/AdvertWidget";
import FriendListWidget from "../widgets/FriendListWidget";
import { useEffect, useState } from "react";
import CreatePetModal from "../widgets/CreatePetModal";
import MyPetListWidget from "../widgets/MyPetListWidget";
import ServiceListWidget from "../widgets/ServiceListWidget";
import Header from "../../components/Header";
import WhiteHeader from "../../components/WhiteHeader";
import FlexBetween from "../../components/FlexBetween";
import Loader from "../../components/Loader";
import MyCommercialListWidget from "../widgets/CommercialWidgets/MyCommercialWidget";
import { useNavigate } from "react-router-dom";
import { useGetBrandsByUserQuery } from "../../store/services/brandService";
import CommentPostModal from "../widgets/CommentPostModal";
import { useGetNearbyAdvertisementsQuery } from "../../store/services/advertiseService";

const HomePage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { _id, picturePath, firstName } = useSelector(
    (state) => state.authReducer.user
  );
  const latlong = useSelector((state) => state.authReducer.latlong);
  const { data:ad_data } = useGetNearbyAdvertisementsQuery({ latitude:latlong?.latitude, longitude:latlong?.longitude });

  const [mediatype, setmediatype] = useState("");
  const [location, setLocation] = useState();
  const navigate = useNavigate();
  const [pet_data, setpet_data] = useState(null);
  const [showmainModal, setshowmainModal] = useState(false);
  const [selected_business, setselected_business] = useState(null);
  const [userdataloading, setuserdataloading] = useState(true);

  console.log(_id, "mainid");

  const { data } = useGetBrandsByUserQuery(_id);

  const socials = {
    linkedin: {
      link: "https://www.linkedin.com/in/christianbarlow",
      label: "LinkedIn",
    },
    twitter: {
      link: "https://twitter.com/TheRealBarlow_",
      label: "Twitter",
    },
    github: {
      link: "https://github.com/Barlow1",
      label: "Github",
    },
    email: {
      link: "/contact",
      label: "Email me!",
    },
  };

  useEffect(() => {
    function addPrevClass(e) {
      var target = e.target;
      if (target.tagName === "svg") {
        // check if it is an icon
        var li = target.parentNode.parentNode;
        var prevLi = li.previousElementSibling;
        if (prevLi) {
          prevLi.className = "prev";
        }

        target.addEventListener(
          "mouseout",
          function () {
            if (prevLi) {
              prevLi.removeAttribute("class");
            }
          },
          false
        );
      }
    }
    if (window && document) {
      document
        .getElementsByClassName("dock")[0]
        ?.addEventListener("mouseover", addPrevClass, false);
    }

    return () => {
      // DESTROY
      document
        .getElementsByClassName("dock")[0]
        ?.removeEventListener("mouseover", addPrevClass, false);
    };
  }, []);

  return (
    <>
      {/* {userdataloading && <Loader/>} */}

      <CreatePetModal open={showmainModal} setOpen={setshowmainModal} />

      <Box>
        {/* <Navbar /> */}
        <Box
          width="100%"
          padding={isNonMobileScreens ? "2rem 6%" : "0.5rem 2%"}
          display={isNonMobileScreens ? "flex" : "block"}
          gap="0.5rem"
          justifyContent="space-between"
        >
          {isNonMobileScreens && (
            <Box flexBasis={isNonMobileScreens ? "25%" : undefined}>
              {/* <Box display="flex" flexDirection="row" gap="1.5rem">

          <UserWidget setloading={setuserdataloading} userId={_id} picturePath={picturePath} onpress={() => {setpet_data(null);
 navigate("/userconnect",{ state: {userId:_id}})

           }}/>
          <MyPetListWidget userId={_id} onPress={(data) => setpet_data(data)} />
          </Box> */}

              <div class="sideBarContainer">
                <div class="sidebar left">
                  <div class="container borderNone">
                    <div
                      onClick={() => {
                        if (mediatype) {
                          setmediatype("");
                        } else {
                          setpet_data(null);
                          navigate(`/userconnect/${_id}`, {
                            state: { userId: _id },
                          });
                        }
                      }}
                      class="globalProfile"
                    >
                      <div
                        class="globalRoundProfile"
                        style={{
                          backgroundImage: `url(https://api.petzoy.in/assets/${picturePath})`,
                        }}
                      ></div>
                      <div class="name">{firstName}</div>
                    </div>
                    <div
                      class={`globalProfile ${
                        mediatype == "video" && "globalProfileSelected"
                      }`}
                      onClick={() => setmediatype("video")}
                    >
                      <div
                        class="globalRoundProfile"
                        style={{
                          backgroundImage:
                            "url(https://png.pngtree.com/png-vector/20190623/ourlarge/pngtree-monitorcomputervideoplay-abstract-flat-color-icon-template-png-image_1490983.jpg)",
                        }}
                      ></div>
                      <div class="name">Videos</div>
                    </div>
                    <div
                      class={`globalProfile ${
                        mediatype == "image" && "globalProfileSelected"
                      }`}
                      onClick={() => setmediatype("image")}
                    >
                      <div
                        class="globalRoundProfile"
                        style={{
                          backgroundImage:
                            "url(https://cdn-icons-png.flaticon.com/512/1375/1375106.png",
                        }}
                      ></div>
                      <div class="name">Photos</div>
                    </div>
                    {/* <div class="globalProfile">
            <div class="globalRoundProfile" style={{backgroundImage: 'url(https://cdn-icons-png.freepik.com/512/7579/7579697.png)'}}></div>
            <div class="name">Followers</div>
        </div> */}
                    <div
                      class="globalProfile"
                      onClick={() =>
                        navigate("/discussiondashboard", { state: { tagdata: pet_data } })
                      }
                    >
                      <div
                        class="globalRoundProfile"
                        style={{
                          backgroundImage:
                            "url(https://cdn-icons-png.flaticon.com/512/5962/5962463.png)",
                        }}
                      ></div>
                      <div class="name">Ask Query</div>
                    </div>
                    {/* <div class="globalProfile">
            <div class="globalRoundProfile circle">
                <i class="fas fa-angle-down"></i>
            </div>
            <div class="name">See More</div>
        </div> */}
                  </div>

                  <div class="container">
                    <div class="mainTitle">
                      <h3 class="padding">Your Business</h3>
                      {/* <a href="#">Edit</a> */}
                    </div>
                    {data?.length > 0 &&
                      data?.map((friend) => (
                        <div
                          class="globalProfile"
                          onClick={() => {
                            setselected_business(friend);
                            setmediatype(false);
                            setpet_data(null);
                          }}
                        >
                          <div
                            class="globalRoundProfile r-10"
                            style={{
                              backgroundImage: `url(https://api.petzoy.in/assets/${friend.picturePath})`,
                            }}
                          ></div>
                          <div class="name">{friend.name}</div>
                        </div>
                      ))}
                    {/* <div class="globalProfile">
            <div class="globalRoundProfile circle">
                <i class="fas fa-angle-down"></i>
            </div>
            <div class="name">See More</div>
        </div> */}
                  </div>
                </div>
              </div>
            </Box>
          )}
          <Box
            flexBasis={isNonMobileScreens ? "42%" : undefined}
            mt={isNonMobileScreens ? undefined : "2rem"}
          >
            <MyPostWidget
              picturePath={
                selected_business
                  ? selected_business?.picturePath
                  : pet_data?.picturePath || picturePath
              }
              taggedpet={pet_data}
              onimagepress={() => setmediatype("image")}
              onvideopress={() => {
                setmediatype("video");
              }}
              mediaType={mediatype}
              setpet_data={setpet_data}
              pet_data={pet_data}
              selected_business={selected_business}
            />

            <div>
              <div class="rooms">
                <div class="title">
                  <div class="left">
                    <img
                      src="assets/img/icon/pet_icon02.svg"
                      alt=""
                      className="injectable"
                    />
                    {/* <h3>Pets</h3> */}
                    {/* <p>Your Owned Pets</p> */}
                    {/* <i class="fas fa-exclamation-circle"></i> */}
                  </div>
                  <div class="right">
                    <button onClick={() => setshowmainModal(true)}>
                      Add Pet
                    </button>
                  </div>
                </div>
                {/* <div class="profiles">
                <div class="globalRoundProfile" style={{backgroundImage: 'url(https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg)'}}>
                    <div class="svg">
                        <img src="./svg/plusWhite.svg" alt=""/>
                    </div>
                    <span></span>
                    <div class="darkSpan"></div>
                </div>

                <div class="globalRoundProfile" style={{backgroundImage: 'url(https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg)'}}>
                    <span></span>
                    <div class="active"></div>
                </div>
                <MyPetListWidget userId={_id} onPress={(data) => setpet_data(data)} />
               
            </div> */}
                <MyPetListWidget
                  userId={_id}
                  onPress={(data) => {
                    setmediatype(false);
                    setselected_business(null);
                    setpet_data(data);
                  }}
                />
              </div>
            </div>

            {/* <MyCommercialListWidget onPress={(data) => console.log(data)} /> */}
            <PostsWidget
              mediaType={mediatype}
              mainid={_id}
              userId={pet_data?._id ? pet_data._id : null}
              isProfile={pet_data != null}
              userdata={pet_data}
              selected_business={selected_business}
            />
          </Box>
          {isNonMobileScreens && (
            <Box flexBasis="26%">
              {/* <ServiceListWidget type={'owner'} /> */}
              <Box m="2rem 0" />
              {ad_data?.map((item, index) => {

                return (
                  <AdvertWidget maindata={item}/>
                )
})}

              <Box m="2rem 0" />
              <FriendListWidget userId={_id} />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default HomePage;
