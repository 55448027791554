import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const homeProducts = createApi({
  reducerPath: "homeProducts",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.petzoy.in/api/",
  }),
  endpoints: (builder) => {
    return {
      usersbyService: builder.query({
        query: (params) => {
          return {
            url: `/users/byservice/${params.service}`,
            method: "POST",
            params: {
              limit: params.limit, // Include the page parameter here
            },
            body: params,
          };
        },
      }),
      
      catProducts: builder.query({
        query: (params) => {
          return {
            url: `cat-products/${params.name}/${params.page}`,
            method: "GET",
          };
        },
      }),
      brandProducts: builder.query({
        query: (params) => {
          return {
            url: `brand-products/${params.name}/${params.page}`,
            method: "GET",
          };
        },
      }),
      searchProducts: builder.query({
        query: (params) => {
          return {
            url: `search-products/${params.keyword}/${params.page}`,
            method: "GET",
          };
        },
      }),
      getSubcategoryProducts: builder.query({
        query: ({ subcategoryId, page }) => {
          if (!subcategoryId) {
            return { data: null }; // Return null data to skip the API call
          }
          return {
            url: `productbysubcategory/${subcategoryId}`,
            method: "POST",
          };
        },
        providesTags: ["products"],
      }),
        // New query for distance calculation
        getDistanceToUser: builder.mutation({
          query: ({ userId, latitude, longitude }) => ({
            url: `/distance/${userId}`,
            method: "POST",
            body: { latitude, longitude },
          }),
        }),
    };
  },
});
export const { useUsersbyServiceQuery, useCatProductsQuery,useBrandProductsQuery, useSearchProductsQuery,useGetSubcategoryProductsQuery,useGetDistanceToUserMutation } = homeProducts;
export default homeProducts;
