import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import "moment-timezone";
import { PersonCircle, HandThumbsUpFill, Check2Circle } from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import UserImage from "../../components/UserImage.jsx";
import PostReply from "../../components/createReply.jsx";
import {
  ChatIcon,
  DotsHorizontalIcon,
  EyeIcon,
  ShareIcon,
} from "@heroicons/react/outline";

import Sidebar from "../../components/Dashboard/Components/Sidebar";
import Widgets from "../../components/Dashboard/Components/Widgets";
import { useGetPetTypesQuery } from "../../store/services/pettypeService.js";
import {
  useCloseDiscussionMutation,
  useGetAllPostsQuery,
  useSearchPostsQuery,
} from "../../store/services/discussionPosts.js";
import http from "../../services/httpService.js";
import { api } from "../../config";
import Loader from "../../components/Loader/index.js";
import { AiOutlineCloseCircle } from "react-icons/ai";

const PostComment = ({}) => {
  const [link_copied, setlink_copied] = useState(false)
  const { data: userdata, error: usererror } = useGetPetTypesQuery();
  const [selectedPetType, setSelectedPetType] = useState(null);
  const [selectedBreed, setSelectedBreed] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { state } = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const [tags, setTags] = useState([]);
  const [loading, setloading] = useState(true);
  const [allPosts, setAllPosts] = useState([]);
  // const postId = useParams();
  const {postId:id } = useParams();
  // const id = postId;
  const navigate = useNavigate();
  const [replies, setReplies] = useState([]);
  const user = useSelector((state) => state.authReducer.user);
  const  _id  = useSelector((state) => state.authReducer.user?._id);
  const [selectedTag, setSelectedTag] = useState({
    _id: "1",
    name: "All Posts",
  });

  const { data: fetchedAllPosts, error: posterror } = useGetAllPostsQuery(
    undefined,
    {
      skip: !!searchQuery, // Skip this query if searchQuery is not empty
    }
  );
  const { data: searchedPosts, error: searchError } = useSearchPostsQuery(
    searchQuery,
    {
      skip: !searchQuery, // Skip this query if searchQuery is empty
    }
  );

  useEffect(() => {
    const fetchPostsAndTags = async () => {
      try {
        // const { data: fetchedAllPosts } = await http.get(api.postsEndPoint);
        const { data: fetchedTags } = await http.get(api.tagsEndPoint);
        // setAllPosts([...fetchedAllPosts]);
        setTags([
          {
            _id: "1",
            name: "All Posts",
          },
          ...fetchedTags,
        ]);
        setloading(false);
      } catch (ex) {
        console.error(ex);
      }
    };

    fetchPostsAndTags();
  }, [fetchedAllPosts]);

  useEffect(() => {
    if (!searchQuery) {
      setAllPosts(fetchedAllPosts || []);
    } else {
      setAllPosts(searchedPosts || []);
    }
  }, [fetchedAllPosts, searchedPosts, searchQuery]);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePetTypeSelect = (petType) => {
    setSelectedPetType(petType);
    setSelectedTag({ _id: "1", name: "All Posts" }); // Reset tag selection
    setSelectedBreed(null);
    setCurrentPage(1);
  };

  const handleTagSelect = (tag) => {
    setSelectedTag(JSON.parse(tag));
    setSelectedPetType(null); // Reset pet type and breed selections
    setSelectedBreed(null);
    setCurrentPage(1);
  };

  const getFilteredPosts = () => {
    let filtered =
      selectedTag._id === "1"
        ? allPosts
        : allPosts.filter((post) =>
            post.tags.some((tag) => tag.name === selectedTag.name)
          );

    if (selectedPetType != null) {
      console.log(
        "filte running",
        selectedPetType,
        filtered.filter(
          (post) => post.pettag && post.pettag.type == selectedPetType.petType
        )
      );
      filtered = filtered.filter(
        (post) => post.pettag && post.pettag.type == selectedPetType.petType
      );
    }

    if (selectedBreed) {
      filtered = filtered.filter(
        (post) => post.pettag && post.pettag.breed == selectedBreed.breedName
      );
    }
    console.log(filtered);
    return filtered;
  };

  const filteredPosts = getFilteredPosts();

  const [post, setPost] = useState({
    description: "",
    title: "",
    tags: [],
    author: [],
    upvotes: [],
    views: 0,
    closed:false
  });


  const fetchData = async () => {
    try {
      const { data: post } = await http.get(api.postsEndPoint + id);
      const { data: replies } = await http.get(api.repliesEndPoint + id);
      setPost(post);
      setReplies(replies);
      setloading(false);
    } catch (ex) {
      setloading(false);
      // Handle error
    }
  };

  useEffect(() => {
    
    fetchData();
  }, [id]);


  const [closeDiscussion, { isLoading, isError, isSuccess }] = useCloseDiscussionMutation();

  const handleCloseDiscussion = async () => {
    const isConfirmed = window.confirm("Are you sure you want to close this discussion?");
    if (isConfirmed) {
      try {
        await closeDiscussion(id).unwrap();
        fetchData()
        toast.success("Query Closed!");
      } catch (error) {
        fetchData()
        toast.error("Failed to close the discussion.");
      }
    }
  };



  const checkReplyLike = (id) => {
    if (user) {
      for (let i in replies) {
        if (replies[i]._id === id) {
          if (replies[i].upvotes.includes(user._id)) return true;
        }
      }
    }
    return false;
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleReplyUpvote = async (id) => {
    try {
      const reply_updated = await http.put(
        api.repliesEndPoint + "like/" + id,
        {}
      );
      const { data: updatedReplies } = await http.get(
        api.repliesEndPoint + "/" + id
      );
      setReplies(updatedReplies);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error("You can't upvote your own reply!");
      }
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="flex flex-col min-h-screen w-full mx-auto bg-white">
        <main className="flex flex-col xl:flex-row max-w-7xl mx-auto w-full">
          {/* User Profile and Pet Profile */}
          <div className="xl:w-1/4 pr-5">
            <div
              className="blog-avatar-wrap"
              onClick={() =>
                navigate(`/userconnect/${post.author._id}`)
              }
            >
              <div className="blog-avatar-img">
                <UserImage image={post?.author?.picturePath} size="100px" />
              </div>
              <div className="blog-avatar-info">
                <span className="designation">Author</span>
                <h4 className="name">
                  <a href="#">
                    {(post.author && post.author.firstName) || "Admin"}
                  </a>
                </h4>
                <p>Check Profile</p>
              </div>
            </div>

            {post?.pettag && (
              <div
                className="blog-avatar-wrap"
                onClick={() =>
                  navigate(`/contactprofile/${post.pettag._id}`)
                }
              >
                <div className="blog-avatar-img">
                  <UserImage image={post?.pettag?.picturePath} size="100px" />
                </div>
                <div className="blog-avatar-info">
                  <span className="designation">Pet</span>
                  <h4 className="name">
                    <a href="#">
                      {(post?.pettag && post?.pettag.name) || "Pet"}{" "}
                    </a>
                  </h4>
                  <p>Check Profile</p>
                </div>
              </div>
            )}
          </div>

          {/* Post */}
          <div className="xl:w-1/2">
            <div className=" border-l border-r border-gray-200 xl:min-w-[576px]  flex-grow max-w-xl">
              <div className="flex flex-col bg-white rounded-lg shadow-md overflow-hidden">
                {/* Post Header */}
                <div className="p-4 border-b border-gray-200">
                  <div className="flex items-center space-x-3">
                    <img
                      onClick={() =>
                        navigate(`/userconnect/${post.author._id}`, {
                          state: { userId: post.author._id },
                        })
                      }
                      className="h-14 w-14 rounded-full cursor-pointer hover:opacity-80 transition transform hover:scale-105 ring-2 ring-blue-500"
                      src={`https://api.petzoy.in/assets/${post.author.picturePath}`}
                      alt="Profile picture"
                    />
                    <div className="flex-1">
                      <div className="flex items-center justify-between">
                        <div
                          onClick={() =>
                            navigate(`/userconnect/${post.author._id}`, {
                              state: { userId: post.author._id },
                            })
                          }
                          className="group cursor-pointer"
                        >
                          <h4 className="font-bold text-xl group-hover:underline text-gray-900">
                            {post.author?.firstName}
                          </h4>
                          <span className="text-gray-500 text-sm">
                            {formatDate(post.time)}
                          </span>
                        </div>
                        {/* <button className="text-gray-500 hover:bg-gray-100 rounded-full p-2 transition duration-200 ease-in-out">
                          <DotsHorizontalIcon className="h-6 w-6" />
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Post Content */}
                <div className="p-4">
                  <h2 className="text-2xl font-semibold mb-3 text-gray-800">
                    {post.title}
                  </h2>
                  <p className="text-gray-700 text-base mb-4 leading-relaxed">
                    {post.description}
                  </p>

                  {post.pettag && (
                    <div className="mb-4 bg-blue-50 p-3 rounded-lg">
                      <span className="text-gray-600">For: </span>
                      <strong
                        onClick={() =>
                          navigate(`/contactprofile/${post.pettag._id}`, {
                            state: { userId: post.pettag._id },
                          })
                        }
                        className="text-blue-600 hover:underline cursor-pointer"
                      >
                        {post.pettag?.name}
                      </strong>
                    </div>
                  )}

                  {post.pettag && (
                    <img
                      className="rounded-lg mb-4 max-h-96 w-full object-cover shadow-md hover:shadow-lg transition duration-300"
                      src={`https://api.petzoy.in/assets/${post.pettag?.picturePath}`}
                      alt="post image"
                    />
                  )}

                  <div className="flex flex-wrap mb-4">
                    {post.tags.map((tag) => (
                      <span
                        key={tag._id}
                        className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 mb-2 px-3 py-1 rounded-full hover:bg-blue-200 transition duration-200"
                      >
                        #{tag.name}
                      </span>
                    ))}
                  </div>

                  {/* Post Actions */}
                  <div className="flex justify-between text-gray-500 border-t pt-4">
                    <button className="flex items-center space-x-2 group hover:bg-blue-50 px-3 py-2 rounded-full transition duration-200">
                      <ChatIcon className="h-6 w-6 group-hover:text-blue-500" />
                      <span className="group-hover:text-blue-500">
                        {post?.replyCount} Comments
                      </span>
                    </button>
                    <button onClick={() => {
                    setlink_copied(true)
                    navigator.clipboard.writeText(
                      `https://www.petzoy.in/post/${post._id}`
                    );
                    toast.success('Link Copied !');
                  }} className="flex items-center space-x-2 group hover:bg-green-50 px-3 py-2 rounded-full transition duration-200">
                       {link_copied ? <Check2Circle className="h-6 w-6 group-hover:text-green-500"/>:
            <ShareIcon className="h-6 w-6 group-hover:text-green-500" />}
                      
                      <span className="group-hover:text-green-500">Share</span>
                    </button>
                    {post.author._id === _id && !post.closed && ( <button onClick={() => handleCloseDiscussion()} className="flex items-center space-x-2 group bg-red-50 px-3 py-2 rounded-full transition duration-200">
                      <AiOutlineCloseCircle className="h-6 w-6 text-red-500" />
                      <span className="text-red-500">
                        Close Query
                      </span>
                    </button>)}
                  </div>
                </div>
              </div>

              {user && (post.closed ==  true ? 
               <div className="bg-white rounded-lg shadow-md p-6 mt-8">
               <h3 className="text-2xl font-semibold mb-4 text-gray-800">
                 Discussion Closed
               </h3>
               </div>
              :  <PostReply id={id} />)}

              {/* Comments Section */}
              <div className="mt-8 bg-white rounded-lg shadow-md p-6">
                <h3 className="text-xl font-semibold mb-4 text-gray-800">
                  {replies.length} Comments
                </h3>
                <div className="space-y-6">
                  {replies &&
                    replies.map((reply) => (
                      <div
                        key={reply._id}
                        className="flex space-x-4 border-b border-gray-200 pb-4"
                      >
                        <img
                          className="h-10 w-10 rounded-full"
                          src={`https://api.petzoy.in/assets/${reply.author.picturePath}`}
                          alt={reply.author.firstName}
                        />
                        <div className="flex-1">
                          <div className="flex items-center justify-between mb-1">
                            <h6 className="font-medium text-gray-900">
                              {reply.author.firstName}
                            </h6>
                            <span className="text-sm text-gray-500">
                              <Moment fromNow>{reply.time}</Moment>
                            </span>
                          </div>
                          <p className="text-gray-700 mb-2">{reply.comment}</p>
                          <button
                            className={`flex items-center space-x-2 text-sm ${
                              checkReplyLike(reply._id)
                                ? "text-blue-600"
                                : "text-gray-500 hover:text-blue-600"
                            } transition duration-200`}
                            disabled={!user}
                            onClick={() => handleReplyUpvote(reply._id)}
                          >
                            <HandThumbsUpFill className="h-4 w-4" />
                            <span>{reply.upvotes.length} Likes</span>
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          {/* Recent Posts */}

          <div className="xl:w-1/4">
            <div
              className="col-xl-4 col-lg-4"
              style={{
                backgroundColor: "transparent",
                width: "100%",
                borderLeft: "1px solid #E4E4E4",
                paddingLeft: "30px",
              }}
            >
              <aside className="blog-sidebar">
                <div className="blog-widget">
                  <h4 className="widget-title">Search</h4>
                  <div className="sidebar-search-form">
                    <form action="#">
                      <input type="text" placeholder="Type Keywords. . ." />
                      <button type="submit">
                        <i className="flaticon-loupe"></i>
                      </button>
                    </form>
                  </div>
                </div>

                <div className="blog-widget">
                  <h4 className="widget-title">Recent Post</h4>
                  <div className="rc-post-wrap">
                    {allPosts.slice(0, 5).map((item) => (
                      <div className="rc-post-item" key={item._id}>
                        {/* <div className="thumb">
                          <a
                            onClick={() =>
                              navigate(`/post/${item._id}`, {
                                state: { postId: item._id },
                              })
                            }
                          >
                            <img
                              src="assets/img/blog/rc_post01.jpg"
                              alt="img"
                            />
                          </a>
                        </div> */}
                        <div className="content">
                          <h4 className="title">
                            <a
                              onClick={() =>
                                navigate(`/post/${item._id}`, {
                                  state: { postId: item._id },
                                })
                              }
                            >
                              {item.title}
                            </a>
                          </h4>
                          <span className="date">
                            <i className="flaticon-calendar"></i>
                            <Moment fromNow style={{ color: "#505050" }}>
                              {item.time}
                            </Moment>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="blog-widget">
                  <h4 class="widget-title">Tags</h4>
                  <div class="sidebar-tag-list">
                    <ul class="list-wrap">
                      {tags.map((item) => (
                        <li>
                          <a
                            href="#"
                            onClick={() => navigate("/discussiondashboard")}
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default PostComment;

// User Profile

// Pet Profile

// Aside
