import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const friendRequestService = createApi({
  reducerPath: 'friendRequests',
  tagTypes: 'friendRequests',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.petzoy.in/api/', // Replace with your API base URL
    prepareHeaders: (headers, { getState }) => {
      const { authReducer } = getState();
      const token = authReducer.userToken;
      headers.set('Authorization', token ? `Bearer ${token}` : '');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    sendFriendRequest: builder.mutation({
      query: (request) => ({
        url: '/friend-request/send',
        method: 'POST',
        body: request,
      }),
    }),
    acceptFriendRequest: builder.mutation({
      query: (requestId) => ({
        url: `/accept-friend-request/accept/`+requestId,
        method: 'PUT',
      }),
    }),
    declineFriendRequest: builder.mutation({
      query: (requestId) => ({
        url: `/friend-request/decline/${requestId}`,
        method: 'PUT',
      }),
    }),
    getPendingFriendRequests: builder.query({
      query: (userId) => ({
        url: `/friend-request/pending/${userId}`,
        method: 'GET',
      }),
      providesTags: ['friendRequests'],
    }),
    checkFriendRequests: builder.query({
        query: ({ userId, otherUserId }) => ({
            url: `/friendrequests/${userId}/${otherUserId}`,
            method: 'GET'
        }),
        providesTags: ['friendRequests']
    }),
    deleteFriendRequest: builder.mutation({
        query: ({ userId, receiverId }) => ({
          url: `/friend-request/delete/${userId}/${receiverId}`,
          method: 'DELETE',
        }),
      }),


      // New endpoints
    acceptFollow: builder.mutation({
      query: ({ senderId, receiverId }) => ({
        url: `/accept-follow/${senderId}/${receiverId}`,
        method: 'POST',
      }),
    }),
    checkFollowing: builder.query({
      query: ({ userId, followeeId }) => ({
        url: `/check-following/${userId}/${followeeId}`,
        method: 'GET',
      }),
      providesTags: ['friendRequests'],
    }),
    removeFollower: builder.mutation({
      query: ({ userId, followerId }) => ({
        url: `/remove-follower/${userId}/${followerId}`,
        method: 'DELETE',
      }),
    }),
    getFollowingUsers: builder.query({
      query: (userId) => ({
        url: `/get-following/${userId}`,
        method: 'GET',
      }),
      providesTags: ['friendRequests'],
    }),
    getFollowerUsers: builder.query({
      query: (userId) => ({
        url: `/get-follower/${userId}`,
        method: 'GET',
      }),
      providesTags: ['friendRequests'],
    }),
  }),
});

export const {
  useSendFriendRequestMutation,
  useAcceptFriendRequestMutation,
  useDeclineFriendRequestMutation,
  useGetPendingFriendRequestsQuery,
  useCheckFriendRequestsQuery,
  useDeleteFriendRequestMutation,

  useAcceptFollowMutation,      // New hook for accept follow
  useCheckFollowingQuery,       // New hook for check following
  useRemoveFollowerMutation, 
  useGetFollowingUsersQuery,  
  useGetFollowerUsersQuery,  
} = friendRequestService;

export default friendRequestService;
