import { Box, Typography, useTheme } from "@mui/material";
import Friend from "../../components/Friend";
import WidgetWrapper from "../../components/WidgetWrapper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFriends } from "../../store/reducers/authReducer";
import Service from "../../components/Service";


const ServiceListWidget = ({ type }) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const token = useSelector((state) => state.authReducer.token);
  const [all_services, setall_services] = useState([])

  const getFriends = async () => {
    const response = await fetch(
      `https://api.petzoy.in/api/services/services`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();
    const petServices = data.filter(service => service.type == type);
    setall_services(petServices);
  };


  useEffect(() => {
    getFriends()
  }, [])
  

 
  return (
    <WidgetWrapper>
      <Typography
        color={palette.neutral.dark}
        variant="h5"
        fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        My Services
      </Typography>
      <Box display="flex" flexDirection="column" gap="1.5rem">
        {all_services?.length > 0 && all_services?.map((friend) => (
          <Service
            key={friend._id}
            mainId={friend._id}
            name={`${friend.name}`}
            subtitle={friend.description}
            userPicturePath={friend.image}
          />
        ))}
      </Box>
    </WidgetWrapper>
  );
};

export default ServiceListWidget;
