import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const brandService = createApi({
    reducerPath: 'brands',
    tagTypes: 'brands',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.petzoy.in/api/',
        // http://13.201.117.185:5500/api/
        prepareHeaders: (headers, { getState }) => {
            const reducers = getState();
            const token = reducers?.authReducer?.userToken;
            headers.set('authorization', token ? `Bearer ${token}` : '');
            return headers;
        }
    }),
    endpoints: (builder) => {
        return {
            createBrand: builder.mutation({
                query: (data) => ({
                    url: '/brands/create', // Match the Express route path
                    method: 'POST',
                    body: data
                }),
                invalidatesTags: ['brands']
            }),
            updateBrand: builder.mutation({
                query: (data) => ({
                    url: '/udpatebrand',
                    method: 'PUT',
                    body: data
                }),
                invalidatesTags: ['brands']
            }),
            deleteBrand: builder.mutation({
                query: (id) => ({
                    url: `/delete/${id}`,
                    method: 'DELETE'
                }),
                invalidatesTags: ['brands']
            }),
            getBrands: builder.query({
                query: (page) => ({
                    url: `/brands`,
                    method: 'POST'
                }),
                providesTags: ['brands']
            }),
            getBrand: builder.query({
                query: (id) => ({
                    url: `/brands/${id}`,
                    method: 'POST'
                }),
                providesTags: ['brands']
            }),
            getBrandsByUser: builder.query({
                query: (userId) => ({
                    url: `/brands/${userId}/byuser`, // New API endpoint
                    method: 'GET',
                }),
                providesTags: ['brands']
            }),
            getBrandsByType: builder.query({
                query: (type) => ({
                    url: `/brandsbytype/${type}`, // New API endpoint
                    method: 'GET',
                }),
                providesTags: ['brands']
            }),
        }
    }
});

export const {
    useCreateBrandMutation,
    useDeleteBrandMutation,
    useUpdateBrandMutation,
    useGetBrandsQuery,
    useGetBrandQuery,
    useGetBrandsByUserQuery,
    useGetBrandsByTypeQuery
} = brandService;

export default brandService;
