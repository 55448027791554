import { Box, Typography, useTheme } from "@mui/material";
import Friend from "../../components/Friend";
import WidgetWrapper from "../../components/WidgetWrapper";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFriends } from "../../store/reducers/authReducer";
import { useGetFollowingUsersQuery } from "../../store/services/FriendRequestService";


const FriendListWidget = ({ userId }) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const token = useSelector((state) => state.authReducer.token);
  const friends = useSelector((state) => state.authReducer.user.friends);
  const { _id, picturePath } = useSelector((state) => state.authReducer.user);

  const { data: following, error, isLoading } = useGetFollowingUsersQuery(_id);

  return (
    <WidgetWrapper>
      <Typography
        color={palette.neutral.dark}
        variant="h5"
        fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        Followings List
      </Typography>
      <Box display="flex" flexDirection="column" gap="1.5rem">
        {following?.length > 0 && following?.map((friend) => (
          <Friend
            key={friend._id}
            friendId={friend._id}
            name={`${friend.firstName}`}
            subtitle={friend.occupation}
            userPicturePath={friend.picturePath}
          />
        ))}
      </Box>
    </WidgetWrapper>
  );
};

export default FriendListWidget;
