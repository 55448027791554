/* eslint-disable jsx-a11y/img-redundant-alt */
import SidebarMenuItem from "./SidebarMenuItem";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

const Sidebar = ({ maindata, mainsubcat, onpress }) => {
  const { user } = useSelector((state) => state.authReducer);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      className={`z-10 ${
        isMobile
          ? "fixed left-0 top-1/2 transform -translate-y-1/2 bg-white shadow-lg rounded-r-lg"
          : "mt-10 sm:flex flex-col p-2 xl:items-start fixed h-full"
      }`}
    >
      <div
        className={`${
          isMobile
            ? "p-1 max-h-[80vh] overflow-y-auto"
            : "mt-4 mb-2.5 xl:items-start"
        }`}
      >
        {maindata?.map((item) => (
          <SidebarMenuItem
            key={item.id}
            item={item}
            text={item.name}
            mainimg={item.image}
            mainsubcat={mainsubcat}
            onClick={() => onpress(item)}
            isMobile={isMobile}
          />
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
