import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const allpetService = createApi({
  reducerPath: 'allpet',
  tagTypes: "allpet",
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.petzoy.in/' , prepareHeaders: (headers, { getState }) => {
    const reducers = getState();
    const token = reducers?.authReducer?.token;
    headers.set('authorization', token ? `Bearer ${token}` : '');
    return headers;
  }}),
  endpoints: (builder) => ({
    // Pets
    getAllPets: builder.query({
      query: () => `pets/pets`,
      providesTags: ["allpet"],
    }),
    activateDeactivatePet: builder.mutation({
      query: ({ userId, petId, status }) => ({
        url: `pets/${userId}/pet/${petId}/status`,
        method: 'PUT',
        body: { status },
      }),
      invalidatesTags: ['allpet']
    }),
    createNewPetUser: builder.mutation({
      query: ({ userId, formData }) => ({
        url: `pet_auth/${userId}/pet`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['allpet']
    }),
    updatePetUserImg: builder.mutation({
      query: (formData) => ({
        url: `pet_auth/updateimg`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['allpet']
    }),
    getPetaccoUser: builder.query({
      query: (userId) => `pets/${userId}/pets`,
      providesTags: ['allpet'],
    }),
   getPethoroscopeaccoUser: builder.query({
      query: (userId) => `pets/${userId}/horoscopes`, // Endpoint path
      providesTags: ['allpet'],
    }),
    deletePet: builder.mutation({
      query: ({ userId, petId }) => ({
        url: `pets/${userId}/pet/${petId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['allpet']
    }),
    removePet: builder.mutation({
      query: ({ userId, petId }) => ({
        url: `pets/${userId}/removepet/${petId}`,
        method: 'POST',
      }),
      invalidatesTags: ['allpet']
    }),
    getSpecificPet: builder.query({
      query: ({ userId, petId }) => `pets/${userId}/mypet/${petId}`,
      providesTags: ['allpet'],
    }),
    getSpecificPetbyid: builder.query({
      query: ({ petId }) => `pets/${petId}`,
      providesTags: ['allpet'],
    }),
    updatePetUser: builder.mutation({
      query: (body) => ({
          url: 'pets/update-petdetails', 
          method: 'PUT',
          body: body
      }),
      invalidatesTags: ['allpet']
  }),
  toggleLikePet: builder.mutation({
    query: ({ petId, userId }) => ({
      url: `pets/${petId}/toggle-like`,
      method: 'POST',
      body: { userId:userId },
    }),
    invalidatesTags: ['allpet']
  }),
  isPetLiked: builder.query({
    query: ({ petId, userId }) => `pets/${petId}/is-liked/${userId}`,
    providesTags: ['allpet'],
  }),

    // More endpoints can be added similarly...

  }),
});

export const {
  useGetAllPetsQuery,
  useActivateDeactivatePetMutation,
  useCreateNewPetUserMutation,
  useGetPetaccoUserQuery,
  useGetPethoroscopeaccoUserQuery,
  useDeletePetMutation,
  useRemovePetMutation,
  useGetSpecificPetQuery,
  useGetSpecificPetbyidQuery,
  useUpdatePetUserMutation,
  useUpdatePetUserImgMutation,
  useToggleLikePetMutation,
  useIsPetLikedQuery
} = allpetService;

export default allpetService;
