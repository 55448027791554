import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useCatProductsQuery, useGetSubcategoryProductsQuery } from "../../store/services/homeProducts";
import { discount } from "../../utils/discount";
import currency from "currency-formatter";
import { useAllCategoriesQuery } from "../../store/services/categoryService";
import HomeProducts from "../../components/HomeProducts/HomeProducts";


import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useGetSubcategoriesByCategoryQuery } from "../../store/services/subcategoryService";
import { useSelector } from "react-redux";
import { useGetNearbyUserPetsQuery } from "../../store/services/authService";
import Moment from "react-moment";


const AllNearbyPets = () => {
    const latlong = useSelector((state) => state.authReducer.latlong);
    const [user_coordinates, setuser_coordinates] = useState({
        latitude: latlong?.latitude || "",
        longitude: latlong?.longitude || "",
      });
    const { data, error: userpeterror } =
    useGetNearbyUserPetsQuery({
      latitude: user_coordinates.latitude,
      longitude: user_coordinates.longitude,
      
    });

  let i = 1;
  return (
    <>
      <main class="fix">
        <section class="category__area">
          <div class="container">
            <div class="row align-tiems-center">
              <div class="col-md-8">
                <div class="section__title-two mb-30">
                  <h2 class="title">
                    Pets Near You{" "}
                    <img
                      src="assets/img/images/title_shape.svg"
                      alt=""
                      class="injectable"
                    />
                  </h2>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
            {data?.map((category, index) => {
          if (i >= 5) {
            i = 1;
          } else {
            i++;
          }
          return (
          
              <Link
                to={{
                  pathname: `/contactprofile/${category._id}`,
                }}
                state={{
                  userId: category._id,
                }}
                // to={`/shop/${category.name}`}
                className="w-full h-full"
              >
                {/* <div className="col-xl-12" key={category._id}>
                  <div className="animal__item animal__item-two shine-animate-item">
                    <div className="animal__thumb animal__thumb-two shine-animate">
                      <a href="animal-details.html"><img src={`https://api.petzoy.in/assets/${category?.picturePath}`} alt="img" /></a>
                    </div>
                    <div className="animal__content animal__content-two">
                      <h4 className="title"><a href="animal-details.html">{category?.name} </a></h4>
                      <li> {distanceaway.toFixed(2)} Km away</li>
                      <div className="pet-info">
                        <ul className="list-wrap">
                          <li>Gender: <span>Male</span></li>
                          <li>Breed: <span>{category?.breed}</span></li>
                        </ul>
                      </div>

                    </div>
                  </div>
                </div> */}

                {/* <div class="card">
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt quibusdam omnis repellendus, atque similique magnam alias.</p>
                  <div class="image">
                    <img src="https://assets.codepen.io/4787486/puppies-2.jpg" alt="" />
                  </div>
                  <div class="button">Puppies</div>
                </div> */}

                {/* <div class="wrapper" id="petcard">
                  <div class="card">
                    <div class="image">
                      <img
                        src={`https://api.petzoy.in/assets/${category?.picturePath}`}
                        alt=""
                      />
                    </div>
                    <div class="button">{category?.name}</div>
                    <div>
                      <p className="p-1">
                        <i
                          class={
                            category?.gender == "male"
                              ? "fa-solid fa-mars "
                              : "fa-solid fa-venus"
                          }
                        ></i>{" "}
                        {category?.age} Yrs.{" "}
                        {category?.type == "Dog" ? (
                          <i class="fa-solid fa-shield-dog" />
                        ) : (
                          <i class="fa-solid fa-cat" />
                        )}{" "}
                        {category?.breed}
                      </p>
                    </div>
                  </div>
                </div> */}
                <div class="petcard">
                <div className="petImageContainer">
                  <img
                    class="card-image"
                    src={`https://api.petzoy.in/assets/${category?.picturePath}`}
                    alt="Dog"
                  />
                </div>
                  <div class="card-content">
                    <div className="userMainHeading">
                      <h3 class="button">{category?.name}</h3>
                      <img
                        class="user-Image"
                        src={`https://api.petzoy.in/assets/${category?.owner.picturePath}`}
                        alt="Dog"
                      />
                    </div>
                    <div class="card-info">
                    {!category?.private ? <span class="card-icon">
                        {category?.private &&  <i class="fa-solid fa-lock"/>} {"  "}
                        
                          <img
                          
                          src={`https://api.petzoy.in/assets/${category?.image}`}
                          style={{width:"20px",height:"20px"}}
                        />
                        
                        {category?.breed}
                      </span>:
 <i class="fa-solid fa-lock"/>
        }
                      {!category?.private &&   <span class="card-date">
                        <i
                          class={
                            category?.gender == "male"
                              ? "fa-solid fa-mars "
                              : "fa-solid fa-venus"
                          }
                        ></i>{" "}
                                     <Moment
        fromNow
        style={{
          color: "#505050",
          fontSize: "10px",
        }}
      >
        {category?.age}
      </Moment>  {" "}
                      </span>}
                    </div>
                  </div>
                </div>
              </Link>
            
          );
        })}

          </div>
          </div>
        </section>

      </main>
    </>
  );
};

export default AllNearbyPets;
