const SidebarMenuItem = ({ text, Icon, active, onClick, mainimg }) => {
  return (
    <div className="hoverEffect flex items-center text-gray-700 justify-center xl:justify-start text-lg space-x-3" onClick={onClick}>
      {Icon && <Icon className="h-7" />}
      {mainimg && (
        <img
          src={`https://api.petzoy.in/assets/${mainimg}`}
          className="sm:mt-2 sm:mb-2 md:mt-0 md:mb-0" // Margin for mobile view
          style={{ width: "40px", height: "40px" }}
        />
      )}
      <span className={`${active && "font-bold"} hidden xl:inline`}>
        {text}
      </span>
    </div>
  );
};

export default SidebarMenuItem;
