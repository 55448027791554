import React, { useEffect, useState } from "react";
import Friend from "../../components/Friend";
import {
  useGetSpecificPetbyidQuery,
  useIsPetLikedQuery,
  useToggleLikePetMutation,
} from "../../store/services/allpetService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import WhiteHeader from "../../components/WhiteHeader";
import { useZIM } from "../../hooks/zim";
import {
  useDeleteUserReviewMutation,
  useGetUserReviewsByPetIdQuery,
} from "../../store/services/userReviewService";
import UserPostReview from "../../components/createUserReview";
import UserImage from "../../components/UserImage";
import Moment from "react-moment";
import { Rating } from "react-simple-star-rating";
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { useCheckFollowingQuery } from "../../store/services/FriendRequestService";

const ContactProfile = () => {
  const navigate = useNavigate();
  const { state: locationstate } = useLocation();
  const { userId } = useParams();
  const [state, zimAction] = useZIM();
  const isAuth = Boolean(useSelector((state) => state.authReducer.token));
  const _id = useSelector((state) => state.authReducer.user?._id);
  const { data, isFetching } = useGetSpecificPetbyidQuery({ petId: userId });
  const { data: userreviews, isFetching: userreviewfetching } =
    useGetUserReviewsByPetIdQuery(userId);
  const [first_loading, setfirst_loading] = useState(true);
  const [selectedindex, setselectedindex] = useState(0);
  const [link_copied, setlink_copied] = useState(false)
  
  const sendMessage = (msg) => {
    navigate(`/userconnect/${data?.owner._id}`, { state: { userId: data?.owner._id } });

    //   zimAction.sendChatMessage(0, "Hey your profile seems interesting", data?.owner._id, false).then(() => {
    //       setTimeout(() => {
    //         navigate("/chat")
    //       }, 200);
    //   });
  };

  const [deleteReview] = useDeleteUserReviewMutation();

  const handleDeleteReview = async (reviewId) => {
    try {
      await deleteReview(reviewId).unwrap();
      // Handle success (e.g., show a success message)
    } catch (error) {
      // Handle error (e.g., show an error message)
    }
  };

  const [toggleLikePet, { data: toggleLikeData }] = useToggleLikePetMutation();
  const { data: isLikedData } = useIsPetLikedQuery({
    petId: userId,
    userId: _id,
  });

  const isLiked = isLikedData?.isLiked;

  const {
    data: followinguser,

    refetch: refetchfollowingrequest,
  } = useCheckFollowingQuery({
    userId: _id, // Assuming user object has an 'id' property
    followeeId: data?.owner._id, // Replace 'otherUserId' with the actual other user's ID
    skip: !isAuth, // Skip the query if user is not authenticated
  });

  useEffect(() => {
    // Handle any side effects or updates based on `toggleLikeData`
  }, [toggleLikeData]);

  const handleToggleLike = async () => {
    await toggleLikePet({ petId: userId, userId: _id });
  };

  useEffect(() => {
    if (!isFetching) {
      setfirst_loading(false);
    }
  }, [isFetching]);

  if (isFetching && first_loading) {
    return <Loader />;
  }

  return (
    <body className="fix">
      <Toaster position="top-right" />
      <section className="product__details-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="product__details-images-wrap">
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane show active"
                    id="itemOne-tab-pane"
                    role="tabpanel"
                    aria-labelledby="itemOne-tab"
                    tabindex="0"
                  >
                    <a
                      href={`https://api.petzoy.in/assets/${data?.picturePath}`}
                      className="popup-image"
                    >
                      <img
                        src={`https://api.petzoy.in/assets/${data?.picturePath}`}
                        alt="img"
                      />
                    </a>
                  </div>
                </div>
                {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="itemOne-tab" data-bs-toggle="tab" data-bs-target="#itemOne-tab-pane" type="button" role="tab" aria-controls="itemOne-tab-pane" aria-selected="true">
                                    <img src={`https://api.petzoy.in/assets/${data?.picturePath}`} alt="img"/>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="itemTwo-tab" data-bs-toggle="tab" data-bs-target="#itemTwo-tab-pane" type="button" role="tab" aria-controls="itemTwo-tab-pane" aria-selected="false">
                                    <img src="assets/img/products/products_img02.jpg" alt="img"/>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="itemThree-tab" data-bs-toggle="tab" data-bs-target="#itemThree-tab-pane" type="button" role="tab" aria-controls="itemThree-tab-pane" aria-selected="false">
                                    <img src="assets/img/products/products_img03.jpg" alt="img"/>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="itemFour-tab" data-bs-toggle="tab" data-bs-target="#itemFour-tab-pane" type="button" role="tab" aria-controls="itemFour-tab-pane" aria-selected="false">
                                    <img src="assets/img/products/products_img04.jpg" alt="img"/>
                                </button>
                            </li>
                        </ul> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="product__details-content">
                <div className="product-desc-wrap">
                  <ul className="nav nav-tabs" id="myTab2" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        onClick={() => setselectedindex(0)}
                        className="nav-link active"
                        id="description-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#description-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="description-tab-pane"
                        aria-selected="true"
                      >
                        Description
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        onClick={() => {
                          {
                            !data?.private || followinguser?.isFollowing  || data?.owner._id == _id
                              ? setselectedindex(1)
                              : toast.success("This account is Private");
                          }
                        }}
                        className="nav-link"
                        id="reviews-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#reviews-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="reviews-tab-pane"
                        aria-selected="false"
                      >
                        Reviews
                      </button>
                    </li>
                  </ul>
                </div>
                {selectedindex == 0 && (
                  <>
                    <Friend
                      friendId={data?.owner._id}
                      name={`${data?.owner.firstName}`}
                      subtitle={data?.owner.occupation}
                      userPicturePath={data?.owner.picturePath}
                    />
                    {/* <h2 className="title">Meow Mix Seafood Medley Dry Cat Food, 3.15-Pound</h2>
                        <div className="product__reviews-wrap">
                            <div className="product__reviews">
                                <div className="rating">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                </div>
                                <span>(2 Reviews)</span>
                            </div>
                            <div className="product__code">
                                <span>SKU: <strong>CAT4502</strong></span>
                            </div>
                        </div> */}
                    {/* <h4 className="price">$29.00</h4> */}
                    <div className="line__devider" />

                    {data?.private && !followinguser?.isFollowing && data?.owner._id != _id ? (
                      <>
                        <div className="likes-wrap">
                          <a>
                            <i class="fa-solid fa-lock"></i>
                            This profile is Private
                          </a>
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        <div className="likes-wrap">
                          <a
                            className={isLiked ? "liked" : ""}
                            onClick={() => {
                              !isAuth
                                ? toast.success("Please Login to Continue !")
                                : handleToggleLike();
                            }}
                          >
                            <i
                              class={
                                isLiked
                                  ? "fa-solid fa-heart"
                                  : "fa-regular fa-heart"
                              }
                            ></i>
                            {data?.likes.length} Likes
                          </a>

                          <a onClick={() => setselectedindex(1)}>
                            <i class="fa-regular fa-comment"></i>
                            {userreviews?.length} Reviews
                          </a>
                        </div>
                      </>
                    )}

{!data?.private ||  data?.owner._id == _id  ? (
    <>
        <span className="contact-size-title">Pet Name:</span>
        <p>{data?.name}</p>
        <span className="contact-size-title">Pet Type:</span>
        <p>{data?.type}</p>
        <span className="contact-size-title">Pet Breed:</span>
        <p>{data?.breed}</p>
        <span className="contact-size-title">Pet Age:</span>
        
        <p>   <Moment
        fromNow
        
      >
      {data?.age}
      </Moment></p>  
        
        <span className="contact-size-title">Pet Weight:</span>
        <p>{data?.weight} kg</p>
    </>
) : (
    (data?.private && followinguser?.isFollowing) && (
        <>
            <span className="contact-size-title">Pet Name:</span>
            <p>{data?.name}</p>
            <span className="contact-size-title">Pet Type:</span>
            <p>{data?.type}</p>
            <span className="contact-size-title">Pet Breed:</span>
            <p>{data?.breed}</p>
            <span className="contact-size-title">Pet Age:</span>
            
            <p>    <Moment
        fromNow
      
      >
        {data?.age}
      </Moment></p>  
            <span className="contact-size-title">Pet Weight:</span>
            <p>{data?.weight} kg</p>
        </>
    )
)}

                    {/* <div className="product__size-wrap">
                            <span className="size-title">Information:</span>
                            <ul className="list-wrap">
                                <li>
                                    <button>250mg</button>
                                </li>
                                <li>
                                    <button>500mg</button>
                                </li>
                                <li>
                                    <button>
                                        1kg
                                    </button>
                                </li>
                            </ul>
                        </div> */}
                    <div className="product__details-qty">
                      {/* <div className="cart-plus-minus">
                                <input type="text" value="1"/>
                            </div> */}
                      <a onClick={() => sendMessage()} className="add-btn">
                        Contact Now
                      </a>
                    </div>
                    {/* <a href="product-details.html" className="buy-btn">Buy it Now</a> */}
                    <div className="product__wishlist-wrap">
                      {data?.providedServices?.map((item, index) => (
                        <React.Fragment key={index}>
                          <a>
                            <i class="fa-solid fa-tag"></i>
                            {item.name}
                          </a>
                          {/* <i class="fa-solid fa-star"></i> <small><br/></small> -- <span>{item.title}</span> */}
                          <br />
                        </React.Fragment>
                      ))}

                      {/* <a href="product-details.html"><i className="flaticon-exchange"></i>Compare To Other</a> */}
                    </div>
                    <div className="product__details-bottom">
                      <ul className="list-wrap">
                        {/* <li className="product__details-category">
                                    <span className="title">Categories:</span>
                                    <a href="product-details.html">Cat,</a>
                                    <a href="product-details.html">Food,</a>
                                    <a href="product-details.html">Care</a>
                                </li>
                                <li className="product__details-tags">
                                    <span className="title">Tags:</span>
                                    <a href="product-details.html">Nearby Pet,</a>
                                    <a href="product-details.html">Pet Essentials</a>
                                </li> */}
                        <li className="product__details-social">
                          <span className="title">Share :</span>
                          <ul className="list-wrap" id="list-wrap">
              <li>
                <a
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://www.petzoy.in/contactprofile/${userId}`
                    );
                    setlink_copied(true)
                    toast.success('Link Copied !');
                  }}
                >
                  <i class={link_copied ? "fa-regular fa-circle-check" :"fa-regular fa-copy"}></i>
                </a>
              </li>
            </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
                {/* <div className="product__details-checkout">
                            <span className="title">Guaranteed Safe Checkout</span>
                            <img src="assets/img/products/card.png" alt=""/>
                        </div> */}

                {selectedindex == 1 && (
                  <>
                    {isAuth &&
                      !userreviews.some(
                        (review) => review?.createdBy?._id === _id
                      ) &&
                      _id != userId && <UserPostReview userpet={userId} />}
                    <div className="comments-wrap">
                      <h3 className="comments-wrap-title">
                        {userreviews.length} Reviews
                      </h3>
                      <div className="latest-comments">
                        <ul className="list-wrap">
                          <li>
                            {userreviews &&
                              userreviews.map((reply) => (
                                <div className="comments-box">
                                  <UserImage
                                    image={reply?.createdBy?.picturePath}
                                    size="55px"
                                  />
                                  <div className="comments-text">
                                    <div className="avatar-name">
                                      <h6 className="name">
                                        {reply?.createdBy?.firstName}
                                      </h6>
                                      <span className="date ">
                                        <Moment
                                          fromNow
                                          style={{
                                            color: "#505050",
                                            marginLeft: "5px",
                                          }}
                                        >
                                          {reply?.createdAt}
                                        </Moment>
                                      </span>
                                    </div>

                                    <Rating
                                      readonly
                                      SVGstyle={{ display: "inline" }}
                                      initialValue={reply?.rating}
                                      size={20}
                                    />
                                    <p>{reply?.comment}</p>
                                    {reply.createdBy?._id === _id && (
                                      <button
                                        onClick={() =>
                                          handleDeleteReview(reply._id)
                                        }
                                      >
                                        Delete
                                      </button>
                                    )}
                                  </div>
                                </div>
                              ))}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* <div className="row">
                <div className="col-12">
                    <div className="product-desc-wrap">
                        <ul className="nav nav-tabs" id="myTab2" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="description-tab" data-bs-toggle="tab" data-bs-target="#description-tab-pane" type="button" role="tab" aria-controls="description-tab-pane" aria-selected="true">Description</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="reviews-tab" data-bs-toggle="tab" data-bs-target="#reviews-tab-pane" type="button" role="tab" aria-controls="reviews-tab-pane" aria-selected="false">Reviews</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent2">
                            <div className="tab-pane fade show active" id="description-tab-pane" role="tabpanel" aria-labelledby="description-tab" tabindex="0">
                                <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet ante. ibero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.ea commodo consat. Duis aute irure dolor in reprehenderit   volup tate velitesse cillum dolore euy elit ale ruin irure dolor.uis aute irure dolor in reprehenderit n volup tate velit esse cillum,</p>
                                <p>habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet bero sit amet uam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.ea commodo consat.</p>
                            </div>
                            <div className="tab-pane fade" id="reviews-tab-pane" role="tabpanel" aria-labelledby="reviews-tab" tabindex="0">
                                <div className="product-desc-review">
                                    <div className="product-desc-review-title mb-15">
                                        <h5 className="title">Customer Reviews (0)</h5>
                                    </div>
                                    <div className="left-rc">
                                        <p>No reviews yet</p>
                                    </div>
                                    <div className="right-rc">
                                        <a href="#">Write a review</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
      </section>
    </body>
  );
};

export default ContactProfile;
