import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const serviceService = createApi({
  reducerPath: "services",
    tagTypes: "services",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.petzoy.in/api/services/",
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      console.log(token);
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createService: builder.mutation({
      query: (service) => ({
          url: "services",
          method: "POST",
          body: service,
      }),
      invalidatesTags: ["services"],
  }),
  getAllServices: builder.query({
      query: () => "services",
  }),
  getServiceById: builder.query({
      query: (id) => `services/${id}`,
  }),
  updateService: builder.mutation({
      query: ({ id, ...service }) => ({
          url: `services/${id}`,
          method: "PUT",
          body: service,
      }),
      invalidatesTags: ["services"],
  }),
  deleteService: builder.mutation({
      query: (id) => ({
          url: `services/${id}`,
          method: "DELETE",
      }),
      invalidatesTags: ["services"],
  }),
  updateServiceStatus: builder.mutation({
      query: ({ id, ...status }) => ({
          url: `services/${id}/status`,
          method: "PUT",
          body: status,
      }),
      invalidatesTags: ["services"],
  }),
  randomServices: builder.query({
      query: () => "random-services",
  }),
  }),
});

export const {
  useCreateServiceMutation,
  useGetAllServicesQuery,
  useGetServiceByIdQuery,
  useUpdateServiceMutation,
  useDeleteServiceMutation,
    useUpdateServiceStatusMutation,
  useRandomServicesQuery,
} = serviceService;
export default serviceService;
