import {
    ManageAccountsOutlined,
    EditOutlined,
    LocationOnOutlined,
    WorkOutlineOutlined,
    ChildFriendlyOutlined,
    FitnessCenter,
    ScheduleOutlined,
  } from "@mui/icons-material";
  import { Box, Typography, Divider, useTheme } from "@mui/material";
  import UserImage from "../../components/UserImage";
  import FlexBetween from "../../components/FlexBetween";
  import WidgetWrapper from "../../components/WidgetWrapper";
  import { useSelector } from "react-redux";
  import { useEffect, useState } from "react";
  import { useNavigate } from "react-router-dom";
import Friend from "../../components/Friend";
import MyPetOwner from "../../components/MyPetOwner";
import Moment from "react-moment";
  
  const PetWidget = ({ userId, picturePath,user,isProfile = false }) => {

    const { palette } = useTheme();
    const navigate = useNavigate();
    const token = useSelector((state) => state.authReducer.token);
    const dark = palette.neutral.dark;
    const medium = palette.neutral.medium;
    const main = palette.neutral.main;
  
    if (!user) {
      return null;
    }
  
    const {
      name,
      breed,
      weight,
      occupation,
      age,
      impressions,
      friends,
    } = user;
  
    return (
      <WidgetWrapper>
        {/* FIRST ROW */}
        <FlexBetween
          gap="0.5rem"
          pb="1.1rem"
          onClick={() => navigate("/profile",{ state: {userId:userId}} )}
        >
          <FlexBetween gap="1rem">
            <UserImage image={picturePath} />
            <Box>
              <Typography
                variant="h4"
                color={dark}
                fontWeight="500"
                sx={{
                  "&:hover": {
                    color: palette.primary.light,
                    cursor: "pointer",
                  },
                }}
              >
                {name} 
              </Typography>
              <Typography color={medium}>{breed}</Typography>
            </Box>
          </FlexBetween>
          <ManageAccountsOutlined />
        </FlexBetween>
  
        <Divider />
  
        {/* SECOND ROW */}
        <Box p="1rem 0">
          <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
            <ScheduleOutlined fontSize="large" sx={{ color: main }} />
            <Typography color={medium}>
            <Moment
        fromNow
        style={{
          color: "#505050",
          fontSize: "10px",
        }}
      >
        {age}
      </Moment>
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap="1rem">
            <FitnessCenter fontSize="large" sx={{ color: main }} />
            <Typography color={medium}>{weight} kg</Typography>
          </Box>
        </Box>
  
        <Divider />
  
  
        <Divider />
  
        {/* FOURTH ROW */}
        <Box p="1rem 0">
          <Typography fontSize="1rem" color={main} fontWeight="500" mb="1rem">
            Owner
          </Typography>
  
          <Box display="flex" flexDirection="column" gap="1.5rem">
        {isProfile ? 
 <Friend
 key={user.owner._id}
 friendId={user.owner._id}
 name={`${user.owner.firstName}`}
 subtitle={user.owner.occupation}
 userPicturePath={user.owner.picturePath}
/>
:
<MyPetOwner
key={user.owner._id}
friendId={user.owner._id}
name={`${user.owner.firstName}`}
subtitle={user.owner.occupation}
userPicturePath={user.owner.picturePath}
/>
        }
         
        
      </Box>
        </Box>
      </WidgetWrapper>
    );
  };
  
  export default PetWidget;
  