
import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import { useCatProductsQuery } from '../../store/services/homeProducts';
import { discount } from '../../utils/discount';
import currency  from "currency-formatter";
const AllProducts = () => {
  const { state } = useLocation();
  const {maincat } = state;



  const { data, isFetching } = useCatProductsQuery({
    name: maincat?.name,
    page: "",
  });


  if (isFetching) {
    return <div>Loading...</div>;
}

  if (!data || !data.products || data.products.length === 0) {
    return <div>No products found.</div>;
}
  


  return (
  <>

<main class="fix">

<section class="breadcrumb__area fix">
    <div class="container">
        <div class="row align-items-end">
            <div class="col-lg-8">
                <div class="breadcrumb__content">
                    <h3 class="title">All Products</h3>
                    <nav class="breadcrumb">
                        <span property="itemListElement" typeof="ListItem">
                            <a href="/">Home</a>
                        </span>
                        <span class="breadcrumb-separator"><i class="flaticon-right-arrow-angle"></i></span>
                        <span property="itemListElement" typeof="ListItem">All Products</span>
                    </nav>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="breadcrumb__img">
                    <img src="assets/img/images/breadcrumb_img.png" alt="img" data-aos="fade-left" data-aos-delay="800"/>
                </div>
            </div>
        </div>
    </div>
    <div class="breadcrumb__shape-wrap">
        <img src="assets/img/images/breadcrumb_shape01.png" alt="img" data-aos="fade-down-right" data-aos-delay="400"/>
        <img src="assets/img/images/breadcrumb_shape02.png" alt="img" data-aos="fade-up-left" data-aos-delay="400"/>
    </div>
</section>


<section class="product__area-four">
    <div class="container">
        <div class="row gutter-20 row-cols-1 row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 justify-content-center">
        {data?.products?.map((product, index) => {
            return(
            <div class="col">
                <div class="product__item">
                    <div class="product__thumb">
                         <Link to={{pathname:`/product_details/${product?._id}`}}><img src={`https://api.petzoy.in/assets/${product?.images[0]}` || "assets/img/products/latest_products_img01.jpg"} alt="img"/></Link>
                        <div class="product__action">
                             <Link to={{pathname:`/product_details/${product?._id}`}}><i class="flaticon-love"></i></Link>
                             <Link to={{pathname:`/product_details/${product?._id}`}}><i class="flaticon-loupe"></i></Link>
                             <Link to={{pathname:`/product_details/${product?._id}`}}><i class="flaticon-exchange"></i></Link>
                        </div>
                        <div class="sale-wrap">
                            <span>New</span>
                        </div>
                        <div class="product__add-cart">
                            <Link to={{pathname:`/product_details/${product?._id}`}}  class="btn"><i class="flaticon-shopping-bag"></i>Add To Cart</Link>
                        </div>
                    </div>
                    <div class="product__content">
                        <div class="product__reviews">
                            <div class="rating">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </div>
                            <span>(2 Reviews)</span>
                        </div>
                        <h4 class="title">  <Link to={{pathname:`/product_details/${product?._id}`}}>{product.title}</Link></h4>
                        <h3 class="price">{currency.format(discount(product?.price,product?.discount), { code: "INR" })} <del>{currency.format(product?.price, { code: "INR" })}</del></h3>
                    </div>
                </div>
            </div>)
                })}
            
        </div>
        {/* <nav class="pagination__wrap mt-50">
            <ul class="list-wrap">
                <li class="link-arrow"><a href="#"><img src="assets/img/icon/pagination_icon01.svg" alt="" class="injectable"/></a></li>
                <li class="active"><a href="#">1</a></li>
                <li><a href="courses.html">2</a></li>
                <li><a href="courses.html">3</a></li>
                <li><a href="courses.html">4</a></li>
                <li class="link-arrow"><a href="#"><img src="assets/img/icon/pagination_icon02.svg" alt="" class="injectable"/></a></li>
            </ul>
        </nav> */}
    </div>
</section>

</main>
  </>
  )
}

export default AllProducts