import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const subcategoryService = createApi({
  reducerPath: "subcategory",
  tagTypes: ["subcategories"],
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.petzoy.in/api/",
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      console.log(token);
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
     
      getAllSubcategories: builder.query({
        query: () => {
          return {
            url: "subcategories",
            method: "GET",
          };
        },
        providesTags: ["subcategories"],
      }),
      getSubcategoriesByCategory: builder.query({
        query: (categoryId) => {
          return {
            url: `subcategories/${categoryId}`,
            method: "GET",
          };
        },
        providesTags: ["subcategories"],
      }),
    };
  },
});

export const { 
  useGetAllSubcategoriesQuery,
  useGetSubcategoriesByCategoryQuery,
} = subcategoryService;

export default subcategoryService;
