/* eslint-disable jsx-a11y/img-redundant-alt */
import SidebarMenuItem from "./SidebarMenuItem";
import { HomeIcon } from "@heroicons/react/solid";
import {
  BellIcon,
  BookmarkIcon,
  ClipboardIcon,
  DotsCircleHorizontalIcon,
  DotsHorizontalIcon,
  HashtagIcon,
  InboxIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = ({ maindata, handleTagSelect, handlePetTypeSelect }) => {
  const { user } = useSelector((state) => state.authReducer);
  const navigate = useNavigate();
  return (
    <div className="hidden sm:flex flex-col p-2 xl:items-start fixed h-full">
      {/* Menu */}
      <div className="mt-4 mb-2.5 xl:items-start">
        <SidebarMenuItem
          text="Home"
          Icon={HomeIcon}
          active
          onClick={() => {
            handleTagSelect(
              JSON.stringify({
                _id: "1",
                name: "All Posts",
              })
            );
          }}
        />
        {maindata?.map((item) => (
          <SidebarMenuItem
            text={item.petType}
            mainimg={item.image}
            onClick={() => handlePetTypeSelect(item)}
          />
        ))}
        {/* <SidebarMenuItem text="Notifications" Icon={BellIcon} /> */}
        {/* <SidebarMenuItem text="Messages" Icon={InboxIcon} /> */}
        {/* <SidebarMenuItem text="Bookmarks" Icon={BookmarkIcon} /> */}
        {/* <SidebarMenuItem text="Lists" Icon={ClipboardIcon} /> */}
        <SidebarMenuItem
          text="Profile"
          Icon={UserIcon}
          onClick={() => {
            navigate("/editprofile");
          }}
        />
        {/* <SidebarMenuItem text="More" Icon={DotsCircleHorizontalIcon} /> */}
      </div>
      {/* Button */}
      {/* {user && (
        <button onClick={() =>{
            navigate("/new-post", {
                state: { tagdata: null },
              })
        }} className="bg-blue-400 text-white rounded-full w-56 h-12 font-bold shadow-md hover:brightness-95 text-lg hidden xl:inline">
          Ask Question
        </button>
      )} */}
    </div>
  );
};

export default Sidebar;
