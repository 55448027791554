/* eslint-disable jsx-a11y/img-redundant-alt */
import { Box, Chip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useCreatePostMutation } from "../../../store/services/discussionPosts";
import { api } from "../../../config";
import http from "../../../services/httpService";
import { setPetsType } from "../../../store/reducers/authReducer";
import UserImage from "../../UserImage";
import { AiOutlineCloseCircle } from "react-icons/ai";
import MyPetListWidget from "../../../pages/widgets/MyPetListWidget";
const TweetInput = ({tagdata}) => {
  const [mainpettag, setmainpettag] = useState(tagdata)
  const { _id } = useSelector(
    (state) => state.authReducer.user
  );
  const [input, setInput] = useState("");
  const [showmainModal, setshowmainModal] = useState(false);
  const picturePath = useSelector(
    (state) => state.authReducer.user.picturePath
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setstep] = useState(0);
  const [createpost] = useCreatePostMutation();
  const [tags, settags] = useState([]);

  const [selected_type, setselected_type] = useState(null);
  const [selected_breed, setselected_breed] = useState(null)
  const petstype = useSelector((state) => state.authReducer.petstype);
  const token = useSelector((state) => state.authReducer.token);
  const [selectedTags, setSelectedTags] = useState([]);

  const handleTagChange = (tagID) => {
    setSelectedTags((prevSelectedTags) => {
      if (prevSelectedTags.includes(tagID)) {
        return prevSelectedTags.filter((id) => id !== tagID);
      } else {
        return [...prevSelectedTags, tagID];
      }
    });
  };
  useEffect(() => {
    const fetchTags = async () => {
      try {
        const { data: fetchedTags } = await http.get(api.tagsEndPoint);
        settags(fetchedTags);
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          toast.error("Post Validation Failed!");
        }
      }
    };

    fetchTags();
  }, []);




  const getpet_type = async () => {
    const response = await fetch(`https://api.petzoy.in/pets/pet_type/all`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    dispatch(setPetsType({ petstype: data }));
  };

  useEffect(() => {
    getpet_type();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const handleSubmit = async () => {
    try {

     
      const postData = {
        title: input,
        description: "",
        tags: selectedTags,
        pettag: mainpettag ? mainpettag._id: null,
        pettype:selected_type.petType,
        petbreed:selected_breed
      };
      const { response } = await createpost(postData);

      toast.success("Post created successfully");
      setSelectedTags([]);
      setstep(0);
      console.log(response);
      setInput("");
    } catch (ex) {
      console.error(ex);
    }
  };
  return (
    <div className="flex border-b border-gray-200 p-3 space-x-3">
      <ToastContainer />
      <img
        src={`https://api.petzoy.in/assets/${picturePath}`}
        alt="Profile picture"
        className="h-11 w-11 rounded-full cursor-pointer hover:brightness-95"
      />
      <div className="w-full divide-y divide-gray-200">
        {step == 0 && (
          <div>
            <textarea
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Ask your query to community ...(Min 10 words)"
              className="w-full border-none focus:ring-0 text-lg placeholder-gray-500 tracking-wide min-h-[50px] text-gray-500"
              rows="2"
            ></textarea>
          </div>
        )}

        {step == 1 &&
          tags?.map((item, index) => (
            <Chip
              sx={{ margin: "4px" }}
              label={item.name}
              variant={
                selectedTags.includes(item._id) ? "elevated" : "outlined"
              }
              clickable
              onClick={() => {
                handleTagChange(item._id);
              }}
            />
          ))}
        {step == 2 &&
          petstype?.map((item, index) => (
            <Chip
              sx={{ margin: "4px" }}
              label={item.petType}
              variant={
                selected_type ==  item ? "elevated" : "outlined"
              }
              clickable
              onClick={() => {
                setselected_type(item);
              }}
            />
          ))}
        {step == 3 &&
           selected_type?.petBreeds?.length > 0 &&
            selected_type.petBreeds.map((petbreed) => (
            <Chip
              sx={{ margin: "4px" }}
              label={petbreed.breedName}
              variant={
                selected_breed == petbreed.breedName ? "elevated" : "outlined"
              }
              clickable
              onClick={() => {
                setselected_breed(petbreed.breedName);
              }}
            />
          ))}


{!mainpettag && (<div>
              
             
                <MyPetListWidget
                  userId={_id}
                  onPress={(data) => {
                    
                    console.log(data,"pet tag");
                    setmainpettag(data);
                  }}
                />
            </div>)}


{mainpettag && (
                            <div className="d-flex">
                              <label htmlFor="tags">
                                Discussion About{" "}
                                <strong>{mainpettag?.name} </strong>
                              </label>
                              <UserImage
                                size="20px"
                                image={mainpettag?.picturePath}
                              />
                               <AiOutlineCloseCircle className="h-6 w-6 text-red-500" style={{marginLeft:'200px'}} onClick={() => setmainpettag(null)}/>
                              
                            </div>
                          )}

        <div className="flex items-center justify-between pt-2">
          <div className="flex">
            {/* <EmojiHappyIcon className="h-10 w-10 hoverEffect p-2 text-sky-500 hover:bg-sky-100" /> */}
            {/* <span>(Min 10 words)</span> */}
          </div>
          <button
           onClick={() => {
            if (step === 1 && selectedTags.length === 0) {
              toast.error("Please Select at least one tag");
            } else if (step === 0) {
              setstep(step + 1);
            } else if (mainpettag) {
              handleSubmit();
            } else if (step < 3) {
              setstep(step + 1);
            } else {
              handleSubmit();
            }
          }}
          disabled={input.trim().length < 10}
          className="bg-blue-400 text-white px-4 py-2 rounded-full font-bold shadow-md hover:brightness-95 disabled:opacity-50"
          >
            {step < 3 && !mainpettag ? "Next" : "Ask"}
          </button>
        </div>
      </div>
    </div>
  );
};
export default TweetInput;
