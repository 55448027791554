import React from "react";

const Loader = () => {
  return (
    <div id="preloader">
      <div id="loader" className="loader">
        <div className="loader-container">
          <div className="loader-icon">
            <img src="assets/img/logo/preloader.gif" alt="Preloader" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
