import React, { useState } from "react";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import {
  Box,
  Fab,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import {
  Camera,
  File,
  Image,
  LinkSimple,
  PaperPlaneTilt,
  Smiley,
  Sticker,
  User,
} from "phosphor-react";
import { useZIM } from "../../../hooks/zim";
import SendImageMessage from "../../widgets/SendImageMessage";

const StyledInput = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    paddingTop: "12px !important",
    paddingBottom: "12px !important",
  },
}));

const Footer = ({ maindata, onpress }) => {
  const theme = useTheme();
  const [state, zimAction] = useZIM();
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);

  const sendMessage = (msg) => {
    if (msg == "") {
      return;
    }

    zimAction
      .sendChatMessage(maindata.chattype, message, maindata.id, false)
      .then(() => {
        setMessage("");
        setTimeout(() => {
          onpress();
        }, 500);
      });
  };



  const sendMediaMessage = (mainimg) => {
    if (!mainimg) {
      return;
    }
  console.log(mainimg)
    const reader = new FileReader();
    // reader.onload = (e) => {
      // const fileData = e.target.result;
  
      const msgs = { type: 11, fileLocalPath: mainimg };
      zimAction.sendMediaMessage(msgs, maindata.id, maindata.chattype).then(() => {
        setFile(null);
        setTimeout(() => {
          onpress();
        }, 200);
      });
    // };
  
    // reader.readAsDataURL(mainimg);
  };
  

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const [openPicker, setOpenPicker] = useState(false);
  const [showimagemodal, setshowimagemodal] = useState(false)
  return (
   
    <>
      <ChatInput
        setOpenPicker={setOpenPicker}
        message={message}
        setMessage={setMessage}
        setshowimagemodal={setshowimagemodal}
      />
      <SendImageMessage open={showimagemodal} setOpen={setshowimagemodal} sendmessage={(e) => {console.log(e); sendMediaMessage(e)}}/>
      <div style={{ position: "absolute", bottom: 0, left: 20 }}>
        {openPicker && (
          <Picker
            data={data}
            onEmojiSelect={(e) => {
              setMessage(message + e.native);
              setOpenPicker(false);
            }}
            theme={theme.palette.mode}
          />
        )}
      </div>
      <div class="custom-form__send-emoji" onClick={() => setOpenPicker(true)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="svg-icon svg-icon--send-emoji"
          viewBox="0 0 46.2 46.2"
        >
          <path
            d="M23.1,0A23.1,23.1,0,1,0,46.2,23.1,23.1,23.1,0,0,0,23.1,0Zm0,41.6A18.5,18.5,0,1,1,41.6,23.1,18.5,18.5,0,0,1,23.1,41.6Zm8.1-20.8a3.5,3.5,0,0,0,3.5-3.5,3.5,3.5,0,0,0-7,0,3.5,3.5,0,0,0,3.5,3.5ZM15,20.8a3.5,3.5,0,0,0,3.5-3.5A3.5,3.5,0,0,0,15,13.9a3.4,3.4,0,0,0-3.4,3.4A3.5,3.5,0,0,0,15,20.8Zm8.1,15a12.6,12.6,0,0,0,10.5-5.5,1.7,1.7,0,0,0-1.3-2.6H14a1.7,1.7,0,0,0-1.4,2.6A12.9,12.9,0,0,0,23.1,35.8Z"
            fill="#f68b3c"
          />
        </svg>
      </div>
      <button
        type="button"
        onClick={() => sendMessage()}
        class="custom-form__send-submit"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="svg-icon svg-icon--send"
          viewBox="0 0 45.6 45.6"
        >
          <g>
            <path
              d="M20.7,26.7a1.4,1.4,0,0,1-1.2-.6,1.6,1.6,0,0,1,0-2.4L42.6.5a1.8,1.8,0,0,1,2.5,0,1.8,1.8,0,0,1,0,2.5L21.9,26.1A1.6,1.6,0,0,1,20.7,26.7Z"
              fill="#d87232"
            />
            <path
              d="M29.1,45.6a1.8,1.8,0,0,1-1.6-1L19.4,26.2,1,18.1a1.9,1.9,0,0,1-1-1.7,1.8,1.8,0,0,1,1.2-1.6L43.3.1a1.7,1.7,0,0,1,1.8.4,1.7,1.7,0,0,1,.4,1.8L30.8,44.4a1.8,1.8,0,0,1-1.6,1.2ZM6.5,16.7l14.9,6.6a2,2,0,0,1,.9.9l6.6,14.9L41,4.6Z"
              fill="#d87232"
            />
          </g>
        </svg>
      </button>
    </>
  );
};

export default Footer;

const Actions = [
  {
    color: "#4da5fe",
    icon: <Image size={24} />,
    y: 102,
    title: "Photo/Video",
  },
  {
    color: "#1b8cfe",
    icon: <Sticker size={24} />,
    y: 172,
    title: "Stickers",
  },
  {
    color: "#0172e4",
    icon: <Camera size={24} />,
    y: 242,
    title: "Image",
  },
  {
    color: "#1b8cfe",
    icon: <File size={24} />,
    y: 312,
    title: "Document",
  },
  {
    color: "#013f7f",
    icon: <User size={24} />,
    y: 382,
    title: "Contact",
  },
];

const ChatInput = ({ setOpenPicker, message, setMessage,setshowimagemodal }) => {
  const [openAction, setOpenAction] = useState(false);
  
  return (
    <StyledInput
      fullWidth
      placeholder="Write a message..."
      variant="filled"
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <Stack sx={{ width: "max-content" }}>
            <Stack display={ openAction ? "inline" : "none"} sx={{ position: "relative" }}>
              {Actions.map((el) => (
                <Tooltip title={el.title} placement="right">
                  <Fab
                    sx={{
                      position: "absolute",
                      top: -el.y,
                      backgroundColor: el.color,
                    }}
                    onClick={() => el.title === "Photo/Video" ? setshowimagemodal(true) : undefined}
                  >
                    {el.icon}
                  </Fab>
                </Tooltip>
              ))}
            </Stack>
            <InputAdornment>
              <IconButton onClick={() => setOpenAction((prev) => !prev)}>
                <LinkSimple />
              </IconButton>
            </InputAdornment>
          </Stack>
        ),
        // endAdornment: (
        //   <InputAdornment>
        //     <IconButton onClick={() => setOpenPicker((prev) => !prev)}>
        //       <Smiley />
        //     </IconButton>
        //   </InputAdornment>
        // ),
      }}
      value={message}
      onChange={(e) => setMessage(e.target.value)}
    />
  );
};
