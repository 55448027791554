import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const tagService = createApi({
  reducerPath: 'tags',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.petzoy.in/tags/',
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      headers.set('x-auth-token', token ? `${token}` : '');
      return headers;
    }
  }),
  endpoints: (builder) => ({
    createTag: builder.mutation({
      query: (data) => ({
        url: '/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['tags']
    }),
    deleteTag: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['tags']
    }),
    updateTagStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `/status/${id}`,
        method: 'PUT',
        body: { status }
      }),
      invalidatesTags: ['tags']
    }),
    getAllTags: builder.query({
      query: () => '/',
      providesTags: ['tags']
    }),
  }),
});

export const {
  useCreateTagMutation,
  useDeleteTagMutation,
  useUpdateTagStatusMutation,
  useGetAllTagsQuery,
} = tagService;

export default tagService;
