import { useEffect, useState } from "react";

import {
  useAllCategoriesQuery,
  useRandomCategoriesQuery,
} from "../../store/services/categoryService";
import {
  useGetAllServicesQuery,
  useRandomServicesQuery,
} from "../../store/services/serviceService";
import {
  useGetNearbyUserPetsQuery,
  useGetNearbyUsersQuery,
} from "../../store/services/authService";
import Loader from "../../components/Loader";
import Banner from "../../components/Banner/Banner";
import Services from "../../components/Services/Services";
import NearbyPets from "../../components/NearByPets/NearbyPets";
import NearbyUsers from "../../components/NearbyUsers/NearbyUsers";
import NearByBusiness from "../../components/NearByBusiness/NearByBusiness";
import OurBlogs from "../../components/OurBlogs/OurBlogs";
import RandomServicesUser from "../../components/RandomServices/RandomServices";
import { useDispatch, useSelector } from "react-redux";
import {
  setDemoShow,
  setdisplaylocation,
  setlocation,
} from "../../store/reducers/authReducer";
import {
  Box,
  Button,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MapPicker from "react-google-map-picker";
import LocationHeader from "../../components/Header/LocationHeader";
import HomeProducts from "../../components/HomeProducts/HomeProducts";
import { Link } from "react-router-dom";
import { useGetBrandsQuery } from "../../store/services/brandService";
import { useUsersbyServiceQuery } from "../../store/services/homeProducts";
import { useGetAllBlogsQuery } from "../../store/services/blogService";
import Joyride, { STATUS } from "react-joyride";

const OPTIONS = { loop: true };
const SLIDE_COUNT = 5;
const SLIDES = Array.from(Array(SLIDE_COUNT).keys());

function Home() {
  const dispatch = useDispatch();
  const [selectlocation, setselectlocation] = useState(false);
  const latlong = useSelector((state) => state.authReducer.latlong);
  const showdemo = useSelector((state) => state.authReducer.demoshowhome);
  const { data: servicedata, isFetching: servicefetch } =
    useRandomServicesQuery();
  const { data: allservice, isFetching: allservicefetch } =
    useGetAllServicesQuery();
  const [user_coordinates, setuser_coordinates] = useState({
    latitude: latlong?.latitude || "",
    longitude: latlong?.longitude || "",
  });
  const { data: allBlogs, isLoading: isblogsloading } = useGetAllBlogsQuery();
  const { data: nearbyusersdata, error: usererror } = useGetNearbyUsersQuery({
    latitude: latlong?.latitude || user_coordinates.latitude,
    longitude:latlong?.longitude || user_coordinates.longitude,
  });
  const { data: nearbyuserspetdata, error: userpeterror } =
    useGetNearbyUserPetsQuery({
      latitude: latlong?.latitude || user_coordinates.latitude,
      longitude: latlong?.longitude || user_coordinates.longitude,
      totalnum:15
    });

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  function success(pos) {
    var crd = pos.coords;
    console.log("Your current position is:");
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    console.log(`More or less ${crd.accuracy} meters.`);
    dispatch(
      setlocation({
        latlong: { latitude: crd.latitude, longitude: crd.longitude },
      })
    );
    setuser_coordinates({ latitude: crd.latitude, longitude: crd.longitude });
  }

  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  useEffect(() => {
    console.log("saved lat long", latlong);
    if (navigator.geolocation && latlong == null) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          console.log(result);
          if (result.state === "granted") {
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "prompt") {
            //If prompt then the user will be asked to give permission
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
          }
        });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");

  
  const { data: brandsdata, isLoading, error } = useGetBrandsQuery();
  const brands = brandsdata?.brands;

  const [selectedservice_name, setselectedservice_name] = useState("");
  const [selected_service, setselected_service] = useState("");
  const [selectedservicetype, setselectedservicetype] = useState(null);

  const { data: serviceuser, isFetching: serviceuserloading } =
    useUsersbyServiceQuery({
      service: selected_service,
      page: "",

    latitude:latlong?.latitude,
    longitude:latlong?.longitude
    });




const story = [
  {
    target:  isNonMobileScreens ? '.nearbyheader':'.nearbybanner',
    content: 'Hyperlocal Hub: Connect with fellow pet parents, vets, trainers, pet shops, and co-breeders in your immediate area.',
  },
  {
    target: '.shopsheader',
    content: 'Give the gift of happiness with Petzoy.  Create unforgettable moments for your pets and loved ones with Petzoy gifts. Also, empower animal welfare by donating directly to volunteers from your cart in your area. Choose from food, safety equipment, and more. Your support makes a real impact.',
  },
  {
    target: '.socialheader',
    content: 'This is a social media platform designed for pets, where all posts from pet profiles are automatically shared within a community of similar breeds. Additionally, you can create a business shop to professionally connect with pet owners in nearby areas',
  },
  {
    target: '.petoheader',
    content: 'Knowledge is Pawsome: Share experiences, ask questions, and offer advice on everything from pet care and training to mental health.',
  },
  {
    placement: 'top',
    target: '.animal__area-two',
    content: 'Community Support: Create a platform where animal lovers can connect, volunteer, and contribute to your mission.',
  },
  {
    placement: 'top',
    target: '.team__area',
    content: 'Promote Adoptions: Showcase adoptable local breeds and find loving homes for deserving animals.',
  },
  {
    placement: 'top',
    target: '.nearbypetcomm',
    content: 'Targeted Reach: Advertise directly to pet owners in your neighborhood through an unrestricted platform.',
  },
  {
    placement: 'top',
    target: '.nearbybusscomm',
    content: 'Engage & Grow: Build trust and credibility by actively supporting and engaging with other community.',
  },
 
];

const handleJoyrideCallback = (data) => {
  const { status, type } = data;
  const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

  if (finishedStatuses.includes(status)) {
    dispatch(setDemoShow(true))
  }

};



  return (
    <div>
      {servicefetch && <Loader />}
      <main className="fix">
      {!showdemo && <Joyride
      callback={handleJoyrideCallback}
          steps={story}
          showProgress
        showSkipButton
        scrollToFirstStep
          continuous
        />}

        <Banner slides={SLIDES} options={OPTIONS} />

        <section className="services__area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12">
                <div className="section__title text-center">
                  <span className="sub-title">
                    Pet Services
                    <strong className="shake">
                      <img
                        src="assets/img/icon/pet_icon02.svg"
                        alt=""
                        className="injectable"
                      />
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <Services
                setselectedservicetype={setselectedservicetype}
                setselectedservice={setselected_service}
                setselectedname={setselectedservice_name}
              />
            </div>
          </div>
          <div className="services__shape-wrap">
            <img
              src="assets/img/images/services_shape01.png"
              alt="img"
              className="ribbonRotate"
            />
            <img
              src="assets/img/images/services_shape02.png"
              alt="img"
              data-aos="fade-up-right"
              data-aos-delay="800"
            />
            <img
              src="assets/img/images/services_shape03.png"
              alt="img"
              data-aos="fade-down-left"
              data-aos-delay="400"
            />
          </div>
        </section>

        {serviceuser?.length > 0 && <section className="team__area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="section__title text-center">
                  <span className="sub-title">
                    {selectedservice_name}
                    <strong className="shake">
                      <img
                        src="assets/img/icon/pet_icon02.svg"
                        alt=""
                        className="injectable"
                      />
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            {selectedservicetype.type == 'owner' ?
              <div className="row justify-content-center">
                <NearbyUsers data={serviceuser} />
              </div> :
              <div className="row justify-content-center">

                <NearbyPets data={serviceuser} />
              </div>

            }

          </div>
          <div className="team__shape">
            <img
              src="assets/img/team/team_shape.png"
              alt="img"
              className="ribbonRotate"
            />
          </div>
        </section>}

        <section className="animal__area-two">
          <div className="container">
            <div className="col-lg-12">
              <div className="section__title text-center">
                <span className="sub-title ">
                  Pet Near You
                  <strong className="shake">
                    <img
                      src="assets/img/icon/pet_icon02.svg"
                      alt=""
                      className="injectable"
                    />
                  </strong>
                </span>
              </div>
            </div>
            <div className="row justify-content-center">
              <NearbyPets data={nearbyuserspetdata} />
            </div>
          </div>
          <div className="animal__shape-wrap">
            <img
              src="assets/img/images/adoption_shape01.png"
              alt="shape"
              className="rotateme"
            />
            <img
              src="assets/img/images/adoption_shape02.png"
              alt="shape"
              data-aos="fade-down-left"
              data-aos-delay="400"
            />
          </div>
        </section>

        {!servicefetch &&
          servicedata?.services?.length > 0 &&
          servicedata?.services.map((category) => (
            <RandomServicesUser categoryy={category} />
          ))}

        <div className="marquee__area">
          <div className="marquee__wrap">
            <div className="marquee__box">
              {allservice?.map((category) => (
                <a href="#">
                  {category.name}
                  <img src="assets/img/images/marquee_icon.svg" alt="" />
                </a>
              ))}
            </div>
            <div className="marquee__box">
              {allservice?.map((category) => (
                <a href="#">
                  {category.name}
                  <img src="assets/img/images/marquee_icon.svg" alt="" />
                </a>
              ))}
            </div>
          </div>
        </div>

        {/* NearBy Pet Community */}
        <section className="team__area ">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="section__title text-center">
                  <span className="sub-title nearbypetcomm">
                    NearBy Pet Community
                    <strong className="shake">
                      <img
                        src="assets/img/icon/pet_icon02.svg"
                        alt=""
                        className="injectable"
                      />
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <NearbyUsers data={nearbyusersdata} />
            </div>
          </div>
          <div className="team__shape">
            <img
              src="assets/img/team/team_shape.png"
              alt="img"
              className="ribbonRotate"
            />
          </div>
        </section>

        <section class="product__area-two">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-12">
                <div className="section__title text-center">
                  <span className="sub-title nearbybusscomm">
                    Nearby Business
                    <strong className="shake">
                      <img
                        src="assets/img/icon/pet_icon02.svg"
                        alt=""
                        className="injectable"
                      />
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <NearByBusiness />
            </div>
          </div>

          {/* <div class="container custom-container-two">
            <div class="product__item-wrap-two">
              <div class="row gutter-20 row-cols-1 row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 justify-content-center">
                {!isLoading &&
                  brands?.map((brand) => {
                    return (<div class="col">
                      <div class="product__item">
                        <div class="product__thumb">
                          <Link to="/commericaldetails" state={{ maindata: brand }}><img src={`https://api.petzoy.in/assets/${brand?.picturePath}`} alt="img" /></Link>

                          <div class="sale-wrap sale-wrap-two">
                            <span>Near You !</span>
                          </div>
                          <div class="product__add-cart">
                            <Link to="/commericaldetails" state={{ maindata: brand }} class="btn"><i class="flaticon-eye"></i>View More</Link>
                          </div>
                        </div>
                        <div class="product__content">
                          <div class="product__reviews">
                            <div class="rating">
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                            </div>
                            <span>(2 Reviews)</span>
                          </div>
                          <h4 class="title"><Link to="/commericaldetails" state={{ maindata: brand }}>{brand.name}</Link></h4>
                          <h3 class="price"><i class="fa-solid fa-location-dot" /> {brand?.location}</h3>
                        </div>
                      </div>
                    </div>)
                  })}


              </div>
            </div>
          </div> */}
          <div class="product__shape-wrap">
            <img
              src="assets/img/products/products_shape01.png"
              alt="shape"
              class="ribbonRotate"
            />
            <img
              src="assets/img/products/products_shape02.png"
              alt="shape"
              data-aos="fade-up-right"
              data-aos-delay="400"
            />
          </div>
        </section>

        <section class="blog__post-area-four">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-12">
                <div className="section__title text-center">
                  <span className="sub-title">
                    Our Blogs
                    <strong className="shake">
                      <img
                        src="assets/img/icon/pet_icon02.svg"
                        alt=""
                        className="injectable"
                      />
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <OurBlogs />

              {/* {!isblogsloading &&
                allBlogs?.length > 0 &&
                allBlogs?.map((item) => {
                  return (
                    <div class="col-xl-3 col-lg-4 col-md-6">
                      <div class="blog__post-item-four shine-animate-item">
                        <div class="blog__post-thumb-four shine-animate">
                          <a>
                            <Link to={`/blog-details/${item._id}`}>
                              <img
                                src={`https://api.petzoy.in/assets/${item?.picturePath}`}
                                alt="img"
                              />
                            </Link>
                          </a>
                        </div>
                        <div class="blog__post-content-four">
                          <div class="blog__post-meta">
                            <ul class="list-wrap">
                              <li>
                                <i class="flaticon-calendar"></i>
                                {item?.createdAt?.split("T")[0]}
                              </li>
                              <li>
                                <i class="flaticon-user"></i>by {item?.author}
                              </li>
                            </ul>
                          </div>
                          <h2 class="title">
                            <Link to={`/blog-details/${item._id}`}>
                              {item?.title}
                            </Link>
                          </h2>
                        </div>
                      </div>
                    </div>
                  );
                })} */}
            </div>
          </div>
          <div class="blog__shape-wrap-four">
            <img
              src="assets/img/blog/h4_blog_shape01.png"
              alt="img"
              data-aos="fade-down-left"
              data-aos-delay="400"
            />
            <img
              src="assets/img/blog/h4_blog_shape02.png"
              alt="img"
              data-aos="fade-up-right"
              data-aos-delay="400"
            />
          </div>
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-8"></div>

              {/* <div class="col-md-4">
                <div class="view-all-btn">
                  <a href="blog.html">
                    See All <i class="flaticon-right-arrow-angle"></i>
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Home;
