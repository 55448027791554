import { Typography, useTheme } from "@mui/material";
import FlexBetween from "../../components/FlexBetween";
import WidgetWrapper from "../../components/WidgetWrapper";

const AdvertWidget = ({maindata}) => {
  const { palette } = useTheme();
  const dark = palette.neutral.dark;
  const main = palette.neutral.main;
  const medium = palette.neutral.medium;

  return (
    <WidgetWrapper>
      <FlexBetween>
        <Typography color={dark} variant="h5" fontWeight="500">
          Sponsored
        </Typography>
        {/* <Typography color={medium}>Create Ad</Typography> */}
      </FlexBetween>
      <img
        width="100%"
        
        alt="advert"
        src={`https://api.petzoy.in/assets/${maindata?.image}`}
        style={{ borderRadius: "0.75rem", margin: "0.75rem 0",height:"180px" }}
      />
      <FlexBetween>
        <Typography color={main}>{maindata?.title}</Typography>
        <Typography color={medium}>
      <a
        href={maindata?.website_url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        {maindata?.website_url}
      </a>
    </Typography>
      </FlexBetween>
      <Typography color={medium} m="0.5rem 0">
       {maindata?.description}
      </Typography>
    </WidgetWrapper>
  );
};

export default AdvertWidget;
