import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const blogService = createApi({
  reducerPath: "blogService",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.petzoy.in/api/",
  }),
  endpoints: (builder) => ({
    getAllBlogs: builder.query({
     
      query: () => ({
        url: "blogs",
        method: 'POST'
    }),
    }),
    getBlogDetails: builder.query({
      
      query: (blogId) => ({
        url: `blogs/${blogId}`,
        method: 'POST'
    }),
    }),
  }),
});

export const { useGetAllBlogsQuery, useGetBlogDetailsQuery } = blogService;
export default blogService;
