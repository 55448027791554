import {
  ChatBubbleOutlineOutlined,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  ShareOutlined,
} from "@mui/icons-material";
import { Box, Divider, IconButton, Typography, useTheme } from "@mui/material";
import FlexBetween from "../../components/FlexBetween";
import Friend from "../../components/Friend";
import WidgetWrapper from "../../components/WidgetWrapper";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPost } from "../../store/reducers/authReducer";
import CommentPostModal from "./CommentPostModal";
import Moment from "react-moment";
import "moment-timezone";

const PostWidget = ({
  postId,
  postUserId,
  name,
  description,
  location,
  picturePath,
  mediaType,
  userPicturePath,
  likes,
  comments,
  tag,
  postType,
  brandId,
  createdAt,
}) => {
  const [isComments, setIsComments] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authReducer.token);
  const loggedInUserId = useSelector((state) => state.authReducer.user._id);
  const isLiked = Boolean(likes[loggedInUserId]);
  const likeCount = Object.keys(likes).length;

  const [show_Commentbox, setshow_Commentbox] = useState(false);

  const { palette } = useTheme();
  const main = palette.neutral.main;
  const primary = palette.primary.main;

  const patchLike = async () => {
    const response = await fetch(`https://api.petzoy.in/posts/${postId}/like`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId: loggedInUserId }),
    });
    const updatedPost = await response.json();
    dispatch(setPost({ post: updatedPost }));
  };

  return (
    <WidgetWrapper m="0.5rem 0">
      <CommentPostModal
        open={show_Commentbox}
        setOpen={setshow_Commentbox}
        postId={postId}
        postUserId={postUserId}
        name={`${name}`}
        description={description}
        location={location}
        picturePath={picturePath}
        mediaType={mediaType}
        userPicturePath={userPicturePath}
        likes={likes}
        comments={comments}
        tag={tag}
        postType={postType}
        brandId={brandId}
      />
      <Friend
        friendId={postUserId}
        name={postType == "Brand" ? brandId.name : name}
        subtitle={postType == "Brand" ? brandId.address : location}
        userPicturePath={
          postType == "Brand" ? brandId.picturePath : userPicturePath
        }
        tag={tag}
        brand={brandId}
      />
      <Typography color={main} sx={{ mt: "2rem" }}>
        {description}
      </Typography>
      <Moment
        fromNow
        style={{
          color: "#505050",
          fontSize: "10px",
        }}
      >
        {createdAt}
      </Moment>
      {picturePath &&
        (mediaType == "image" ? (
          <img
            width="100%"
            alt="post"
            style={{
              borderRadius: "0.75rem",
              marginTop: "0.75rem",

              objectFit: "contain", // Ensure the entire video is visible
              maxHeight: "400px", // Set a fixed height
            }}
            src={`https://api.petzoy.in/assets/${picturePath}`}
          />
        ) : (
          mediaType == "video" && (
            <video
              width="100%"
              controls
              style={{
                borderRadius: "0.75rem",
                marginTop: "0.75rem",

                objectFit: "contain", // Ensure the entire video is visible
                maxHeight: "400px", // Set a fixed height
              }}
            >
              <source
                src={`https://api.petzoy.in/assets/${picturePath}`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          )
        ))}
      <FlexBetween mt="0.25rem">
        <FlexBetween gap="1rem">
          <FlexBetween gap="0.3rem">
            <IconButton onClick={patchLike}>
              {isLiked ? (
                <FavoriteOutlined sx={{ color: primary }} />
              ) : (
                <FavoriteBorderOutlined />
              )}
            </IconButton>
            <Typography>{likeCount}</Typography>
          </FlexBetween>

          <FlexBetween gap="0.3rem">
            <IconButton onClick={() => setshow_Commentbox(true)}>
              <ChatBubbleOutlineOutlined />
            </IconButton>
            <Typography>{comments.length}</Typography>
          </FlexBetween>
        </FlexBetween>

        <IconButton>
          <ShareOutlined />
        </IconButton>
      </FlexBetween>
      {isComments && (
        <Box mt="0.5rem">
          {comments.map((comment, i) => (
            <Box key={`${name}-${i}`}>
              <Divider />
              <Typography sx={{ color: main, m: "0.5rem 0", pl: "1rem" }}>
                {comment}
              </Typography>
            </Box>
          ))}
          <Divider />
        </Box>
      )}
    </WidgetWrapper>
  );
};

export default PostWidget;
