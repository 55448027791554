import {
  ChartBarIcon,
  ChatIcon,
  DotsHorizontalIcon,
  EyeIcon,
  HeartIcon,
  ShareIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Moment from "react-moment";
import "moment-timezone";
import { useState } from "react";
import { Check2Circle } from "react-bootstrap-icons";

const Post = ({ post }) => {
  const navigate = useNavigate();

  const [link_copied, setlink_copied] = useState(false)

  
  return (
    <div className="flex p-3 cursor-pointer border-b border-gray-200">
      {/* User Image */}
      <img
        onClick={() =>
          navigate(`/userconnect/${post.author._id}`, {
            state: {
              userId: post.author._id,
            },
          })
        }
        className="h-11 w-11 rounded-full mr-4"
        src={`https://api.petzoy.in/assets/${post.author.picturePath}`}
        alt="Profile picture"
      />
      {/* Right Side */}
      <div className="flex-1">
        {/* Header */}
        <div className="flex items-center justify-between">
          {/* User Info */}
          <div
            onClick={() =>
              navigate(`/userconnect/${post.author._id}`, {
                state: {
                  userId: post.author._id,
                },
              })
            }
            className="flex items-center space-x-1 whitespace-nowrap"
          >
            <h4 className="font-bold text-[15px] sm:text-[16px] hover:underline">
              {post.author?.firstName}
            </h4>
            <span className="text-sm sm:text-[15px]"> - </span>
            <Moment
        fromNow
        style={{
          color: "#505050",
          fontSize: "10px",
        }}
      >
        {post.time}
      </Moment>
            {/* <span className="text-sm sm:text-[15px] hover:underline">
              
            </span> */}
          </div>
          {/* Dot Icon */}
          {/* <DotsHorizontalIcon className="h-10 w-10 hoverEffect hover:bg-sky-100 hover:text-sky-500 p-2" /> */}
        </div>
        <p className="text-gray-800 text-[15px] sm:text-[16px] mb-2">
          {post.title}
        </p>
        {post.pettag && "for"}{" "}
        {post.pettag && (
          <strong
            onClick={() =>
              navigate(`/contactprofile/${post.pettag._id}`, {
                state: {
                  userId: post.pettag._id,
                },
              })
            }
          >
            {post.pettag?.name}
          </strong>
        )}

        {post.pettag && (
          <img
            className="rounded-2xl mr-2"
            src={`https://api.petzoy.in/assets/${post.pettag?.picturePath}`}
            alt="post image"
          />
        )}
        <div className="tag_Main-Container">
          <div className="tag_main">
            {post.tags.map((tag) => (
              <div key={tag._id}>
                <p>{tag.name},</p>
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-between text-gray-500 p-2 w-full">
          <div className="flex items-center space-x-1">
            <ChatIcon
              className="h-9 w-9 hoverEffect p-2 hover:text-sky-500 hover:bg-sky-100"
              onClick={() =>
                navigate(`/post/${post._id}`, {
                  state: { postId: post._id },
                })
              }
              />
              <span>{post?.replyCount}</span>
          </div>
          <div className="flex items-center space-x-1" onClick={() => {
                    setlink_copied(true)
                    navigator.clipboard.writeText(
                      `https://www.petzoy.in/post/${post._id}`
                    );
                    toast.success('Link Copied !');
                  }}>
                    {link_copied ? <Check2Circle className="h-9 w-9 hoverEffect p-2 hover:text-sky-500 hover:bg-sky-100" />:
            <ShareIcon className="h-9 w-9 hoverEffect p-2 hover:text-sky-500 hover:bg-sky-100" />}
          </div>
          <div className="flex items-center space-x-1">
            <EyeIcon className="h-9 w-9 hoverEffect p-2 hover:text-sky-500 hover:bg-sky-100" />
            <span>{post?.views}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;
