import {
  EditOutlined,
  DeleteOutlined,
  AttachFileOutlined,
  GifBoxOutlined,
  ImageOutlined,
  MicOutlined,
  MoreHorizOutlined,
  VideocamOutlined,
  AddCircleOutlineOutlined,
  UploadFile,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Typography,
  InputBase,
  useTheme,
  Button,
  IconButton,
  useMediaQuery,
  InputAdornment,
  Menu,
  MenuItem,
} from "@mui/material";
import FlexBetween from "../../components/FlexBetween";
import Dropzone from "react-dropzone";
import UserImage from "../../components/UserImage";
import WidgetWrapper from "../../components/WidgetWrapper";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPosts } from "../../store/reducers/authReducer";
import { useNavigate } from "react-router-dom";
import { useGetReportByNameQuery } from "../../store/services/reportService";
import {  InsertPhoto as InsertPhotoIcon, VideoLibrary as VideoLibraryIcon, HelpOutline as HelpOutlineIcon } from '@mui/icons-material';
import Editor from "./VideoEditor";

const MyPostWidget = ({ picturePath, mediaType,taggedpet,onimagepress,onvideopress,selected_business,setpet_data,pet_data }) => {
  const dispatch = useDispatch();
  const [isImage, setIsImage] = useState(false);
  const [showsection, setshowsection] = useState(false);
  const mainuserpicturePath = useSelector((state) => state.authReducer.user?.picturePath);
  const [media, setMedia] = useState(null);
  const [post, setPost] = useState("");
  const [mediaPreview, setMediaPreview] = useState(null);
  const { palette } = useTheme();
  const { _id } = useSelector((state) => state.authReducer.user);
  const token = useSelector((state) => state.authReducer.token);
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const { data:uploadvideo, error, isLoading } = useGetReportByNameQuery('uploadvideo');
  const mediumMain = palette.neutral.mediumMain;
  const medium = palette.neutral.medium;

  const [openMenu, setOpenMenu] = React.useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(null);
  };
  const navigate = useNavigate();
  const handleImageUpload = (acceptedFiles) => {
    setIsImage(true);
    setMedia(acceptedFiles[0]);
    setMediaPreview(URL.createObjectURL(acceptedFiles[0]));
  };

  const handleVideoUpload = (acceptedFiles) => {
    setIsImage(false);
    setMedia(acceptedFiles[0]);
    setMediaPreview(URL.createObjectURL(acceptedFiles[0]));
  };

  const handlePost = async () => {
    const formData = new FormData();
    formData.append("userId", _id);
    formData.append("description", post);
    formData.append("media", media);
    
    if(taggedpet != null){
      formData.append("tag", taggedpet._id);

    }
    if(selected_business != null){
      formData.append("postType", 'Brand');
      formData.append("brandId", selected_business._id);

    }

    const response = await fetch(`https://api.petzoy.in/posts`, {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      body: formData,
    });
    const posts = await response.json();
    dispatch(setPosts({ posts }));
    setMedia(null);
    setPost("");
  };

  return (
    <WidgetWrapper>
      <FlexBetween gap="0.5rem">
        <div onClick={() => {
          
 navigate(`/userconnect/${_id}`,{ state: {userId:_id}})

           }}>

        <UserImage image={picturePath} size="55px"  />
        </div>
        <InputBase
          placeholder="What's on your mind..."
          onChange={(e) => setPost(e.target.value)}
          value={post}
          sx={{
            width: "100%",
            backgroundColor: palette.neutral.light,
            borderRadius: "2rem",
            padding: "1rem 2rem",
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleOpenMenu}>
                <AddCircleOutlineOutlined/>
              </IconButton>
              <Menu
                anchorEl={openMenu}
                open={Boolean(openMenu)}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={() => {setshowsection(true);setIsImage(true);handleCloseMenu();setMediaPreview('');setMedia(null)}}>
                  <InsertPhotoIcon /> Add Image
                </MenuItem>
                {uploadvideo?.value == 'true' &&   <MenuItem onClick={() => {setshowsection(true);setIsImage(false);setMediaPreview('');setMedia(null);handleCloseMenu()}} >
                  <VideoLibraryIcon /> Add Video
                </MenuItem>}
              </Menu>
            {isNonMobileScreens &&  <Button
          disabled={!post}
          onClick={handlePost}
          sx={{
            color: palette.background.alt,
            backgroundColor: palette.primary.main,
            borderRadius: "3rem",
          }}
        >
          POST
        </Button>}
              {/* <IconButton>
                <HelpOutlineIcon onClick={() =>  navigate("/new-post",{ state: {tagdata:taggedpet}})}/>
              </IconButton> */}
            </InputAdornment>
          }
        />
      </FlexBetween>
    {showsection &&  <Box mt="1rem" p="1rem">
        <Dropzone
          acceptedFiles={isImage ? ".jpg,.jpeg,.png" : ".mp4"}
          multiple={false}
          onDrop={isImage ? handleImageUpload : handleVideoUpload}
        >
          {({ getRootProps, getInputProps }) => (
            <FlexBetween>
           <Box
            {...getRootProps()}
            border={`2px dashed ${palette.primary.main}`}
            p="1rem"
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            bgcolor={palette.background.paper}
            color={palette.text.primary}
            sx={{
              "&:hover": { cursor: "pointer", backgroundColor: palette.action.hover },
              borderRadius: "8px",
              transition: "background-color 0.3s ease-in-out",
            }}
          >
            <input {...getInputProps()} />
            {!media ? (
              <>
                <UploadFile fontSize="large" style={{ fontSize: "48px", marginBottom: "1rem" }} />
                <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {isImage ? "Upload or Drop Image Here" : "Upload or Drop Video Here"}
                </p>
              </>
                ) : (
                  <FlexBetween>
  {isImage ? (
                        <img src={mediaPreview} alt="preview" width="50%"  />
                      ) : (


                        // <Editor videoUrl={mediaPreview}/>
                        <video src={mediaPreview} controls width="50%" />
                      )}
                    <EditOutlined />
                  </FlexBetween>
                )}
              </Box>
              {media && (
                <IconButton
                  onClick={() => setMedia(null)}
                  sx={{ width: "15%" }}
                >
                  <DeleteOutlined />
                </IconButton>
              )}
            </FlexBetween>
          )}
        </Dropzone>
      </Box>}

      <Divider sx={{ margin: "1.25rem 0" }} />

      <FlexBetween>

{pet_data != null &&
<UserImage size={isNonMobileScreens ? "40px":"30px"}  image={mainuserpicturePath} onpress={() => setpet_data(null)}/>
}
     {!isNonMobileScreens && <Button
        
        onClick={() =>  navigate("/new-post",{ state: {tagdata:taggedpet}})}
        sx={{
          color: palette.background.alt,
          backgroundColor: palette.primary.main,
          borderRadius: "3rem",
          fontSize: { xs: '0.5rem', sm: '1rem', md: '1.25rem' },
        }}
      >
        Ask A Question
      </Button>}

      {!isNonMobileScreens &&  <Button gap="0.25rem" 
         sx={{
          color: palette.background.alt,
          backgroundColor: mediaType == "image"? palette.grey[300]:palette.common.white,
          borderRadius: "3rem",
        }}
        onClick={onimagepress}>
          <ImageOutlined sx={{ color: mediumMain }} />
          <Typography
  color={mediumMain}
  sx={{
    fontSize: { xs: '0.75rem', sm: '1rem', md: '1.25rem' }, // Adjust sizes based on breakpoints
    "&:hover": {
      cursor: "pointer",
      color: medium
    }
  }}
>
            Image
          </Typography>
        </Button>}

      
        {!isNonMobileScreens && <Button gap="0.25rem"
       sx={{
        color: palette.background.alt,
        backgroundColor: mediaType == "video"? palette.grey[300]:palette.common.white,
        borderRadius: "3rem",
      }}
      onClick={onvideopress}>
          <VideocamOutlined sx={{ color: mediumMain }} />
          <Typography
            color={mediumMain}
            sx={{ "&:hover": { cursor: "pointer", color: medium },fontSize: { xs: '0.75rem', sm: '1rem', md: '1.25rem' }, }}
          >
            Video
          </Typography>
        </Button>}

        {!isNonMobileScreens &&  <Button
          disabled={!post}
          onClick={handlePost}
          sx={{
            color: palette.background.alt,
            backgroundColor: palette.primary.main,
            borderRadius: "3rem",
            fontSize: { xs: '0.5rem', sm: '1rem', md: '1.25rem' },
          }}
        >
          POST
        </Button>}
      </FlexBetween>
    </WidgetWrapper>
  );
};

export default MyPostWidget;
