import {
    Avatar,
    Box,
    Button,
    Chip,
    Divider,
    Modal,
    TextField,
    Typography,
    useTheme,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    useMediaQuery,
    MenuItem,
    FormControlLabel,
    Checkbox,
  } from "@mui/material";
  import { useCallback, useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
import { useCreateBrandMutation, useUpdateBrandMutation } from "../../../store/services/brandService";
import Cropper from "react-easy-crop";
  
  const EditCommercialModal = ({ open, setOpen,userdata }) => {
    const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
    const theme = useTheme();
    const [formdata, setformdata] = useState({
      businesstype: "",
      name: "",
      location: "",
      description:""
    });
    const [selected_type, setselected_type] = useState(null);
    const [preview, setPreview] = useState(null);
    const [imgfile, setimgfile] = useState(null);
  
    const [current_addstep, setcurrent_addstep] = useState(0);
    const { palette } = useTheme();
  
    const token = useSelector((state) => state.authReducer.token);
    
    const loggedInUserId = useSelector((state) => state.authReducer.user._id);
    const dispatch = useDispatch();
  
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
     
      setOpen(false);
  };
  
  useEffect(() => {
    setPreview("https://api.petzoy.in/assets/"+userdata?.picturePath)
    setformdata({
        
        businesstype: userdata?.type,
      name: userdata?.name,
      location: userdata?.location,
      description:userdata?.description
      })

    
      
}, [userdata])

  
  
  
  
  
const [crop, setCrop] = useState({ x: 0, y: 0 });
const [zoom, setZoom] = useState(1);
const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
const [croppedImage, setCroppedImage] = useState(null);

   
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setimgfile(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  
  
    const handleFormInput = (event) => {
      console.log(event);
      const { name, value } = event.target;
      setformdata({
        ...formdata,
        [name]: value,
      });
    };
  
    console.log("select type ",selected_type)
    const [updateBrand, { isLoading, error }] = useUpdateBrandMutation(); // Use the mutation hook

    const handleSave = async () => {
      
  
        const data = {
            name: formdata.name,
            location: formdata.location,
            type: formdata.businesstype,
            description: formdata.description,
            id: userdata?._id,
          };
      
          // If image is selected, append it to the data
          if (imgfile) {
            const croppedBlob = await getCroppedImg(preview, croppedAreaPixels);
            const randomName = `croppedImage_${Math.random().toString(36).substring(2, 15)}.jpg`;
            const croppedFile = new File([croppedBlob], randomName, { type: "image/jpeg" });
        
            data.picture = croppedFile;
            data.picturePath = randomName;

          }
  
      const result = await updateBrand(data);
      console.log(result)
      setimgfile(null);
      setimgfile("");
      handleClose();
    };
  
  
  
  
  
  
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "scroll",
          bgcolor: "rgba(0,0,0,0)",
          border: "2px solid #000",
          flexDirection:"column",
          boxShadow: 24,
        }}
      >
        <>      {!isNonMobileScreens &&
   <Box
          sx={{
            width: "100%",
            maxWidth: "800px",
            bgcolor: palette.primary.main,
        display:"flex",
            boxShadow: 24,
            height:  "70px",alignItems:"center",justifyContent:"space-between",paddingLeft:'10px',paddingRight:'10px'
          }}
        >
          <i onClick={handleClose} class="fa-solid fa-chevron-left"></i>
           <Typography variant="h4" color={"black"}>
                      Edit Professional Dashboard
                    </Typography>
                    <i class="fa-solid fa-paw"></i>
        </Box> }
        <Box
          sx={{
            width: isNonMobileScreens ? "30%" : "100%",
            maxWidth: "800px",
            bgcolor: "white",
            padding: 5,
            borderRadius: isNonMobileScreens ? 5:0,
            boxShadow: 24,
            height: isNonMobileScreens ? "auto" : "100%",
          }}
        >
          
          <Typography id="modal-modal-title" variant="h3" component="h3">
           Add Details
          </Typography>
  
         
  
          
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{width:"100%",height:"200px",alignSelf:"center",alignItems:"center",justifyContent:"center",borderWidth:1,borderRadius:'10px',borderStyle:"dashed"}}>
  
           <input type="file" onChange={handleImageUpload} />
               {preview && (
                 <Box
                 sx={{
                   width: "100%",
                   height: "200px", // Fixed height for the cropper container
                   position: "relative",
                   alignSelf: "center",
                   alignItems: "center",
                   justifyContent: "center",
                   borderWidth: 1,
                   borderRadius: "10px",
                   borderStyle: "dashed",
                   overflow: "hidden",
                 }}
               >
                  <Cropper
                  image={preview}
                  crop={crop}
                  zoom={zoom}
                  aspect={3 / 4}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  style={{ containerStyle: { width: '100%', height: '100%' } }}
                />
                  </Box>
                )}</Box>
               
              <Typography>Upload Image</Typography>
              <Box>
               
              </Box>
            </Box>
          
  
       
  
          
              <TextField
                label="Business Name"
                value={formdata.name}
                type="text"
                name="name"
                onChange={handleFormInput}
                fullWidth
                margin="normal"
              />
              
              <TextField
                label="PinCode"
                value={formdata.location}
                type="number"
                name="location"
                onChange={handleFormInput}
                fullWidth
                margin="normal"
              />
              <TextField
          select
          label="Business Type"
          value={formdata.businesstype}
          onChange={handleFormInput}
          name="businesstype"
          fullWidth
          margin="normal"
        >
          <MenuItem value="Shop">Shop</MenuItem>
          <MenuItem value="NGO">NGO</MenuItem>
        </TextField>
        <TextField
                label="Description"
                value={formdata.description}
                
                name="description"
                onChange={handleFormInput}
                fullWidth
                margin="normal"
              />
  
  
     
     
  
          <Box sx={[{ display: "flex", justifyContent: "space-between",
          
           },!isNonMobileScreens && {position:"absolute",bottom:'20px',width:"80%"}]}>
           
            <Button
              variant="outlined"
              size="large"
              onClick={() => {
             
                  handleClose();
                  
              }}
            >
              {"Cancel"}
            </Button>
            <Button variant="contained" size="large" onClick={handleSave}>
              {"Save"}
            </Button>
          </Box>
        </Box></>
  
      </Modal>
    );
  };
  
  export default EditCommercialModal;
  