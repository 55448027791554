import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSearchUsersAndPetsQuery } from "../../store/services/authService";
import UserImage from "../UserImage";
import currency from "currency-formatter";
import toast, { Toaster } from "react-hot-toast";
import { Button, Modal, Typography, useTheme, Radio, FormControlLabel, FormControl, RadioGroup } from "@mui/material";
import {
  setDemoShow,
  setdisplaylocation,
  setlocation,
} from "../../store/reducers/authReducer";
import LocationPicker from "react-leaflet-location-picker";
import HoroscopeModel from "./HoroscopeModel/HoroscopeModel";

const WhiteHeader = () => {

  const [isSearchVisible, setIsSearchVisible] = useState(false);

  // Function to toggle search input visibility
  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  const mainlocation = useLocation();

  const currentUrl = mainlocation.pathname;
  console.log(currentUrl === '/shops', "main url ")
  const isHomePage = mainlocation.pathname === "/";
  const picturePath = useSelector((state) => state.authReducer.user?.picturePath);
  const dispatch = useDispatch();
  const [selectlocation, setselectlocation] = useState(false);
  const displaylocation = useSelector((state) => state.authReducer.location);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const navigate = useNavigate();
  const latlong = useSelector((state) => state.authReducer.latlong);
  const isAuth = Boolean(useSelector((state) => state.authReducer.token));
  const { items, total } = useSelector((state) => state.cart);
  

  const [useMap, setUseMap] = useState(false);
  const theme = useTheme();

  const [searchlocation, setsearchLocation] = useState("");
  const { palette } = useTheme();
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [user_coordinates, setuser_coordinates] = useState({
    latitude: latlong?.latitude || "",
    longitude: latlong?.longitude || "",
  });
  // Fetch search results when the word count exceeds 2
  const { data, error, isLoading } = useSearchUsersAndPetsQuery(searchInput, {
    skip: searchInput.length <= 2, // Skip the query if word count <= 2
    refetchOnMountOrArgChange: true,
    onSuccess: (data) => {
      setSearchResults(data);
    },
    onError: (error) => {
      console.error("Error fetching search results:", error);
    },
  });

  const [locationSuggestions, setLocationSuggestions] = useState([]);

  useEffect(() => {
    if (searchlocation.length > 2) {
      fetch(`https://nominatim.openstreetmap.org/search?q=${searchlocation}&format=json`)
        .then((response) => response.json())
        .then((data) => {
          setLocationSuggestions(data);

        })
        .catch((error) => console.error("Error fetching location suggestions:", error));
    } else {
      setLocationSuggestions([]);
    }
  }, [searchlocation]);


  // Handle search input change
  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const [location, setLocation] = useState({
    lat: latlong?.latitude || 0.0,
    lng: latlong?.longitude || 0.0,
  });
  const handleChangeLocation = (lat, lng) => {
    console.log(lat, lng, "handle change loction");
    setLocation({ lat, lng });
  };
  const closemodal = () => {
    if (useMap) {

      setsearchLocation("")
      dispatch(
        setlocation({
          latlong: { latitude: location.lat, longitude: location.lng },
        })
      );
      dispatch(setdisplaylocation({ location: null }));
      setselectlocation(false);
    } else {
      setsearchLocation("")

      dispatch(
        setlocation({
          latlong: { latitude: searchlocation.lat, longitude: searchlocation.lon },
        })
      );
      dispatch(setdisplaylocation({ location: searchlocation.display_name }));
      setselectlocation(false);
    }
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Function to toggle menu visibility
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  useEffect(() => {
    if (displaylocation == null && latlong != null) {
      getLocationInfo();
    }
  }, [latlong]);

  function getLocationInfo() {
    const url = `https://api.petzoy.in/reverse-geocoding?lat=${latlong.latitude}&lon=${latlong.longitude}&format=json&language=en`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.address) {
          dispatch(setdisplaylocation({ location: data.display_name }));
          
          // console.log("results:", data.display_name,data.address.county,data.address.suburb,data.address.state_district,data.address.state);
        } else {
          console.log("Reverse geolocation request failed.");
        }
      })
      .catch((error) => console.error(error));
  }

  const pickCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ lat: latitude, lng: longitude });

          dispatch(
            setlocation({
              latlong: { latitude: latitude, longitude: longitude },
            })
          );
          setsearchLocation("")
          getLocationInfo(latitude, longitude);
          setselectlocation(false);
        },
        (error) => {
          toast.error("Error fetching current location:", error);
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.");
    }
  };


  const pointMode = {
    banner: true,
    control: {
      values: [[location.lat, location.lng]],
      onClick: point => { console.log("I've just been clicked on the map!", point[0], point[1]); handleChangeLocation(point[0], point[1]) },
      // handleChangeLocation
      onRemove: point =>
        console.log("I've just been clicked for removal :(", point)
    }
  };
  const circleMode = {
    banner: false
  };



  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the form from refreshing the page
    // Handle form submission logic here
    console.log('Form submitted:', searchInput);
  };


  return (
    <>
      
      <Modal
        open={selectlocation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
          bgcolor: "rgba(0,0,0,0.5)",
          padding: "5px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "800px",
            bgcolor: "white",
            padding: 3,
            borderRadius: '10px',
            boxShadow: 24,
            height: isNonMobileScreens ? useMap ? "70%" : "30%" : "auto",
            position: "relative",
          }}
        >
          {!isNonMobileScreens && (
            <Box
              sx={{
                width: "100%",
                // bgcolor: theme.palette.primary.main,
                display: "flex",
                // boxShadow: 24,
                height: "50px",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0 5px",
                // borderRadius: '10px 10px 0 0',
                marginBottom: '20px'
              }}
            >
              <i onClick={closemodal} className="fa-solid fa-chevron-left"></i>
              <Typography variant="h5" color={"black"}>
                Change Your Location
              </Typography>
              <i className="fa-solid fa-location-dot"></i>
            </Box>
          )}
          {isNonMobileScreens && (
            <Box
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                zIndex: 999,
                fontSize: "1.5rem",
              }}
              onClick={() => setselectlocation(false)}
            >
              <i className="fa-solid fa-times"></i>
            </Box>
          )}

          {isNonMobileScreens && (<Typography variant="h6" color="textPrimary" sx={{ mb: 2 }}>
            Change Your Location
          </Typography>)}
          {!useMap &&
            <input
              type="text"
              placeholder="Location"
              value={searchlocation?.display_name}
              onChange={(e) => setsearchLocation(e.target.value)}
              style={{
                width: "100%",
                padding: "12px",
                fontSize: "16px",
                border: "1px solid #E0E0E0",
                borderRadius: "5px",
                marginBottom: "10px",
                outline: "none",
                backgroundColor: "#FFFFFF",
                color: "#333333",
              }}
            />
          }
          {locationSuggestions.length > 0 && (
            <ul
              style={{
                position: "absolute",
                background: "#FFFFFF",
                border: "1px solid #E0E0E0",
                borderRadius: "4px",
                margin: 0,
                padding: 0,
                listStyle: "none",
                zIndex: 1000,
                width: "100%",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              {locationSuggestions.map((suggestion, index) => (
                <li
                  key={index}
                  style={{
                    padding: "10px",
                    cursor: "pointer",
                    transition: "background 0.2s ease",
                    color: "#333333",
                  }}
                  onMouseEnter={(e) => e.target.style.backgroundColor = "#F5F5F5"}
                  onMouseLeave={(e) => e.target.style.backgroundColor = "#FFFFFF"}
                  onClick={() => {
                    console.log(suggestion);
                    setsearchLocation(suggestion);
                    setLocationSuggestions([]);
                  }}
                >
                  {suggestion?.display_name}
                </li>
              ))}
            </ul>
          )}

          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <RadioGroup row aria-label="location" name="location" value={useMap} onChange={(e) => {
              setUseMap(e.target.value === 'true');
              if (e.target.value === 'true') {
                setsearchLocation("")
              }
            }}>
              <FormControlLabel value={false} control={<Radio />} label="Enter Location" />
              <FormControlLabel value={true} control={<Radio />} label="Select from Map" />
            </RadioGroup>
          </FormControl>

          {useMap && <LocationPicker pointMode={pointMode} circleMode={circleMode} startPort={'auto'} />}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              sx={{ textTransform: "none" }}
              onClick={() => pickCurrentLocation()}
            >
              Pick Current Location
            </Button>
            {location != {} && (
              <Button
                variant="contained"
                color="secondary"
                sx={{ textTransform: "none" }}
                onClick={closemodal}
              >
                Change Location
              </Button>
              // <Button
              //   size="medium"

              //   sx={{
              //     color: theme.palette.background.alt,
              //     backgroundColor: theme.palette.primary.main,
              //     borderRadius: "3rem",
              //     marginTop: "5px",
              //   }}
              // >
              //   Change Location
              // </Button>
            )}
          </Box>


        </Box>
      </Modal>
      <header>
        <div id="header-fixed-height"></div>

        <div id="sticky-header" className="tg-header__area">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="tgmenu__wrap">
                  <nav className="tgmenu__nav">
                    <div className="logo">
                      <a href="/">
                        <img src="https://api.petzoy.in/mainlogo/logo.png" alt="Logo" />
                      </a>
                    </div>


                    <div className="tgmenu__navbar-wrap tgmenu__navbar-wrap-two tgmenu__main-menu d-none d-xl-flex">
                      <ul className="navigation">
                        <li className={`${isHomePage ? 'active' : ''} nearbyheader`}>
                          <a href="/">Nearby</a>
                        </li>
                        <li className={`${currentUrl == '/shops' ? 'active' : ''} shopsheader`}>
                          <a href="/shops">Shop</a>
                        </li>
                        {isAuth && (
                          <li className={`${currentUrl == '/home' ? 'active' : ''} socialheader`}>
                            <a href="/home">Social</a>
                          </li>
                        )}
                        <li className={`${currentUrl == '/discussiondashboard' ? 'active' : ''} petoheader`}>
                          <a href="/discussiondashboard">Petopedia</a>
                        </li>
                        {isAuth && (
                          <li className={`${currentUrl == '/chat' ? 'active' : ''}`}>
                            <a href="/chat">Chat</a>
                          </li>
                        )}

                        {/* {isAuth && <li><a href="/editprofile">Account</a></li>} */}
                        {!isAuth && (
                          <li>
                            <a href="/signin">Login</a>
                          </li>
                        )}
                      </ul>
                    </div>

                    {/* <div class="tgmenu__search">
                      <form action="#" class="tgmenu__search-form" onSubmit={handleSubmit}>
                        <input
                          type="text"
                          placeholder="Search Here . . ."
                          value={searchInput}
                          onChange={handleSearchInputChange}
                        />
                        <button type="submit"><i class="flaticon-loupe"></i></button>
                        <button type="button" onClick={(e) => {
                          e.preventDefault();
                          setselectlocation(true);
                        }}><i class="fa-solid fa-location-dot"></i></button>
                        <div class="select-grp">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setselectlocation(true);
                            }}
                          >
                            {displaylocation
                              ? `${displaylocation?.substring(0, 10)}...`
                              : "Location"}
                          </button>
                        </div>
                      </form>

                    </div> */}

                    {/* <button
                      type="button"
                      onClick={toggleSearch}
                      className="search-toggle-btn"
                      style={{
                        display: '-webkit-box',
                        display: '-moz-box',
                        display: '-ms-flexbox',
                        display: '-webkit-flex',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        top: '0px',
                        bottom: '3px',
                        right: '9px',
                        width: '40px',
                        height: '40px',
                        flex: '0 0 auto',
                        border: 'none',
                        padding: '0 0',
                        background: 'var(--tg-theme-primary)',
                        fontSize: '20px',
                        lineHeight: '0',
                        color: 'var(--tg-color-white-default)',
                        WebkitBorderRadius: '50%',
                        MozBorderRadius: '50%',
                        OBorderRadius: '50%',
                        MsBorderRadius: '50%',
                        borderRadius: '50%',
                        marginLeft: '10px'
                      }}
                    >
                      <i class="flaticon-loupe"></i>
                    </button> */}
                    <div class="tgmenu__search">
                      <form action="#" class="tgmenu__search-form" onSubmit={handleSubmit}>
                        {/* Search input container with slide-in animation */}
                        <div className={`search-input-container ${isSearchVisible ? 'visible' : ''}`} style={{ marginRight: '5px' }}>
                          <input
                            type="text"
                            placeholder="Search Here . . ."
                            value={searchInput}
                            onChange={handleSearchInputChange}
                          />
                        </div>
                        {/* Search icon toggles search visibility */}
                        <button
                          type="button"
                          onClick={toggleSearch}
                          className="search-toggle-btn"
                        >
                          <i class="flaticon-loupe"></i>
                        </button>
                        <button type="button" onClick={(e) => {
                          e.preventDefault();
                          setselectlocation(true);
                        }}><i class="fa-solid fa-location-dot"></i></button>
                        <div class="select-grp">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setselectlocation(true);
                            }}
                          >
                            {displaylocation
                              ? `${displaylocation?.substring(0, 15)}...`
                              : "Location"}
                          </button>
                        </div>
                      </form>
                    </div>

                    {data && searchInput != "" && (
                      <Box
                        sx={{
                          maxHeight: "300px",
                          width: isNonMobileScreens ? "20%" : "90%",
                          background: "white",
                          position: "absolute",
                          top: isNonMobileScreens
                            ? "calc(14%)"
                            : "calc(22% + 10px)",
                          right: isNonMobileScreens ? "27%" : null,
                          zIndex: 999,
                          overflow: "scroll",
                          gap: "10px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
                        }}
                      >
                        {isLoading && <p>Loading...</p>}
                        {error && <p>Error: {error.message}</p>}
                        {data.map((result) => {
                          return (
                            <div
                              key={result._id}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "15px",
                                marginTop: "20px",
                                marginBottom: "20px",
                                paddingRight: "10px",
                                paddingLeft: "20px",
                              }}
                              onClick={() => {
                                setSearchInput('')
                                navigate(
                                  result.type === "user"
                                    ? `/userconnect/${result._id}`
                                    : `/contactprofile/${result._id}`,
                                  { state: { userId: result._id } }
                                );
                              }}
                            >
                              <UserImage
                                size="40px"
                                image={result.profilePath}
                              />
                              <h6> {result.name}</h6>

                              {/* Additional properties based on type */}
                              {/* {result.type === 'user' && <p>Profile Path: {result.profilePath}</p>} */}
                              {/* {result.type === 'pet' && <p>Profile Path: {result.profilePath}</p>} */}
                            </div>
                          );
                        })}
                      </Box>
                    )}
                    

                    <div class="tgmenu__action tgmenu__action-three d-none d-md-block">
                      <ul class="list-wrap">
                        {isAuth && (
                          <li class="header-login">
                            <a href="/editprofile">
                              {picturePath ? <Box width={'30px'} height={'30px'}>
                                <img
                                  style={{ objectFit: "cover", borderRadius: "50%", width: '30px', height: '30px' }}

                                  alt="user"
                                  src={`https://api.petzoy.in/assets/${picturePath}`}
                                />
                              </Box> :


                                <i class="flaticon-user"></i>
                              }</a>
                          </li>
                        )}
                        
                        {/* <li class="header-wishlist">
                                            <a href="javascript:void(0)">
                                                <i class="flaticon-love"></i>
                                            </a>
                                        </li> */}
                        {!isHomePage && (
                          <>
                            <li class="header-cart header-cart-two">
                              <strong class="price">
                                {currency.format(total, { code: "INR" })}
                              </strong>
                              <Link
                                to={{
                                  pathname: "/cart",
                                }}
                              >
                                <i class="flaticon-shopping-bag"></i>
                                <span>{items}</span>
                              </Link>
                            </li>
                          </>
                        )}
                        {isHomePage && (
                          <>
                            <li class="header-cart header-cart-two">
                             
                              <Link
                               to={'/'}
                               onClick={() => {
                                dispatch(setDemoShow(false))
                               }}
                              >
                               <i class="fa-solid fa-circle-question"></i>
                                
                              </Link>
                            </li>
                          </>
                        )}
                        
                      </ul>
                      
                    </div>
                    
                    <div>

                      <div class="mobile-nav-toggler" onClick={toggleMenu}>
                        <i class="flaticon-layout"></i>
                      </div>
                      <div class="mobile-nav-toggler" onClick={(e) => {
                        e.preventDefault();
                        setselectlocation(true);
                      }}><i class="fa-solid fa-location-dot"></i></div>
                      <div class="mobile-nav-toggler" onClick={() => {
                        navigate(`/cart`);
                      }}>
                        <i class="flaticon-shopping-bag"></i>
                      </div>
                      <div class="mobile-nav-toggler" onClick={() => {
                        navigate(`/chat`);
                      }}>
                        <i class="fa-regular fa-comment"></i>
                      </div>
                    </div>

                    
                  </nav>
                </div>

                <div className={`tgmobile__menu ${isMenuOpen ? '' : 'hidden'}`}>
                  <nav className="tgmobile__menu-box">
                    <div className="close-btn" onClick={toggleMenu}>
                      <i className="fas fa-times"></i>
                    </div>
                    <div className="nav-logo">
                      <a href="/">
                        <img src="assets/img/logo/logo.png" alt="Logo" />
                      </a>
                    </div>
                    <div className="tgmobile__search">
                      <form>
                        <input type="text" placeholder="Search here..."
                          value={searchInput}
                          onChange={handleSearchInputChange}
                        />
                        <button>
                          <i className="fas fa-search"></i>
                        </button>
                      </form>
                    </div>
                    <div className="tgmobile__menu-outer"></div>
                    <div className="social-links">
                      <ul className="list-wrap">
                        <li>
                          <a href="https://www.facebook.com/" target="_blank">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://twitter.com" target="_blank">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.whatsapp.com/" target="_blank">
                            <i className="fab fa-whatsapp"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/" target="_blank">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.youtube.com/" target="_blank">
                            <i className="fab fa-youtube"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                {isMenuOpen && <div className="tgmobile__menu-backdrop"></div>}
              </div>
            </div>
          </div>
        </div>

        <div className="search__popup">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="search__wrapper">
                  <div className="search__close">
                    <button type="button" className="search-close-btn">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17 1L1 17"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M1 1L17 17"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div className="search__form">
                    <form action="#">
                      <div className="search__input">
                        <input
                          className="search-input-field"
                          type="text"
                          placeholder="Type keywords here"
                        />
                        <span className="search-focus-border"></span>
                        <button>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.55 18.1C14.272 18.1 18.1 14.272 18.1 9.55C18.1 4.82797 14.272 1 9.55 1C4.82797 1 1 4.82797 1 9.55C1 14.272 4.82797 18.1 9.55 18.1Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M19.0002 19.0002L17.2002 17.2002"
                              stroke="currentcolor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="search-popup-overlay"></div>

        <div className="offCanvas__info">
          <div className="offCanvas__close-icon menu-close">
            <button>
              <i className="far fa-window-close"></i>
            </button>
          </div>
          <div className="offCanvas__logo mb-30">
            <a href="index.html">
              <img src="assets/img/logo/logo.png" alt="Logo" />
            </a>
          </div>
          <div className="offCanvas__side-info mb-30">
            <div className="contact-list mb-30">
              <h4>Office Address</h4>
              <p>
                123/A, Miranda City Likaoli <br /> Prikano, Dope
              </p>
            </div>
            <div className="contact-list mb-30">
              <h4>Phone Number</h4>
              <p>+0989 7876 9865 9</p>
              <p>+(090) 8765 86543 85</p>
            </div>
            <div className="contact-list mb-30">
              <h4>Email Address</h4>
              <p>info@example.com</p>
              <p>example.mail@hum.com</p>
            </div>
          </div>
          <div className="offCanvas__social-icon mt-30">
            <a href="javascript:void(0)">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="javascript:void(0)">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="javascript:void(0)">
              <i className="fab fa-google-plus-g"></i>
            </a>
            <a href="javascript:void(0)">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
        <div className="offCanvas__overly"></div>
      </header>{" "}
    </>
  );
};

export default WhiteHeader;