import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const advertisementService = createApi({
  reducerPath: "advertisements",
  tagTypes: ["Advertisements"],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.petzoy.in/api/', // Adjust the base URL if necessary
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAdvertisements: builder.query({
      query: () => ({
        url: `/advertisements`,
        method: "GET",
      }),
      providesTags: ["Advertisements"],
    }),
    getAdvertisementById: builder.query({
      query: (id) => ({
        url: `/advertisements/${id}`,
        method: "GET",
      }),
      providesTags: ["Advertisements"],
    }),
    getNearbyAdvertisements: builder.query({
        query: ({ latitude, longitude }) => ({
          url: `/advertisements/nearby?latitude=${latitude}&longitude=${longitude}`,
          method: "POST",
        }),
        providesTags: ["Advertisements"],
      }),
    updateAdvertisement: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/advertisements/${id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Advertisements"],
    }),
    deleteAdvertisement: builder.mutation({
      query: (id) => ({
        url: `/advertisements/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Advertisements"],
    }),
  }),
});

export const {
  useGetAdvertisementsQuery,
  useGetAdvertisementByIdQuery,
  useGetNearbyAdvertisementsQuery,
  useUpdateAdvertisementMutation,
  useDeleteAdvertisementMutation,
} = advertisementService;

export default advertisementService;
