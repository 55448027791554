import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  useCatProductsQuery,
  useGetSubcategoryProductsQuery,
} from "../../store/services/homeProducts";
import { discount } from "../../utils/discount";
import currency from "currency-formatter";
import { useAllCategoriesQuery } from "../../store/services/categoryService";
import HomeProducts from "../../components/HomeProducts/HomeProducts";

import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useGetSubcategoriesByCategoryQuery } from "../../store/services/subcategoryService";
import MainContent from "../../components/Shop/MainContent";
import Layout from "../../components/Shop/Layout";
import Header from "../../components/Shop/Header";
import Sidebar from "./Components/Sidebar";
import Feed from "./Components/Feed";

const ShoppingPage = () => {
  const [maincat, setmaincat] = useState({});
  const [mainsubcat, setmainsubcat] = useState({});
  const { data: catdata, isFetching: catfetch } = useAllCategoriesQuery();
  const { data, isFetching } = useGetSubcategoryProductsQuery({
    subcategoryId: mainsubcat?._id,
    page: "",
    skip: !mainsubcat?._id,
  });

  const {
    data: subcategories,
    error,
    isLoading,
  } = useGetSubcategoriesByCategoryQuery(maincat._id);

  useEffect(() => {
    if (catdata) {
      setmaincat(catdata?.categories[0]);
    }
  }, [catdata]);

  useEffect(() => {
    setmainsubcat(subcategories?.subcategories[0]);
  }, [subcategories]);

  let i = 1;

  const ScrollerItem = ({ item }) => (
    <div
      onClick={() => setmaincat(item)}
      className={`flex-shrink-0 border rounded-lg shadow-md p-2 m-2 flex items-center cursor-pointer transition-all duration-200 ease-in-out ${
        maincat === item
          ? "bg-gradient-to-r from-purple-500 to-purple-700 text-white border-transparent"
          : "bg-white text-black border-gray-300 hover:border-purple-500"
      }`}
    >
      <img
        src={`https://api.petzoy.in/assets/${item.image}`}
        alt={item.name}
        className="w-8 h-8 mr-2 rounded-full object-cover"
      />
      <div
        className={`font-medium text-lg ${
          maincat === item ? "font-bold" : "font-normal"
        }`}
      >
        {item.name}
      </div>
    </div>
  );

  const HorizontalScroller = ({ items }) => (
    <div className="flex md:justify-center  lg:justify-center  overflow-x-auto p-2 scrollbar-hidden bg-gray-200 ">
      {items.map((item, index) => (
        <ScrollerItem key={index} item={item} />
      ))}
    </div>
  );
  return (
    // <>
    //   <main class="fix">
    //     <section class="category__area">
    //       <div class="container">
    //         <div class="row align-tiems-center">
    //           <div class="col-md-8">
    //             <div class="section__title-two mb-30">
    //               <h2 class="title">
    //                 Shop Your Pets Favourite Products{" "}
    //                 <img
    //                   src="assets/img/images/title_shape.svg"
    //                   alt=""
    //                   class="injectable"
    //                 />
    //               </h2>
    //             </div>
    //           </div>
    //         </div>
    //         <div class="m-2 flex-auto items-center">
    //           {catdata?.categories?.length > 0 && (
    //             <Swiper
    //               navigation={true}
    //               modules={[Navigation]}
    //               spaceBetween={20}
    //               slidesPerView={20}
    //               Navigation
    //               className="w-full h-full"
    //               breakpoints={{
    //                 0: {
    //                   slidesPerView: 1.5,
    //                 },
    //                 768: {
    //                   slidesPerView: 1.5,
    //                 },
    //                 1080: {
    //                   slidesPerView: 6,
    //                 },
    //                 1280: {
    //                   slidesPerView: 6,
    //                 },
    //               }}
    //             >
    //               {catdata?.categories?.map((category, index) => {

    //                 return (
    //                   <SwiperSlide
    //                     className="w-full h-full overflow-hidden  relative"
    //                     key={index}
    //                     virtualIndex={index}
    //                   >
    //                     <Link
    //                       to={{
    //                         pathname: "",
    //                       }}
    //                       state={{
    //                         maindata: category,
    //                       }}
    //                       className="w-full h-full"
    //                     >
    //                       <div class="col">
    //                         <div class="category__item">
    //                           <button
    //                             onClick={() => setmaincat(category)}
    //                             state={{ maincat: category }}
    //                           >
    //                                <img src={`https://api.petzoy.in/assets/${category.image}`} alt="" />
    //                             <span class="name">{category.name}</span>
    //                             {maincat.name == category.name && (
    //                               <strong>
    //                                 {data?.products?.length} Items
    //                               </strong>
    //                             )}
    //                           </button>
    //                         </div>
    //                       </div>
    //                     </Link>
    //                   </SwiperSlide>
    //                 );
    //               })}
    //             </Swiper>
    //           )}

    //         </div>
    //       </div>
    //     </section>

    //    {maincat != {} &&  <section class="category__area">
    //       <div class="container">
    //         <div class="row align-tiems-center">
    //           <div class="col-md-8">
    //             <div class="section__title-two mb-30">
    //               <h2 class="title">
    //                 {maincat.name}{" "}
    //                 <img
    //                   src="assets/img/images/title_shape.svg"
    //                   alt=""
    //                   class="injectable"
    //                 />
    //               </h2>
    //             </div>
    //           </div>
    //         </div>
    //         <div class="m-2 flex-auto items-center">
    //           {subcategories?.subcategories?.length > 0 && (
    //             <Swiper
    //               navigation={true}
    //               modules={[Navigation]}
    //               spaceBetween={20}
    //               slidesPerView={20}
    //               Navigation
    //               className="w-full h-full"
    //               breakpoints={{
    //                 0: {
    //                   slidesPerView: 1.5,
    //                 },
    //                 768: {
    //                   slidesPerView: 1.5,
    //                 },
    //                 1080: {
    //                   slidesPerView: 6,
    //                 },
    //                 1280: {
    //                   slidesPerView: 6,
    //                 },
    //               }}
    //             >
    //               {subcategories?.subcategories?.map((category, index) => {

    //                 return (
    //                   <SwiperSlide
    //                     className="w-full h-full overflow-hidden  relative"
    //                     key={index}
    //                     virtualIndex={index}
    //                   >
    //                     <div

    //                       className="w-full h-full"
    //                     >
    //                       <div class="col">
    //                         <div class="category__item">
    //                           <button
    //                             onClick={() => setmainsubcat(category)}

    //                           >
    //                                <img src={`https://api.petzoy.in/assets/${category.image}`} alt="" />
    //                             <span class="name">{category.name}</span>

    //                           </button>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </SwiperSlide>
    //                 );
    //               })}
    //             </Swiper>
    //           )}

    //         </div>
    //       </div>
    //     </section>}

    //     <section class="product__area-four">
    //       <div class="container">
    //         <div class="row gutter-20 row-cols-1 row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 justify-content-center">
    //           {isFetching ? <div>Loading....</div> : null}
    //           {data?.products?.map((product, index) => {
    //             return (
    //               <div class="col">
    //                 <div class="product__item">
    //                   <div class="product__thumb">
    //                     <Link
    //                       to={{ pathname: `/product_details/${product?._id}` }}
    //                     >
    //                       <img
    //                         src={
    //                           `https://api.petzoy.in/assets/${product?.images[0]}` ||
    //                           "assets/img/products/latest_products_img01.jpg"
    //                         }
    //                         alt="img"
    //                       />
    //                     </Link>

    //                     <div class="sale-wrap">
    //                       <span>New</span>
    //                     </div>
    //                     <div class="product__add-cart">
    //                       <Link
    //                         to={{
    //                           pathname: `/product_details/${product?._id}`,
    //                         }}
    //                         class="btn"
    //                       >
    //                         <i class="flaticon-shopping-bag"></i>Add To Cart
    //                       </Link>
    //                     </div>
    //                   </div>
    //                   <div class="product__content">
    //                     <div class="product__reviews">
    //                       <div class="rating">
    //                         <i class="fas fa-star"></i>
    //                         <i class="fas fa-star"></i>
    //                         <i class="fas fa-star"></i>
    //                         <i class="fas fa-star"></i>
    //                         <i class="fas fa-star"></i>
    //                       </div>
    //                       <span>(2 Reviews)</span>
    //                     </div>
    //                     <h4 class="title">
    //                       {" "}
    //                       <Link
    //                         to={{
    //                           pathname: `/product_details/${product?._id}`,
    //                         }}
    //                       >
    //                         {product.title}
    //                       </Link>
    //                     </h4>
    //                     <h3 class="price">
    //                       {currency.format(
    //                         discount(product?.price, product?.discount),
    //                         { code: "INR" }
    //                       )}{" "}
    //                       <del>
    //                         {currency.format(product?.price, { code: "INR" })}
    //                       </del>
    //                     </h3>
    //                   </div>
    //                 </div>
    //               </div>
    //             );
    //           })}
    //         </div>
    //       </div>
    //     </section>

    //     <section class="product__area-three">
    //       <div class="container">
    //         <div class="row">
    //           <div class="col-lg-6">
    //             <div class="product__inner-wrap">
    //               <div class="row align-items-center">
    //                 <div class="col-sm-8">
    //                   <div class="section__title-two">
    //                     <h2 class="title">
    //                       {petGiftCategory?.name}{" "}
    //                       <img
    //                         src="assets/img/images/title_shape.svg"
    //                         alt=""
    //                         class="injectable"
    //                       />
    //                     </h2>
    //                   </div>
    //                 </div>
    //               </div>

    //               <HomeProducts maincat={petGiftCategory} />
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>
    //   </main>
    // </>
    //   <div className="flex flex-col justify-center bg-white bg-opacity-0">
    //   <Header  maindata={catdata?.categories || []} setmaincat={setmaincat} maincat={maincat}/>
    //   <div className="overflow-hidden z-10 py-0.5 w-full max-md:pl-5 max-md:max-w-full">
    //     <div className="flex gap-5 max-md:flex-col max-md:gap-0">
    //      {maincat != {} && <Sidebar maindata={subcategories?.subcategories || []} maincat={mainsubcat} setmaincat={setmainsubcat}/>}
    //       {giftdata && <MainContent maindata={data?.products || giftdata.products} maincat={maincat}/>}

    //     </div>
    //   </div>
    // </div>

    <div className="flex flex-col min-h-screen w-full mx-auto bg-white">
      <div className="relative">
        <div className="overflow-x-auto overflow-hidden scrollbar-hidden ">
          <HorizontalScroller items={catdata?.categories || []} />
        </div>
      </div>
      <main className="flex flex-col xl:flex-row max-w-7xl mx-auto w-full">
        {/* Sidebar */}
        <Sidebar
          maindata={subcategories?.subcategories}
          // handlePetTypeSelect={handlePetTypeSelect}
          // handleTagSelect={handleTagSelect}
          onpress={(item) => setmainsubcat(item)}
          mainsubcat={mainsubcat}
          className="xl:w-1/4 xl:flex-shrink-0"
        />
        {/* Feed */}
        <Feed
          // maindata={filteredPosts}
          maincat={maincat}
          maindata={data?.products || []}
          className="xl:w-2/4 flex-grow"
        />
      </main>
    </div>
  );
};

export default ShoppingPage;
